import React, { useState } from 'react';
import { Widget } from 'widgets/models/WidgetModels';
import { DocumentTypeFilterOptions } from 'widgets/models/RecentlyViewedModel';
import { ButtonPrimary, ButtonSecondary, RadioGroup } from 'motili-ui';
import { DrawerHeadingAndTitle } from 'widgets/components/drawer/DrawerHeadingAndTitle';
import { DrawerContainer } from 'widgets/components/drawer/DrawerContainer';
import { ToggleButtonGroup } from 'widgets/components/toggleButtonGroup/ToggleButtonGroup';
import useDrawerStyles from 'widgets/components/drawer/DrawerStyles';

interface CustomizeWidgetDrawerProps {
    widgetDetails: Widget;
    handleSubmit: (widgetDetails: Widget) => void;
    handleClose: () => void;
}

export function CustomizeWidgetDrawer(
    props: Readonly<CustomizeWidgetDrawerProps>
): JSX.Element {
    const styles = useDrawerStyles();
    const {
        widgetDetails: initialWidgetDetails,
        handleSubmit,
        handleClose,
    } = props;

    const [widgetData, setWidgetData] = useState(initialWidgetDetails);

    const handleNumDocumentSelect = (event): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                noOfDocumentsToShow: Number(event.target.value),
            },
        });
    };

    const updateDocumentTypeFilter = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setWidgetData(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                documentType: (event.target as HTMLInputElement)
                    .value as DocumentTypeFilterOptions,
            },
        }));
    };

    return (
        <DrawerContainer handleClose={handleClose}>
            <>
                {/*widget title*/}
                <DrawerHeadingAndTitle
                    widgetData={widgetData}
                    setWidgetData={setWidgetData}
                    dataTestId={'RVWidgetTitleInput'}
                />

                <div className={styles.filterContainer}>
                    {/*/!*num of documents to display!/*/}
                    <div className={styles.marginTop20}>
                        <h4>Select the Number of Cards to be Displayed</h4>
                    </div>
                    <div>
                        <ToggleButtonGroup
                            options={[
                                {
                                    id: 'fourButton',
                                    text: '4',
                                    value: 4,
                                    dataTestId: 'RVWidgetFourCardButton',
                                },
                                {
                                    id: 'eightButton',
                                    text: '8',
                                    value: 8,
                                    dataTestId: 'RVWidgetEightCardButton',
                                },
                                {
                                    id: 'twelveButton',
                                    text: '12',
                                    value: 12,
                                    dataTestId: 'RVWidgetTwelveCardButton',
                                },
                            ]}
                            type={'noOfDocumentsToShow'}
                            selectedOption={
                                widgetData.config.noOfDocumentsToShow
                            }
                            handleButtonSelect={handleNumDocumentSelect}
                        />
                    </div>

                    {/*/!*filter heading*!/*/}
                    <div className={styles.marginTop20}>
                        <h4>Filter</h4>
                    </div>

                    {/*/!*document type*!/*/}
                    <RadioGroup
                        data-test-id={'RVWidgetDocumentTypeRadio'}
                        className={'document-type-radio'}
                        name={'filter-select'}
                        label={'Document Type'}
                        required
                        options={[
                            {
                                label: 'All',
                                value: 'ALL_DOCUMENT_TYPE',
                                id: 'all-document-type',
                            },
                            {
                                label: 'Work Orders',
                                value: 'WORK_ORDER',
                                id: 'work-orders',
                            },
                            {
                                label: 'Orders',
                                value: 'ORDER',
                                id: 'orders',
                            },
                            {
                                label: 'Drafts',
                                value: 'DRAFT',
                                id: 'drafts',
                            },
                            {
                                label: 'Quotes',
                                value: 'QUOTE',
                                id: 'quotes',
                            },
                        ].map(opt => ({
                            ...opt,
                            checked:
                                widgetData?.config?.documentType === opt.value,
                        }))}
                        onChange={updateDocumentTypeFilter}
                    />
                </div>

                <div className={styles.confirmCancelContainer}>
                    <div className={styles.cancelButtonRightPadding}>
                        <ButtonSecondary onClick={handleClose}>
                            Cancel
                        </ButtonSecondary>
                    </div>
                    <div>
                        <ButtonPrimary
                            onClick={() => {
                                handleSubmit(widgetData);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </div>
                </div>
            </>
        </DrawerContainer>
    );
}
