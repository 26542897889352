import React from 'react';
import { Widget } from 'widgets/models/WidgetModels';
import { Widgets } from '../../common/Constants';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { KeyWorkOrderStatusesWidget } from '../IndividualWidgets/keyWorkOrderStatuses/KeyWorkOrderStatuses';
import { ContractorDocumentExpirationWidget } from '../IndividualWidgets/ContractorDocumentExpiration/ContractorDocumentExpiration';
import { ExceptionFlagWidget } from '../IndividualWidgets/ExceptionFlag/ExceptionFlag';
import { RecentlyViewedWidget } from '../IndividualWidgets/recentlyViewed/RecentlyViewed';

export interface WidgetProps {
    widgetItem: Widget;
    dragging: boolean;
    onUpdateWidget: (widget: Widget) => void;
    onDelete: (id: number) => void;
    listeners: SyntheticListenerMap | undefined;
    attributes: DraggableAttributes;
}

export function RenderWidget(props: Readonly<WidgetProps>) {
    const {
        widgetItem,
        dragging,
        onUpdateWidget,
        onDelete,
        listeners,
        attributes,
        ...other
    } = props;
    if (widgetItem.widgets.key === Widgets.KeyWorkOrderStatuses) {
        return (
            <KeyWorkOrderStatusesWidget
                widgetItem={widgetItem}
                dragging={dragging}
                onUpdateWidget={onUpdateWidget}
                onDelete={onDelete}
                listeners={listeners}
                attributes={attributes}
                {...other}
            />
        );
    }
    if (widgetItem.widgets.key === Widgets.ContractorDocumentExpiration) {
        return (
            <ContractorDocumentExpirationWidget
                widgetItem={widgetItem}
                dragging={dragging}
                onUpdateWidget={onUpdateWidget}
                onDelete={onDelete}
                listeners={listeners}
                attributes={attributes}
                {...other}
            />
        );
    }
    if (widgetItem.widgets.key === Widgets.ExceptionFlag) {
        return (
            <ExceptionFlagWidget
                widgetItem={widgetItem}
                dragging={dragging}
                onUpdateWidget={onUpdateWidget}
                onDelete={onDelete}
                listeners={listeners}
                attributes={attributes}
                {...other}
            />
        );
    }
    if (widgetItem.widgets.key === Widgets.RecentlyViewed) {
        return (
            <RecentlyViewedWidget
                data={widgetItem}
                onUpdateWidget={onUpdateWidget}
                onDelete={onDelete}
                listeners={listeners}
                attributes={attributes}
                {...other}
            />
        );
    }

    return (
        <div
            style={{
                width: '100%',
                minHeight: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                fontSize: '18px',
            }}
        >
            {widgetItem.id}
        </div>
    );
}
