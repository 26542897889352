import * as React from 'react';
import { SVGProps } from 'react';

const LoadMore = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 242 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <g data-name='Group 5834'>
            <g data-name='Group 5805'>
                <path
                    data-name='Path 2647'
                    d='M11.736 19.853a7.886 7.886 0 0 1-6.51-3.513 1.697 1.697 0 0 0-.105-.125l-.066-.076-.412-.477-.164.609-.106.4c-.073.282-.143.549-.222.809a.856.856 0 0 1-.779.612h-.039a.8.8 0 0 1-.791-.516 1.113 1.113 0 0 1-.045-.588c.343-1.409.716-2.816 1.068-4.126a.841.841 0 0 1 .826-.664 1.054 1.054 0 0 1 .268.036c1.206.318 2.5.669 4.087 1.1a.867.867 0 0 1 .666 1.05.857.857 0 0 1-.858.664 1.084 1.084 0 0 1-.241-.028c-.22-.05-.446-.113-.664-.173-.13-.036-.26-.072-.39-.106-.07-.018-.133-.028-.214-.041l-.136-.023-.791-.14.458.659c.035.051.065.1.093.136.055.082.1.153.158.223a6.138 6.138 0 0 0 4.976 2.541 7.385 7.385 0 0 0 1.1-.085 6.183 6.183 0 0 0 5.008-4.135c.184-.455.408-.661.774-.712a.894.894 0 0 1 .121-.008.876.876 0 0 1 .716.375.783.783 0 0 1 .1.732 7.779 7.779 0 0 1-6.169 5.421 9.6 9.6 0 0 1-1.717.169Z'
                    fill='#aaafba'
                />
            </g>
            <g data-name='Group 5806'>
                <path
                    data-name='Path 2648'
                    d='M12.264 4.15a7.887 7.887 0 0 1 6.51 3.512c.033.044.066.082.105.125l.066.076.412.477.164-.609.106-.4c.073-.282.143-.549.223-.809a.855.855 0 0 1 .779-.612h.039a.8.8 0 0 1 .792.516 1.113 1.113 0 0 1 .045.588 181.946 181.946 0 0 1-1.068 4.126.841.841 0 0 1-.826.664 1.054 1.054 0 0 1-.269-.036c-1.205-.318-2.5-.669-4.087-1.1a.866.866 0 0 1-.665-1.05.856.856 0 0 1 .857-.664 1.084 1.084 0 0 1 .241.028c.22.05.446.113.663.173.13.036.26.072.39.106a2.165 2.165 0 0 0 .214.041l.136.023.791.14-.458-.659c-.035-.051-.065-.1-.093-.136-.055-.082-.1-.153-.158-.223a6.138 6.138 0 0 0-4.976-2.541 7.4 7.4 0 0 0-1.1.085 6.182 6.182 0 0 0-5.008 4.135c-.184.455-.408.661-.774.712a.871.871 0 0 1-.837-.366.784.784 0 0 1-.1-.732 7.779 7.779 0 0 1 6.169-5.421 9.6 9.6 0 0 1 1.717-.169Z'
                    fill='#aaafba'
                />
            </g>
        </g>
    </svg>
);

export default LoadMore;
