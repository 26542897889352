import { RootState } from '../store/CommentsStore';

export const documentsList = (state: RootState) => state.documentsList;

export const appConfigDataSelector = (state: RootState) =>
    state.appConfigurations;

export const individualDocumentComments = (state: RootState) =>
    state.showAllComments;

export const nonPersistentConfigSelector = (state: RootState) =>
    state.nonPersistentConfigurations;
