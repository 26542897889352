import React from 'react';
import ReactDOM from 'react-dom/client';
import WidgetsMF from './widgets/render/WidgetsMF';
import CommentsMF from './comments/CommentsMF';
import { Provider } from 'react-redux';
import { widgetsMFStore, widgetsMFPersistor } from 'widgets/store/WidgetsStore';
import {
    commentsMFStore,
    commentsMFPersistor,
} from './comments/store/CommentsStore';
import { ThemeProvider } from '@mui/material';
import { theme } from 'comments/theme/theme';
import { ThemeProvider as MotiliUIThemeProvider } from 'styled-components';
import { theme as motiliUiTheme } from 'motili-ui';
import { PersistGate } from 'redux-persist/integration/react';

/**
 * Manually injecting react tool-tip CSS file here because we are disabling the automatic CSS injection (via the disableStyleInjection prop)
 * to avoid injecting the same stylesheet multiple times (which generates a warning in the console) each time a tooltip component is rendered
 * reference: https://react-tooltip.com/docs/examples/styling#disabling-reacttooltip-css
 */
import 'react-tooltip/dist/react-tooltip.css' 

const appContainer = document.getElementById('root-dashboard-mf-app');
// If User hits the Dashboard App url directly, redirect to main app.
if (appContainer && process.env.NODE_ENV === 'production') {
    window.location.href = process.env.REACT_APP_CONTAINER_APP_URL;
} else if (appContainer) {
    require('./index.scss'); //pull global styles that are otherwise provided by motili-web
    /**
     * Manually injecting react tool-tip CSS file here because we are disabling the automatic CSS injection (via the disableStyleInjection prop)
     * to avoid injecting the same stylesheet multiple times (which generates a warning in the console) each time a tooltip component is rendered
     * reference: https://react-tooltip.com/docs/examples/styling#disabling-reacttooltip-css
     */
    require('react-tooltip/dist/react-tooltip.css');
    window.onload = function () {
        window.renderDashboardWidgets('root-dashboard-mf-app');
    };
}

/**
 *
 * @param {*} containerId - Id of the Div element present in Main App(MotiliWeb) into which the MicroFrontend will be injected.
 * The React Nodes for both microFrontends are stored in window object separately. Also the method to unmount MF is stored in window.
 * Comments MF will be mounted when motiliweb calls the window object, as soon as user logs into motiliweb.
 * Widgets MF will be shown only for Motili users. It will be mounted only when the user is in Dashboard route of main app.
 * During log out, both MicroFrontends are unmounted by calling the method stored in window.
 */

/**
 * We are still using ReactDOM from "react-dom" instead of "react-dom/client"(introduced in v18), because
 * if we use react-dom/client(React 18 way), the unmount() method provided will not accept containerId as input. This blocks us from unmounting them.
 * If no such options are provided in future, we should consider refreshing the page on logout, so that MicroFrontends will get unmounted.
 */

window.renderDashboardComments = containerId => {
    const root = ReactDOM.createRoot(document.getElementById(containerId));
    root.render(
        <Provider store={commentsMFStore}>
            <PersistGate loading={null} persistor={commentsMFPersistor}>
                <ThemeProvider theme={theme}>
                    <CommentsMF />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
    window.unmountDashboardComments = () => {
        root.unmount();
        window.unmountDashboardComments = undefined;
    };
};

window.renderDashboardWidgets = containerId => {
    const root = ReactDOM.createRoot(document.getElementById(containerId));
    root.render(
        <Provider store={widgetsMFStore}>
            <PersistGate loading={null} persistor={widgetsMFPersistor}>
                <MotiliUIThemeProvider theme={motiliUiTheme}>
                    <WidgetsMF />
                </MotiliUIThemeProvider>
            </PersistGate>
        </Provider>
    );
    window.unmountDashboardWidgets = () => {
        root.unmount();
        window.unmountDashboardWidgets = undefined;
    };
};
