import React from 'react';
import { Widget, BaseUrl } from '../../models/WidgetModels';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import { MenuButton } from '../menuButton/MenuButton';
import { Divider } from '../divider/Divider';
import useTableHeaderStyles from './DataTableHeaderStyles';

interface DisplayChartProps {
    chartSelectedValue: string;
    handleCustomiseWidgetMenuClick: () => void;
    handleDeleteWidgetMenuClick: () => void;
    widgetDetails: Widget;
}

export function DataTableHeader(props: Readonly<DisplayChartProps>): JSX.Element {
    const styles = useTableHeaderStyles();
    const {
        chartSelectedValue,
        handleCustomiseWidgetMenuClick,
        handleDeleteWidgetMenuClick,
        widgetDetails,
    } = props;

    return (
        <div className={styles.tableHeaderContainer}>
            <div className={styles.headerAndFilterIcon}>
                <h3>
                    {chartSelectedValue === 'DRAFTED'
                        ? 'Waiting for Approval'
                        : snakeCaseToTitleCase(chartSelectedValue)}
                </h3>
                <div className={styles.italicFont}>
                    <img
                        alt={'filter icon'}
                        src={
                            `${BaseUrl.widgetImage}/filter-icon.svg`
                        }
                    />{' '}
                    Filters Active - See customization to change these
                </div>
            </div>
            <div>
                <div className={styles.expandedTableMenuButton}>
                    <Divider height={'40px'} />

                    <MenuButton
                        handleCustomiseWidgetMenuClick={
                            handleCustomiseWidgetMenuClick
                        }
                        handleDeleteWidgetMenuClick={
                            handleDeleteWidgetMenuClick
                        }
                        widgetId={widgetDetails.id.toString()}
                    />
                </div>
            </div>
        </div>
    );
}
