import React, { useMemo } from 'react';
import { DataTable } from 'motili-ui';
import {
    WorkOrderFilters,
    TableRecords,
} from '../../models/KeyWorkOrderStatusesModel';
import { TableHeaders } from '../../models/WidgetModels';
import { useGetFilteredWorkOrdersQuery } from '../../store/api/KeyWorkOrderStatusesWidgetApi';
import {
    snakeCaseToTitleCase,
    shortenedAddress,
} from '../../../utils/WidgetUtils';

interface KeyWorkOrderStatusTableProps {
    mappedWorkOrderCountData:
        | { label: string; value: string; count: number }[]
        | undefined;
    activeTablePage: number;
    onPageClick: (e: number) => void;
    workOrderFilters: WorkOrderFilters;
    chartSelectedValue: string;
    recordsPerPage: number;
}

export function KeyWorkOrderStatusesTable(
    props: Readonly<KeyWorkOrderStatusTableProps>
): JSX.Element {
    const {
        mappedWorkOrderCountData,
        activeTablePage,
        onPageClick,
        workOrderFilters,
        chartSelectedValue,
        recordsPerPage,
    } = props;

    const tableHeaders: TableHeaders[] = [
        {
            displayName: 'Number',
            value: 'id',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Type',
            value: 'workOrderTypeId',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Client',
            value: 'client',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Address',
            value: 'stdAddress',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'State',
            value: 'stdRegion',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Contractor Company',
            value: 'contractor',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Reference One',
            value: 'accountManager',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Since Creation',
            value: 'createdDate',
            sortable: false,
            ascending: false,
        },
    ];

    // Api call to get work order table data
    const { data: GetFilteredWorkOrdersData } = useGetFilteredWorkOrdersQuery(
        {
            ...workOrderFilters,
            page: activeTablePage - 1,
            statusIds: [chartSelectedValue],
        },
        { refetchOnMountOrArgChange: true }
    );
    const totalNumberOfRecords:
        | {
              label: string;
              value: string;
              count: number;
          }[]
        | undefined = mappedWorkOrderCountData?.filter(
        obj => obj.value === chartSelectedValue
    );

    const mappedWorkOrderData: TableRecords[] = useMemo(() => {
        if (GetFilteredWorkOrdersData) {
            return GetFilteredWorkOrdersData.result.map((record, index) => {
                const numberLink: string = `/#/ops/work-orders/${record.id}/details`;
                const clientLink: string = `/#/ops/clients/${record.client.id}/details`;
                const contractorLink: string = `/#/ops/contractors/${record.contractor?.id}/details`;
                const workOrderTypeDisplay: string = snakeCaseToTitleCase(
                    record.workOrderTypeId
                );
                const stdAddress: string = shortenedAddress(
                    record.property.stdAddress
                );
                const extReference1: string = record.extReferenceNumber1
                    ? record.extReferenceNumber1
                    : 'No Reference';
                const givenDateObj: any = new Date(record.created);
                const todayDateObj: any = new Date();
                const timeDifference: number = givenDateObj - todayDateObj;
                const differenceInDays: number = Math.round(
                    timeDifference / (1000 * 60 * 60 * 24)
                );

                return {
                    id: (
                        <a
                            data-test-id={`KWOSTableIdLink${index}`}
                            href={numberLink}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            {record.number}
                        </a>
                    ),
                    workOrderTypeId: workOrderTypeDisplay,
                    client: (
                        <a
                            data-test-id={`KWOSTableClientLink${index}`}
                            href={clientLink}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            {record.client.name}
                        </a>
                    ),
                    stdAddress: stdAddress,
                    stdRegion: record.property.stdRegion,
                    contractor: record.contractor?.name ? (
                        <a
                            data-test-id={`KWOSTableContractorLink${index}`}
                            href={contractorLink}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            {record.contractor?.name}
                        </a>
                    ) : (
                        'NCF'
                    ),
                    accountManager: extReference1,
                    createdDate: `${Math.abs(differenceInDays)} Days`,
                };
            });
        }
        return [];
    }, [GetFilteredWorkOrdersData]);

    return (
        <DataTable
            headerBackground={'#0E2248'}
            headerTextColor={'#FFFFFF'}
            data-test-id={'KWOSWidgetClosedTable'}
            totalRecordCount={
                totalNumberOfRecords ? totalNumberOfRecords[0].count : 0
            }
            records={mappedWorkOrderData}
            tableHeaders={tableHeaders}
            showPagination
            activePage={activeTablePage}
            recordsPerPage={recordsPerPage}
            onPageClick={pageNumber => {
                onPageClick(pageNumber);
            }}
            onListItemClick={listItem => {}}
            onTableHeaderItemClick={headerItem => {}}
        />
    );
}
