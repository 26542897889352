import * as React from 'react';
import { SVGProps } from 'react';

const Tooltip = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 120 \u2013 20'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <g data-name='Group 4690'>
            <g data-name='Group 4689' fill='#17c2ed'>
                <path
                    data-name='Path 2440'
                    d='M7.991 15.998A8 8 0 1 1 16 8.022a7.993 7.993 0 0 1-8.009 7.976Zm6.413-8a6.4 6.4 0 1 0-6.406 6.406 6.41 6.41 0 0 0 6.406-6.407Z'
                />
                <path
                    data-name='Path 2441'
                    d='M7.101 10.553c0-.621.023-2.425.034-3.046a.88.88 0 0 1 .767-.853.9.9 0 0 1 1 .638.754.754 0 0 1 .033.208c0 1.235-.023 3.1-.033 4.333a.878.878 0 0 1-.808.86.9.9 0 0 1-.952-.645 1.282 1.282 0 0 1-.037-.334v-1.16Z'
                />
                <path
                    data-name='Path 2442'
                    d='M8.902 4.846a.9.9 0 0 1-.9.9.9.9 0 1 1 .9-.9Z'
                />
            </g>
        </g>
    </svg>
);

export default Tooltip;
