import { getCommentsApi } from './FeedsApi';
import { UpdateReadStatusReq } from 'comments/models/CommentsModel';

const UpdateReadUnreadStatusApi = getCommentsApi.injectEndpoints({
    endpoints: builder => ({
        UpdateReadStatus: builder.mutation<boolean, UpdateReadStatusReq[]>({
            query(object) {
                return {
                    url: `/CommentNotifications/readStatus`,
                    method: 'PATCH',
                    body: object,
                };
            },
        }),
    }),
});

export const { useUpdateReadStatusMutation } = UpdateReadUnreadStatusApi;
