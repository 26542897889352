import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { BaseUrl } from '../../models/WidgetModels';
import CustomizeIcon from '../../../common/icons/CustomizeIcon';
import RemoveIcon from '../../../common/icons/RemoveIcon';
import ClickAwayListener from 'react-click-away-listener';
import useMenuButtonStyles from './MenuButtonStyles';

interface MenuProps {
    handleCustomiseWidgetMenuClick: () => void;
    handleDeleteWidgetMenuClick: () => void;
    widgetId: string;
}

export function MenuButton(props: Readonly<MenuProps>): JSX.Element {
    const styles = useMenuButtonStyles();
    const {
        handleCustomiseWidgetMenuClick,
        handleDeleteWidgetMenuClick,
        widgetId,
    } = props;

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const buttonId: string = `menu-button-${widgetId}`;

    return (
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <div>
                <button
                    id={`menu-button-${buttonId}`}
                    onClick={() => {
                        setMenuOpen(!menuOpen);
                    }}
                    className={styles.menuLayout}
                >
                    <div>
                        <img
                            alt={'menu button'}
                            src={`${BaseUrl.widgetImage}/triple-dot-vert-icon.svg`}
                        />
                        <Tooltip
                            id={`menu-button-tooltip-${buttonId}`}
                            anchorSelect={`#menu-button-${buttonId}`}
                            clickable
                            openOnClick
                            isOpen={menuOpen}
                            place={'bottom-end'}
                            opacity={1}
                            style={{
                                backgroundColor: '#0e2248',
                                zIndex: 5,
                                borderRadius: 6,
                                maxWidth: 'fit-content',
                            }}
                            disableStyleInjection={'core'}
                        >
                            <div
                                id='menu-list-quote'
                                style={{ maxWidth: 'fit-content' }}
                            >
                                <div
                                    className={styles.menuItemStyle}
                                    onClick={() => {
                                        handleCustomiseWidgetMenuClick();
                                        setMenuOpen(false);
                                    }}
                                >
                                    <span className={styles.menuItemIconStyle}>
                                        <CustomizeIcon color={'white'} />
                                    </span>
                                    <span className={styles.menuItemTextStyle}>
                                        Customize
                                    </span>
                                </div>
                                <div
                                    className={styles.menuItemStyle}
                                    onClick={() => {
                                        handleDeleteWidgetMenuClick();
                                        setMenuOpen(false);
                                    }}
                                >
                                    <span className={styles.menuItemIconStyle}>
                                        <RemoveIcon />
                                    </span>
                                    <div className={styles.menuItemTextStyle}>
                                        <span>Delete</span>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                </button>
            </div>
        </ClickAwayListener>
    );
}
