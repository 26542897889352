import { Components, createTheme } from '@mui/material';

const FONTFAMILY_PARAGRAPH = 'Nunito Sans';
const FONTFAMILY_HEADING = 'Nunito Sans';
const COLOR_HEADING = '#333333';

export const muiComponentTheme: Components = {
    MuiDialog: {
        defaultProps: {
            disableScrollLock: true,
        },
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                color: '#AAAFBA',
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            root: {
                fontSize: '24px',
            },
        },
    },
};

export const themeObject: any = {
    components: { ...muiComponentTheme },
    palette: {
        background: {
            default: '#F7F7F7',
        },
        primary: {
            main: '#17C2ED',
            light: '#314976',
            dark: '#0E2248',
            contrastText: '#314976',
        },
        secondary: {
            main: '#F5F5F5', //Secondary button background color
            light: '#3A3A3A', // Secondary button text color
            dark: '#E1E1E1', //Secondary button background(on hover) color
            contrastText: '#1DBAAF', // Secondary button disabled.
        },
        text: {
            primary: '#333333', // Text
            secondary: '#FFFFFF', // Text
        },
        divider: '#DFE4ED', // also used in border color
        action: {
            active: COLOR_HEADING, // Link active/hover state
            selected: '#8F9197', // variable; Wizard button selected
            hover: '#333333', // Outlined button border color in hover state
            disabled: '#E1E1E1',
        },
    },
    typography: {
        fontWeightMedium: 500,
        h1: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 600, // variable
            fontSize: '40px',
            color: COLOR_HEADING,
            lineHeight: '56px',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 600, // variable
            fontSize: '32px', //
            color: COLOR_HEADING,
            lineHeight: '38px',
            letterSpacing: 0,
        },
        h3: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 600, // variable
            fontSize: '28px',
            color: COLOR_HEADING,
            lineHeight: '32px',
            letterSpacing: 0,
        },
        h4: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 600, // variable
            fontSize: '24px',
            color: COLOR_HEADING,
            lineHeight: '30px',
            letterSpacing: 0,
        },
        h5: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 500, // variable
            fontSize: '20px',
            color: COLOR_HEADING,
            lineHeight: '28px',
            letterSpacing: 0,
            textTransform: 'none',
        },
        body1: {
            // Paragraph
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '14px',
            lineHeight: '22px',
            letterSpacing: 0,
        },
        body2: {
            // Paragraph Small
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: 0,
        },
        subtitle1: {
            // Link
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 600, // variable
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: COLOR_HEADING,
        },
        subtitle2: {
            // Footer
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 600, // variable
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: '#6D6D6D',
        },
        button: {
            // Button
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 600, // variable
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
    },
    spacing: 10,
    shape: {
        borderRadius: 4,
    },
};

export const theme = createTheme(themeObject);
