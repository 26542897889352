import { createUseStyles } from 'react-jss';

const useInternalLoadingStyles = createUseStyles({
    loadingOverlayComments: {
        zIndex: 49,
        position: 'absolute',
        width: '100%',
        height: '100%',
        margin: 0,
    },

    'loadingOverlayComments::before': {
        display: 'none',
    },

    'loadingOverlayComments span': {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    loadingOverlayWidget: {
        zIndex: 3,
    },
});

export default useInternalLoadingStyles;
