import * as React from 'react';

const MarkAllAsRead = props => (
    <svg
        data-name='Component 339 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <g data-name='Group 5900'>
            <g data-name='Group 5170'>
                <g data-name='Group 5171'>
                    <path
                        data-name='Path 2562'
                        d='M3.192 22.995a1.5 1.5 0 0 1-1.5-1.5V5.941a4.756 4.756 0 0 1 4.75-4.75h10.672a4.756 4.756 0 0 1 4.75 4.75v9.253a4.756 4.756 0 0 1-4.75 4.75H6.993l-2.865 2.712a.8.8 0 0 1-.128.1 1.488 1.488 0 0 1-.808.239Zm.341-.966ZM6.447 2.921a3.022 3.022 0 0 0-3.02 3.02v15l2.626-2.487a.867.867 0 0 1 .595-.237h10.466a3.023 3.023 0 0 0 3.021-3.02V5.944a3.023 3.023 0 0 0-3.021-3.02Z'
                        fill='#17c2ed'
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default MarkAllAsRead;
