import React, { useState, useEffect, useMemo } from 'react';
import {
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Chart,
    Legend,
    Tooltip,
    ChartOptions,
} from 'chart.js';
import { Widget, ChartData } from '../../models/WidgetModels';
import { WorkOrderFilters } from '../../models/KeyWorkOrderStatusesModel';
import { WidgetProps } from '../../render/RenderWidget';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import AlertIcon from 'common/icons/AlertIcon';
import DragIcon from 'common/icons/DragIcon';
import { useGetFilteredWorkOrderCountQuery } from '../../store/api/KeyWorkOrderStatusesWidgetApi';
import { CustomizeWidgetDrawer } from './KeyWorkOrderStatusesDrawer';
import { KeyWorkOrderStatusesTable } from './KeyWorkOrderStatusesTable';
import { KeyWorkOrderStatusesDisplayChartType } from './KeyWorkOrderStatusesDisplayChartType';
import { DataTableHeader } from '../../components/tableHeader/DataTableHeader';
import { MenuButton } from '../../components/menuButton/MenuButton';
import { ToolTipQuestionMarkIcon } from '../../components/toolTipQuestionMark/ToolTipQuestionMark';
import useLibraryStyles from '../../components/WidgetStyleLibrary';

Chart.register(
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
    Tooltip
);

/**
 * @param {WidgetProps} props - The props for the component. Should not be modified within the component.
 */

export function KeyWorkOrderStatusesWidget(
    props: Readonly<WidgetProps>
): JSX.Element {
    const styles = useLibraryStyles();
    const {
        widgetItem: data,
        dragging,
        onUpdateWidget,
        listeners,
        attributes,
    } = props;

    const [activeTablePage, setActiveTablePage] = useState<number>(1);

    // Setting filters for workOrderFilters object
    const clientFilter: number[] = data.config.clients.map(
        client => client.value
    );
    const childClientFilter: number[] = data.config.childClients.map(
        child => child.value
    );
    const clientWorkOrderFilter: number[] = [
        ...clientFilter,
        ...childClientFilter,
    ];
    const contractorWorkOrderFilter: number[] = data.config.contractors.map(
        contractor => contractor.value
    );
    let userIds: string[] | number[] | null;
    if (data.config.view === 'Just Me') {
        userIds = [data.accountid];
    } else if (data.config.view === 'All') {
        userIds = null;
    } else {
        userIds = data.config.user.map(record => record.value);
    }

    // Main filter for count and work order api calls
    const workOrderFilters: WorkOrderFilters = {
        userIds: userIds,
        noOfDocumentsToShow: data.config.noOfDocumentsToShow,
        page: activeTablePage - 1,
        ...(data.config.residentStatus !== 'All' && {
            occupied: data.config.residentStatus === 'Occupied',
        }),
        ...(data.config.workOrderTypes.length && {
            workOrderTypes: data.config.workOrderTypes.map(
                record => record.value
            ),
        }),
        ...(data.config.states.length && {
            states: data.config.states.map(record => record.value),
        }),
        ...(clientWorkOrderFilter.length && data.config.workOrderDataType === 'client' && {
            clientIds: clientWorkOrderFilter,
        }),
        ...(contractorWorkOrderFilter.length && data.config.workOrderDataType === 'contractor' && {
            contractorIds: contractorWorkOrderFilter,
        }),
        dateRangeType: data.config.dateRangeType.value,
        dateRangeFrom: data.config.dateRangeFrom.value,
    };

    // Api call to get count data for chart/graph
    const { data: GetFilteredWorkOrderCountData } =
        useGetFilteredWorkOrderCountQuery(
            {
                ...workOrderFilters,
                statusIds: data.config.workOrderStatuses.map(
                    status => status.value
                ),
            },
            { refetchOnMountOrArgChange: true }
        );
    const mappedWorkOrderCountData = useMemo(() => {
        if (GetFilteredWorkOrderCountData) {
            return GetFilteredWorkOrderCountData.map(record => ({
                label: record.label,
                value: record.label,
                count: record.count,
            }));
        }
        return [];
    }, [GetFilteredWorkOrderCountData]);

    const [noDataResults, setNoDataResults] = useState<boolean>(false);
    const [configRequired, setConfigRequired] = useState<boolean>(
        !data.config.initialConfig
    );
    const [displayTable, setDisplayTable] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [chartData, setChartData] = useState<ChartData>({
        labels: [],
        values: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderRadius: 5,
                borderWidth: 1,
                hoverOffset: 2,
                minBarLength: 8,
            },
        ],
    });
    const [chartSelectedValue, setChartSelectedValue] = useState<string>('');

    // Settings for chart/graph
    const doughnutOptions: ChartOptions<'doughnut'> = {
        ...(dragging && { animation: false }),
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 15,
                    font: {
                        weight: 'bold',
                    },
                    generateLabels: function (chart) {
                        const data = chart.data;
                        const chartData: number[] =
                            mappedWorkOrderCountData?.map(
                                record => record.count
                            ) || [];
                        const meta = chart.getDatasetMeta(0);

                        if (data?.labels?.length && data.datasets.length) {
                            return data.labels.map(function (label, i) {
                                const style = meta.controller.getStyle(i, true);
                                const tableColor: string =
                                    chartSelectedValue &&
                                    snakeCaseToTitleCase(chartSelectedValue) ===
                                        label
                                        ? 'black'
                                        : '#818181';

                                return {
                                    fontColor: !chartSelectedValue
                                        ? 'black'
                                        : tableColor,
                                    text:
                                        label === 'Drafted'
                                            ? `Waiting for Approval (${chartData[i]})`
                                            : `${label} (${chartData[i]})`,
                                    fillStyle: style.backgroundColor,
                                    datasetIndex: i,
                                };
                            });
                        }
                        return [];
                    },
                },
            },
            tooltip: {
                bodyAlign: 'center',
                callbacks: {
                    label: function (tooltipItem) {
                        const chartData: number[] =
                            mappedWorkOrderCountData?.map(
                                record => record.count
                            ) || [];
                        const value: number = chartData[tooltipItem.dataIndex];
                        return `${value}`;
                    },
                },
            },
        },
        spacing: 3,
        cutout: '60%',
    };
    const barOptions: ChartOptions<'bar'> = {
        ...(dragging && { animation: false }),
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                bodyAlign: 'center',
            },
        },
    };

    useEffect((): void => {
        if (!mappedWorkOrderCountData?.length) {
            setNoDataResults(true);
            return;
        } else {
            setNoDataResults(false);
        }

        if (mappedWorkOrderCountData) {
            const chartData: number[] | null =
                mappedWorkOrderCountData.length > 0
                    ? mappedWorkOrderCountData.map(record => record.count)
                    : null;

            let inPercent;
            if (chartData) {
                const total: number = chartData.reduce((a, v) => a + v);
                inPercent = chartData.map(v => Math.max((v / total) * 100, 1));
            }

            if (chartSelectedValue) {
                const backgroundColors: string[] = [
                    '#0e2248',
                    '#667fae',
                    '#2c6ee8',
                    '#334669',
                    '#57c0e8',
                ];
                const index: number = mappedWorkOrderCountData.findIndex(
                    record => record.value === chartSelectedValue
                );

                setChartData({
                    labels:
                        mappedWorkOrderCountData.map(record => {
                            return record.label === 'DRAFTED'
                                ? 'Waiting for Approval'
                                : snakeCaseToTitleCase(record.label);
                        }) || [],
                    values:
                        mappedWorkOrderCountData.map(record => record.value) ||
                        [],
                    datasets: [
                        {
                            data:
                                data.config.chartType === 'Bar Graph'
                                    ? mappedWorkOrderCountData.map(
                                          record => record.count
                                      )
                                    : inPercent,
                            backgroundColor: mappedWorkOrderCountData.map(
                                (record, idx) => {
                                    return idx === index
                                        ? backgroundColors[index]
                                        : '#f5f5f5';
                                }
                            ),
                            borderColor: ['#ffffff'],
                            borderRadius: 5,
                            borderWidth: 3,
                            hoverOffset: 2,
                            minBarLength: 8,
                        },
                    ],
                });
            } else {
                setChartData({
                    labels:
                        mappedWorkOrderCountData.map(record => {
                            return record.label === 'DRAFTED'
                                ? 'Waiting for Approval'
                                : snakeCaseToTitleCase(record.label);
                        }) || [],
                    values:
                        mappedWorkOrderCountData.map(record => record.value) ||
                        [],
                    datasets: [
                        {
                            data:
                                data.config.chartType === 'Bar Graph'
                                    ? mappedWorkOrderCountData?.map(
                                          record => record.count
                                      )
                                    : inPercent,
                            backgroundColor: [
                                '#0e2248',
                                '#667fae',
                                '#2c6ee8',
                                '#334669',
                                '#57c0e8',
                            ],
                            borderColor: ['#ffffff'],
                            borderRadius: 5,
                            borderWidth: 3,
                            hoverOffset: 2,
                            minBarLength: 8,
                        },
                    ],
                });
            }
        }
    }, [
        GetFilteredWorkOrderCountData,
        chartSelectedValue,
        data.config.chartType,
        mappedWorkOrderCountData,
        dragging,
    ]);
    useEffect((): void => {
        if (noDataResults) {
            setDisplayTable(false);
        }
    }, [noDataResults]);
    useEffect((): void => {
        if (attributes['aria-pressed']) {
            setChartSelectedValue('');
            setDisplayTable(false);
        }
    }, [attributes]);

    const handleCustomiseWidgetMenuClick = (): void => {
        setDrawerOpen(true);
        setDisplayTable(false);
        setChartSelectedValue('');
    };
    const handleDeleteWidgetMenuClick = (): void => {
        setDisplayTable(false);
        setChartSelectedValue('');

        props.onDelete(data.id);
    };

    return (
        <div className={displayTable ? styles.rootExpanded : styles.root}>
            {drawerOpen && (
                <CustomizeWidgetDrawer
                    widgetDetails={data}
                    handleClose={() => {
                        setDrawerOpen(false);
                    }}
                    handleSubmit={(widgetDetails: Widget) => {
                        if (!widgetDetails.config.initialConfig) {
                            widgetDetails.config.initialConfig = true;
                            setConfigRequired(false);
                        }

                        onUpdateWidget(widgetDetails);
                        setDrawerOpen(false);
                    }}
                />
            )}
            <div
                style={{
                    height:
                        data.config.chartType === 'Bar Graph' &&
                        !noDataResults &&
                        !drawerOpen
                            ? '300px'
                            : '100%',
                }}
            >
                <div className={styles.widgetHeader}>
                    <div
                        className={styles.draggableAreaStyle}
                        {...listeners}
                        {...attributes}
                    >
                        <DragIcon />
                        <div className={styles.widgetTitle}>
                            {data.config.name}
                        </div>
                    </div>
                    {!displayTable && (
                        <MenuButton
                            key={data.id}
                            handleCustomiseWidgetMenuClick={
                                handleCustomiseWidgetMenuClick
                            }
                            handleDeleteWidgetMenuClick={
                                handleDeleteWidgetMenuClick
                            }
                            widgetId={data.id.toString()}
                        />
                    )}
                </div>
                <div>
                    {!configRequired && !noDataResults && !drawerOpen ? (
                        <div className={styles.chartAndTooltipContainer}>
                            <KeyWorkOrderStatusesDisplayChartType
                                displayType={data.config.chartType}
                                setChartSelectedValue={setChartSelectedValue}
                                chartSelectedValue={chartSelectedValue}
                                setDisplayTable={setDisplayTable}
                                setActiveTablePage={setActiveTablePage}
                                chartData={chartData}
                                barOptions={barOptions}
                                doughnutOptions={doughnutOptions}
                            />
                            <ToolTipQuestionMarkIcon
                                dataTestId={'KWOSWidgetTooltipButton'}
                                widgetId={data.id.toString()}
                            >
                                <>
                                    <strong>Tool Tip</strong>
                                    Select the individual status on the chart to
                                    view the related Work Orders on a table.
                                    <br />
                                    <br />
                                    Reselect the status to minimize the Work
                                    Order table.
                                </>
                            </ToolTipQuestionMarkIcon>
                        </div>
                    ) : (
                        <>
                            {configRequired && !drawerOpen && (
                                <div
                                    className={styles.configureViewLayout}
                                    onClick={() => {
                                        setDrawerOpen(true);
                                    }}
                                >
                                    <div className={styles.widgetStatusFont}>
                                        Customize to Configure View
                                    </div>
                                </div>
                            )}
                            {noDataResults &&
                                !configRequired &&
                                !drawerOpen && (
                                    <div className={styles.noContentLayout}>
                                        <div className={styles.alertIconStyle}>
                                            <div
                                                className={
                                                    styles.alertIconWrapper
                                                }
                                            >
                                                <AlertIcon />
                                            </div>
                                            <div
                                                className={
                                                    styles.widgetStatusFont
                                                }
                                            >
                                                No Records to Show!
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {drawerOpen && (
                                <div className={styles.noContentLayout}>
                                    <div className={styles.widgetStatusFont}>
                                        Configuring View
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {displayTable && chartSelectedValue && (
                <div className={styles.chartOpenContainer}>
                    <DataTableHeader
                        chartSelectedValue={chartSelectedValue}
                        handleCustomiseWidgetMenuClick={
                            handleCustomiseWidgetMenuClick
                        }
                        handleDeleteWidgetMenuClick={
                            handleDeleteWidgetMenuClick
                        }
                        widgetDetails={data}
                    />
                    <div style={{ paddingTop: '20px' }}>
                        <KeyWorkOrderStatusesTable
                            mappedWorkOrderCountData={mappedWorkOrderCountData}
                            activeTablePage={activeTablePage}
                            workOrderFilters={workOrderFilters}
                            chartSelectedValue={chartSelectedValue}
                            recordsPerPage={data.config.noOfDocumentsToShow}
                            onPageClick={pageNumber => {
                                setActiveTablePage(pageNumber);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
