import React from 'react';
import { Widget } from 'widgets/models/WidgetModels';
import { RenderWidget } from './RenderWidget';
import { useSortable } from '@dnd-kit/sortable';

interface Props {
    id: number;
    value: Widget;
    dragging: boolean;
    onUpdateWidget: (widget: Widget) => void;
    onDelete: (id: number) => void;
}

const SortableItem = (props: Props) => {
    const { value, dragging, onUpdateWidget, onDelete, id } = props;
    const { attributes, listeners, setNodeRef, isDragging } = useSortable({
        id: id,
    });

    return (
            <div ref={setNodeRef} style={{opacity: isDragging ? .3 : 1}}>
                <RenderWidget
                    widgetItem={value}
                    dragging={dragging}
                    onUpdateWidget={onUpdateWidget}
                    onDelete={onDelete}
                    listeners={listeners}
                    attributes={attributes}
                />
            </div>
    );
};

export default SortableItem;
