import React from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ButtonPrimary,
    ButtonSecondary,
} from 'motili-ui';
import AlertIcon from '../../common/icons/AlertIcon';
import useDeleteWidgetConfirmationModalStyles from './DeleteWidgetConfirmationModalStyles';

interface Props {
    handleCancel: () => void;
    handleConfirm: (widgetId: number) => void;
    widgetId: number;
}

export function DeleteWidgetConfirmationModal(props: Props) {
    const styles = useDeleteWidgetConfirmationModalStyles();

    const { handleConfirm, handleCancel, widgetId } = props;

    return (
        <Modal showClose handleOnClose={handleCancel}>
            <div style={{ width: '500px' }}>
                <ModalBody>
                    <div className={styles.alertIconStyle}>
                        <div className={styles.alertIconWrapper}>
                            <AlertIcon />
                        </div>
                    </div>
                    <h3 className={styles.boldCenterContent}>Are you sure?</h3>
                    <div className={styles.centerContent}>
                        You can remove your widget and add later
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={styles.centerContent}>
                        <ButtonSecondary
                            onClick={handleCancel}
                            buttonStyle={{ marginRight: '15px' }}
                        >
                            Cancel
                        </ButtonSecondary>
                        <ButtonPrimary onClick={() => handleConfirm(widgetId)}>
                            Yes, Proceed
                        </ButtonPrimary>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    );
}
