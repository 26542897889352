import * as React from 'react';
import { SVGProps } from 'react';

const CommentsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 114 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <g data-name='Group 4685' fill='#10244a'>
            <path
                data-name='Path 2379'
                d='M23.191 9.05a10.694 10.694 0 0 0-12.528-7.839 10.729 10.729 0 0 0-6.917 4.7 10.74 10.74 0 0 0-1.417 8.363 10.421 10.421 0 0 0 1.99 4.1l-.045.046-1.845 1.845a1.277 1.277 0 0 0-.4 1.23 1.257 1.257 0 0 0 1.329.994h5.027c1.456 0 2.924 0 4.378-.016a11.625 11.625 0 0 0 2.365-.256 10.576 10.576 0 0 0 6.817-4.866 10.6 10.6 0 0 0 1.246-8.301ZM6.956 19.396a1.292 1.292 0 0 0 .005-1.935l-.033-.033a8.052 8.052 0 0 1-2.306-5.932 8.132 8.132 0 0 1 6.777-7.789 8.138 8.138 0 0 1 6.053 1.354 8.1 8.1 0 0 1 3.348 5.207 8.077 8.077 0 0 1-1.325 6.125 8.151 8.151 0 0 1-5.343 3.383 8.255 8.255 0 0 1-1.353.122c-1.64.009-3.308.008-4.921.007H6.449c.176-.177.342-.342.506-.51Z'
            />
            <g data-name='Group 4422' transform='translate(-1181 -37)'>
                <path
                    data-name='Path 2438'
                    d='M1191 46h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2Z'
                />
                <rect
                    data-name='Rectangle 5202'
                    width={6}
                    height={2}
                    rx={1}
                    transform='translate(1191 50)'
                />
            </g>
        </g>
    </svg>
);

export default CommentsIcon;
