import { UserOrGroupListResponse } from 'comments/models/CommentsModel';
import { getCommentsApi } from './FeedsApi';

export const userListApi = getCommentsApi.injectEndpoints({
    endpoints: builder => ({
        GetUsersAndGroupList: builder.query<UserOrGroupListResponse[], string>({
            query() {
                return {
                    url: `/accounts/get-user-and-group-mentions-list`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useGetUsersAndGroupListQuery } = userListApi;
