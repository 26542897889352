import React, { JSXElementConstructor, ReactElement } from 'react';
import { CloseIcon } from '../../../common/icons/Close';
import useFullscreenModalStyles from './FullscreenModalStyles';

interface FullscreenProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export function FullscreenModal(props: FullscreenProps): JSX.Element | null {
    const styles = useFullscreenModalStyles();
    const { isOpen, onClose, children } = props;

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.fullscreenModal}>
            <button className={styles.closeButton} onClick={onClose}>
                <CloseIcon color={'black'} />
            </button>
            {children}
        </div>
    );
}
