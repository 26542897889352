import * as React from 'react';
import { SVGProps } from 'react';

const UnselectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 68 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        {...props}
    >
        <g data-name='Rectangle 5778' fill='#fff' stroke='#aaafba'>
            <rect width={20} height={20} rx={2} stroke='none' />
            <rect x={0.5} y={0.5} width={19} height={19} rx={1.5} fill='none' />
        </g>
    </svg>
);

export default UnselectedIcon;
