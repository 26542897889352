import * as React from 'react';
import { SVGProps } from 'react';

const AddWidgetIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 127 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <g data-name='Group 5594'>
            <g data-name='Group 5382' fill='#fff'>
                <path
                    data-name='Path 2587'
                    d='M11.943 14.396a5.306 5.306 0 0 1-.648-.267q-4.639-2.67-9.269-5.354a1.058 1.058 0 0 1-.015-1.96q4.652-2.7 9.313-5.378a1.165 1.165 0 0 1 1.263-.007q4.655 2.691 9.313 5.376a1.062 1.062 0 0 1 .007 1.963q-4.65 2.7-9.313 5.378a4.43 4.43 0 0 1-.651.249Zm7.407-6.587c-.123-.081-.187-.129-.257-.169-2.28-1.305-4.557-2.615-6.847-3.9a.736.736 0 0 0-.61.023c-2.247 1.262-4.483 2.545-6.72 3.825-.1.059-.2.14-.325.233 2.412 1.38 4.776 2.737 7.148 4.079a.528.528 0 0 0 .441 0c2.372-1.342 4.736-2.698 7.17-4.091Z'
                />
                <path
                    data-name='Path 2588'
                    d='M22.563 11.848a1.3 1.3 0 0 1-.717 1.2q-3.847 2.211-7.685 4.439c-.529.306-1.062.606-1.589.917a1.119 1.119 0 0 1-1.218 0q-4.678-2.707-9.358-5.409a1.058 1.058 0 0 1-.47-1.513 1.075 1.075 0 0 1 1.558-.3q4.219 2.41 8.428 4.837a.769.769 0 0 0 .9 0c2.861-1.655 5.727-3.3 8.614-4.908a1.231 1.231 0 0 1 1.01.044c.269.157.415.538.527.693Z'
                />
                <path
                    data-name='Path 2589'
                    d='M22.563 16.083a1.3 1.3 0 0 1-.714 1.2q-4.063 2.335-8.117 4.685c-.386.223-.774.445-1.158.673a1.122 1.122 0 0 1-1.219 0q-4.678-2.708-9.357-5.413a1.062 1.062 0 1 1 1.084-1.811q4.219 2.41 8.429 4.838a.768.768 0 0 0 .892.008c2.86-1.656 5.726-3.3 8.614-4.911a1.234 1.234 0 0 1 1.011.039c.275.151.422.536.535.692Z'
                />
            </g>
        </g>
    </svg>
);

export default AddWidgetIcon;
