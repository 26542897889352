import React from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { DropDownSelect } from 'motili-ui';
import {
    useChildClientDropdown,
    DrawerChildClientDropdownProps,
} from './UseChildClientDropdown';

export function DrawerChildClientDropdown(
    props: Readonly<DrawerChildClientDropdownProps>
) {
    const {
        childClientOptions,
        handleChildClientDropdown,
        menuOpen,
        setMenuOpen,
        components,
    } = useChildClientDropdown(props);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setMenuOpen(false);
            }}
        >
            <div style={{ width: '263px' }}>
                <DropDownSelect
                    data-test-id={'KWOSWidgetChildClientDropdown'}
                    name={'child-client-multi-select'}
                    label={'Child Client(s)'}
                    isMulti
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    menuIsOpen={menuOpen}
                    componentOverrides={components}
                    /*@ts-ignore*/
                    options={childClientOptions}
                    /*@ts-ignore*/
                    value={props.widgetData.config.childClients}
                    onChange={selectedChildClients => {
                        handleChildClientDropdown(selectedChildClients);
                    }}
                ></DropDownSelect>
            </div>
        </ClickAwayListener>
    );
}
