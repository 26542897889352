import React from 'react';
import AlertHandler from '../../common/AlertHandler';
import WidgetsContainer from './WidgetsContainer';

export function WidgetsWrapper() {
    return (
        <div>
            <AlertHandler />
            <WidgetsContainer />
        </div>
    );
}
