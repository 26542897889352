import React, { useEffect, useState } from 'react';
import {
    ControlProps,
    GroupBase,
    MenuListProps,
    OptionProps,
    SelectComponentsConfig,
    ValueContainerProps,
} from 'react-select';
import { Checkbox } from 'motili-ui';
import { ChildClient, Widget } from '../../../models/WidgetModels';
import { useGetChildClientsQuery } from '../../../store/api/KeyWorkOrderStatusesWidgetApi';
import styled from 'styled-components';

export interface DrawerChildClientDropdownProps {
    widgetData: Widget;
    setWidgetData: React.Dispatch<React.SetStateAction<Widget>>;
    path: string;
}
export function useChildClientDropdown(
    props: Readonly<DrawerChildClientDropdownProps>
) {
    const { widgetData, setWidgetData, path } = props;
    const [childClientOptions, setChildClientOptions] = useState<ChildClient[]>(
        []
    );
    const [initialChildClientOptions, setInitialChildClientOptions] = useState<
        ChildClient[]
    >([]);
    const [isSelectButtonSelected, setIsSelectButtonSelected] =
        useState<boolean>(false);
    const [isDeselectButtonSelected, setIsDeselectButtonSelected] =
        useState<boolean>(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    // Define and return styled components
    const styledComponents = getStyledComponents(menuOpen);

    // Api calls to gather all dropdown data
    const { data: getClientsChildData } = useGetChildClientsQuery(path);

    useEffect(() => {
        // Structuring api data to input needed format/values
        const clientChildDropdownOptions: {
            label: string;
            value: number;
            parentId: number | null;
        }[] =
            getClientsChildData
                ?.map(elem => {
                    return {
                        label: elem.name,
                        value: elem.id,
                        parentId: elem.parentId,
                    };
                })
                .sort((a, b) => a.label.localeCompare(b.label)) || [];

        setChildClientOptions(clientChildDropdownOptions);
        setInitialChildClientOptions(clientChildDropdownOptions);

        // set initial select and deselect button colors on load
        setIsSelectButtonSelected(
            widgetData.config.childClients.length ===
                clientChildDropdownOptions.length
        );

        setIsDeselectButtonSelected(
            widgetData.config.childClients.length === 0
        );
    }, [getClientsChildData, widgetData.config.childClients.length]);
    useEffect(() => {
        if (widgetData.config.childClients.length === 0) {
            // set select and deselect buttons when the clear button is selected
            setIsSelectButtonSelected(false);
            setIsDeselectButtonSelected(true);
        } else if (
            widgetData.config.childClients.length <
            initialChildClientOptions.length
        ) {
            // set select and deselect buttons when individual child clients are selected
            setIsSelectButtonSelected(false);
            setIsDeselectButtonSelected(false);
        } else {
            // set select and deselect buttons when user manually selects all check boxes
            setIsSelectButtonSelected(true);
            setIsDeselectButtonSelected(false);
        }
    }, [
        widgetData.config.childClients.length,
        initialChildClientOptions.length,
    ]);

    const handleChildClientDropdown = (
        selectedChildClients: {
            label: string;
            value: number;
            parentId: number;
        }[]
    ): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                childClients: selectedChildClients,
            },
        });
    };

    // Override components
    const ControlComponent = (props: ControlProps<any, boolean>) => {
        const { children, innerProps } = props;
        return (
            <div {...innerProps}>
                <styledComponents.ControlContainerStyle
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    {children}
                </styledComponents.ControlContainerStyle>
            </div>
        );
    };
    const ValueContainerComponent = (
        valProps: ValueContainerProps<any, boolean>
    ) => {
        const { children, innerProps } = valProps;

        if (children) {
            return (
                <div {...innerProps}>
                    <styledComponents.ValueContainerStyle>
                        {widgetData.config.childClients.length ? (
                            <>
                                {widgetData.config.childClients.length ===
                                childClientOptions.length
                                    ? 'All Child Clients Selected'
                                    : `${widgetData.config.childClients.length} Child Clients Selected`}
                                {children[1]}
                            </>
                        ) : (
                            <>
                                {'0 Child Clients Selected'}
                                {children[1]}
                            </>
                        )}
                    </styledComponents.ValueContainerStyle>
                </div>
            );
        }
    };
    const MenuListComponent = (menuProps: MenuListProps<any, boolean>) => {
        const { innerProps, children } = menuProps;
        return (
            <div {...innerProps}>
                <styledComponents.MenuListContainer>
                    <styledComponents.Button
                        isSelected={isDeselectButtonSelected}
                        onClick={() => {
                            setWidgetData({
                                ...widgetData,
                                config: {
                                    ...widgetData?.config,
                                    childClients: [],
                                },
                            });
                            setIsSelectButtonSelected(false);
                            setIsDeselectButtonSelected(true);
                        }}
                    >{`Deselect All`}</styledComponents.Button>
                    <br />
                    <div style={{ paddingBottom: '10px' }}>
                        <styledComponents.Button
                            isSelected={isSelectButtonSelected}
                            onClick={() => {
                                setWidgetData({
                                    ...widgetData,
                                    config: {
                                        ...widgetData?.config,
                                        childClients: childClientOptions,
                                    },
                                });
                                setIsSelectButtonSelected(true);
                                setIsDeselectButtonSelected(false);
                            }}
                        >{`Select All`}</styledComponents.Button>
                    </div>
                    {children}
                </styledComponents.MenuListContainer>
            </div>
        );
    };
    const OptionComponent = (optionProps: OptionProps<any, boolean>) => {
        const { data, innerProps, selectOption } = optionProps;
        const checkboxLabel =
            data.label.length > 60
                ? data.label.substring(0, 60) + '...'
                : data.label;
        return (
            <div {...innerProps}>
                <styledComponents.ChildClientListContainer>
                    <styledComponents.ChildClientListCheckbox
                        id={'childClientListSelector'}
                        className={'childClientListCheckbox'}
                        name={data.label}
                        label={checkboxLabel}
                        value={data.value}
                        searchable={true}
                        checked={widgetData.config.childClients.find(
                            childClient => childClient.value === data.value
                        )}
                        onChange={() => selectOption(data)}
                    />
                </styledComponents.ChildClientListContainer>
            </div>
        );
    };

    const components: Partial<
        SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
    > = {
        Control: ControlComponent,
        ValueContainer: ValueContainerComponent,
        MenuList: MenuListComponent,
        Option: OptionComponent,
    };

    return {
        childClientOptions,
        handleChildClientDropdown,
        menuOpen,
        setMenuOpen,
        components,
    };
}

const getStyledComponents = (menuOpen: boolean) => {
    return {
        ControlContainerStyle: styled.div`
            align-content: center;
            cursor: default;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 36px;
            outline: 0 !important;
            position: static;
            transition: all 100ms ease 0s;
            background-color: white;
            border-color: ${menuOpen ? '#66AFE9' : '#cccccc'};
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            box-shadow: ${menuOpen &&
            '0 1px 1px inset #00000013, 0 0 8px #66afe999'};
            box-sizing: border-box;
            &:hover {
                border-color: ${menuOpen ? '#2684ff' : '#B3B3B3'};
            }
        `,
        ValueContainerStyle: styled.div`
            padding-left: 15px;
            padding-top: 2px;
        `,
        MenuListContainer: styled.div`
            overflow: scroll;
            max-height: 420px;
        `,
        ChildClientListContainer: styled.div`
            display: grid;
            padding: 0 0 0 10px;
        `,
        ChildClientListCheckbox: styled(Checkbox)`
            label {
                font-size: 12px;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
            }
        `,
        Button: styled.button`
            color: ${props => (props.isSelected ? '#818181' : '#57c0e8')};
            background: none;
            border: none;
            padding: 10px 0 0 12px;
        `,
    };
};
