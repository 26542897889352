import React from 'react';

export interface User {
    id: string;
    firstName: string;
    lastName: string;
}

export enum EXCEPTION_FLAG_BUCKET {
    DAYS_7 = 'DAYS_7',
    DAYS_15 = 'DAYS_15',
    DAYS_30 = 'DAYS_30',
    DAYS_30PLUS = 'DAYS_30PLUS',
}

export enum EXCEPTION_FLAG {
    CLIENT = 'CLIENT',
    CONTRACTOR = 'CONTRACTOR',
    DISTRIBUTOR = 'DISTRIBUTOR',
    FINANCE = 'FINANCE',
    HOMEOWNER = 'HOMEOWNER',
    NOT_ACCEPTED = 'NOT_ACCEPTED',
    NO_COVERAGE = 'NO_COVERAGE',
    QUALITY_APPROVAL_REQUIRED = 'QUALITY_APPROVAL_REQUIRED',
    QUALITY_REJECTED = 'QUALITY_REJECTED',
    RECRUITMENT_REQUIRED = 'RECRUITMENT_REQUIRED',
    REQUIREMENT = 'REQUIREMENT',
    SCHEDULE_HOLD = 'SCHEDULE_HOLD',
}

export interface ExceptionFlag {
    name: string;
    description: string;
}

export interface ExceptionFlagBucket {
    name: string;
    description: string;
}

export interface WorkOrderExceptionCountAPIResult {
    user: {
        id: number;
        firstName: string;
        lastName: string;
        userType: string;
        statusId: string | null;
        active: boolean;
        userName: string;
    };
    counts: { label: string; count: number }[];
}

export interface WorkOrderExceptionCountAPIResponse {
    result: WorkOrderExceptionCountAPIResult[];
}

export enum WorkOrderUserType {
    APPROVAL_OWNER = 'APPROVAL_OWNER',
    OWNER = 'OWNER',
}

export interface ExceptionFlagFilters {
    exceptionFlags?: EXCEPTION_FLAG[] | null;
    exceptionFlagBucket?: EXCEPTION_FLAG_BUCKET | null;
    noOfDocumentsToShow?: number | null;
    page?: number | null;
    workOrderUserType?: WorkOrderUserType | null;
    userIds?: number[] | null;
}

export interface WorkOrderAPI {
    result: WorkOrderResults[];
    _links: {};
}

export interface WorkOrderResults {
    id: string;
    workOrder: {
        id: string;
        client: {
            id: string;
            name: string;
            address: {
                id: number;
                stdAddress: string;
            };
        };
        number: string;
        workOrderTypeId: string;
        statusId: string;
        contractor: { id: string; name: string };
    };
    issueId: string;
    issueAgeDays: string;
}

export interface TableRecords {
    number: React.ReactNode;
    workOrderTypeId: string;
    client: React.ReactNode;
    statusId: string;
    issueId: string;
    contractor: string | React.ReactNode;
    issueAgeDays: string;
}
