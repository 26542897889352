import React, { Dispatch, SetStateAction } from 'react';
import StackIcon from '../../common/icons/StackIcon';
import AddWidgetIcon from '../../common/icons/AddWidgetIcon';
import useAddWidgetLayoutStyles from './AddWidgetLayoutStyles';

interface Props {
    setShowAddModal: Dispatch<SetStateAction<boolean>>;
}

export function AddWidgetLayout(props: Props) {
    const styles = useAddWidgetLayoutStyles();

    return (
        <div className={styles.root}>
            <div>
                <div className={styles.centerContent}>
                    <div className={styles.stackIconBlock}>
                        <StackIcon />
                    </div>
                </div>
                <h3 style={{ marginTop: '24px' }}>
                    You can start adding your widgets
                </h3>
                <div
                    style={{
                        color: '#5B657B',
                        paddingTop: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    There are currently widgets available for you, please check
                </div>
                <div
                    className={styles.addWidgetBlock}
                    onClick={() => props.setShowAddModal(true)}
                >
                    <div className={styles.addWidgetContainer}>
                        <AddWidgetIcon />
                        <span className={styles.addWidgetWrapper}>
                            Add Widgets
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
