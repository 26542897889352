import * as React from 'react';
import { SVGProps } from 'react';

const Calender = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 121 \u2013 23'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <g data-name='Group 4692'>
            <path
                data-name='Path 2445'
                d='M5.031 3.213c0-.21-.005-.4 0-.6a.606.606 0 0 1 .808-.58.6.6 0 0 1 .4.563v.6h3.642v-.557a.608.608 0 1 1 1.214-.006v.572h.177c.4 0 .807-.017 1.208.017a1.785 1.785 0 0 1 1.644 1.79c.008.967 0 1.933 0 2.9v4.378a1.8 1.8 0 0 1-1.529 1.812 2.12 2.12 0 0 1-.354.022h-8.36a1.809 1.809 0 0 1-1.888-1.888V5.1a1.817 1.817 0 0 1 1.9-1.9ZM3.21 8.075v4.138a.626.626 0 0 0 .706.71h8.3a.623.623 0 0 0 .7-.7v-4.15Zm9.7-1.227a.279.279 0 0 0 .014-.054c0-.587.007-1.175 0-1.762a.6.6 0 0 0-.623-.6H11.106c0 .216.006.41 0 .6a.606.606 0 0 1-1.212.006v-.6H6.252v.6a.607.607 0 0 1-1.213-.008c-.007-.2 0-.394 0-.6H3.9a.615.615 0 0 0-.682.675v1.606c0 .045 0 .09.007.138Z'
                fill='#aaafba'
            />
        </g>
    </svg>
);

export default Calender;
