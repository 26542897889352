import * as React from 'react';
import { SVGProps } from 'react';

const CustomizeIcon = (props: SVGProps<SVGSVGElement>) => {
    const color = props.color || 'fff';
    const width = props.width || 16;
    const height = props.height || 16;
    return (
        <svg
            id='Component_228_1'
            data-name='Component 228 – 1'
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 16 16'
        >
            <g id='Component_155_4' data-name='Component 155 – 4'>
                <rect
                    id='Rectangle_5790'
                    data-name='Rectangle 5790'
                    width='16'
                    height='16'
                    fill={color}
                    opacity='0'
                />
                <g id='f233XJ.tif' transform='translate(-706.073 -315.939)'>
                    <g
                        id='Group_5378'
                        data-name='Group 5378'
                        transform='translate(707.561 317.219)'
                    >
                        <path
                            id='Path_2585'
                            data-name='Path 2585'
                            d='M718.056,329.05,717,328.839c-.1-.019-.194-.04-.292-.057a.654.654,0,0,0-.756.438c-.137.389-.263.782-.4,1.173a.664.664,0,0,1-.7.507q-1.309,0-2.619,0a.663.663,0,0,1-.693-.5c-.126-.371-.246-.743-.372-1.114a.671.671,0,0,0-.85-.493c-.385.075-.768.156-1.152.233a.669.669,0,0,1-.782-.347q-.653-1.125-1.3-2.251a.667.667,0,0,1,.086-.851c.251-.287.5-.572.757-.859a.681.681,0,0,0,0-1.019c-.249-.282-.5-.564-.746-.847a.67.67,0,0,1-.088-.863q.645-1.12,1.294-2.238a.664.664,0,0,1,.766-.348c.4.079.809.161,1.215.235a.659.659,0,0,0,.786-.482c.116-.368.226-.739.34-1.108a.663.663,0,0,1,.7-.525h2.6a.673.673,0,0,1,.721.526c.124.371.247.743.373,1.114a.66.66,0,0,0,.806.471c.4-.074.789-.157,1.183-.237a.668.668,0,0,1,.783.344q.654,1.124,1.3,2.251a.664.664,0,0,1-.085.838c-.262.3-.528.593-.791.891a.673.673,0,0,0,.015.993c.267.287.537.571.8.858a.661.661,0,0,1,.1.86q-.648,1.127-1.3,2.252A.7.7,0,0,1,718.056,329.05Zm-9.6-6.785c.19.214.372.415.549.621a2,2,0,0,1,0,2.64c-.157.182-.316.363-.479.539a.122.122,0,0,0-.017.174q.383.638.754,1.283a.144.144,0,0,0,.188.079c.224-.051.45-.094.675-.138a2,2,0,0,1,2.288,1.316c.075.217.146.436.208.657a.149.149,0,0,0,.172.133q.729-.008,1.458,0a.146.146,0,0,0,.167-.119c.071-.229.148-.457.23-.683a2,2,0,0,1,2.23-1.3q.37.064.737.148a.133.133,0,0,0,.173-.079q.363-.641.736-1.275a.143.143,0,0,0-.018-.2c-.155-.163-.3-.333-.449-.5a2,2,0,0,1,0-2.677c.151-.173.309-.341.455-.519a.173.173,0,0,0,.022-.155q-.371-.663-.759-1.317a.173.173,0,0,0-.143-.065c-.232.037-.462.089-.692.133a2,2,0,0,1-2.3-1.317q-.116-.333-.222-.669a.136.136,0,0,0-.152-.117q-.745.008-1.49,0c-.093,0-.126.038-.151.119-.068.219-.143.437-.219.653a2,2,0,0,1-2.283,1.328c-.236-.043-.471-.1-.708-.136a.174.174,0,0,0-.146.056C709,321.332,708.73,321.794,708.456,322.265Z'
                            transform='translate(-706.973 -317.523)'
                            fill={color}
                        />
                        <path
                            id='Path_2586'
                            data-name='Path 2586'
                            d='M771.286,386.782a2.674,2.674,0,1,1,2.671-2.676A2.673,2.673,0,0,1,771.286,386.782Zm-1.342-2.681a1.337,1.337,0,1,0,1.339-1.332A1.338,1.338,0,0,0,769.944,384.1Z'
                            transform='translate(-764.732 -377.419)'
                            fill={color}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CustomizeIcon;
