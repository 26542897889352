import { createUseStyles } from 'react-jss';

const useMenuButtonStyles = createUseStyles({
    dividerContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    menuLayout: {
        height: '40px',
        width: '38px',
        position: 'relative',
        border: '1px solid #DFE4ED',
        borderRadius: '4px',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    menuOverflowIconStyle: {
        padding: '6px',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: '5px',
        width: '38px',
        height: '40px',
    },
    menuPaperStyle: {
        backgroundColor: '#0e2248',
        overflow: 'visible',
        borderRadius: '6px',
        maxWidth: '300px',
        position: 'relative',
        marginTop: '12px',
        boxShadow: '0px 5px 31px #0000004F',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: '12px',
            width: '12px',
            height: '12px',
            backgroundColor: '#0e2248',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: '3',
        },
    },
    menuItemStyle: {
        display: 'flex',
        alignItems: 'center',
        zIndex: '4',
        width: '240px',
        margin: '1px',
        height: '50px',
        '&:hover': {
            backgroundColor: '#314976',
            borderRadius: '4px',
        },
    },
    menuItemIconStyle: {
        paddingRight: '10px',
        paddingLeft: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    menuItemTextStyle: {
        color: 'white',
    },
    expandedTableMenuButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    infoTipStyle: {
        marginRight: '3px',
        marginBottom: '3px'
    },
    tooltipBackground: {
        backgroundColor: 'red',
    },
});

export default useMenuButtonStyles;
