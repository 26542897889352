import { createUseStyles } from 'react-jss';

const useLibraryStyles = createUseStyles({
    docDisplayCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
        height: '130px',
        border: '1px solid #dfe4ed',
        margin: '15px',
        padding: '10px'
    },
    idAndStatusRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    statusColorDisplay: {
        height: '16px',
        borderRadius: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        fontSize: '12px'
    },
    detailsRow: {
        display: "flex",
        alignContent: 'end'
    },
    staticText: {
        fontSize: '12px',
        color: '#5b657b'
    },
    clientNameText: {
        fontSize: '14px',
        color: '#000000'
    },
    fontSize14: {
        fontSize: '14px'
    }
});

export default useLibraryStyles;
