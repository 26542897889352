import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { getLoggedInUserDetails } from '../../../utils/utils';

export const axiosBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: headers => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getLoggedInUserDetails();
        if (token && token.id) {
            headers.set('authorization', token.id);
        }
        return headers;
    },
});

export const dataDashboardBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DATA_DASHBOARD_BASE_URL,
    prepareHeaders: headers => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getLoggedInUserDetails();
        if (token && token.id) {
            headers.set('authorization', token.id);
        }
        return headers;
    },
});
