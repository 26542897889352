import {
    combineReducers,
    configureStore,
    ConfigureStoreOptions,
} from '@reduxjs/toolkit';
import { getCommentsApi } from './api/FeedsApi';
import documentsSlice from './api/DocumentListSlice';
import appConfiguration from './api/AppConfigSlice';
import showAllComments from './api/ShowAllComments';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import nonPersistentConfiguration from './api/CommentsNonPersistentConfigSlice';
const persistConfig: PersistConfig<any> = {
    key: 'motili-dashboard-comments',
    storage,
    blacklist: [
        'comments',
        'documentsList',
        'showAllComments',
        'nonPersistentConfigurations',
    ],
};

const combinedReducers = combineReducers({
    [getCommentsApi.reducerPath]: getCommentsApi.reducer,
    documentsList: documentsSlice,
    appConfigurations: appConfiguration,
    showAllComments: showAllComments,
    nonPersistentConfigurations: nonPersistentConfiguration,
});
const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const createCommentsMFStore = (
    options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
    configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat([getCommentsApi.middleware]),
        ...options,
    });

export const commentsMFStore = createCommentsMFStore();
export const commentsMFPersistor = persistStore(commentsMFStore);

export type AppDispatch = typeof commentsMFStore.dispatch;
export type RootState = ReturnType<typeof commentsMFStore.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
