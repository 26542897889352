/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */
// @ts-nocheck
import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import autoSuggestStyles from './AutoSuggestStyle.js';
const autoStuggest = ({
    value,
    placeholder,
    onChange,
    mentions = [],
}: FormMentionsTextInputProps) => {
    return (
        <MentionsInput
            style={autoSuggestStyles}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
        >
            <Mention
                style={autoSuggestStyles.mention}
                displayTransform={(id, display) => `@${display}`}
                trigger={'@'}
                data={mentions}
            />
        </MentionsInput>
    );
};
export default autoStuggest;

interface FormMentionsTextInputProps {
    value: string;
    placeholder: string;
    onChange: (e: {
        target: {
            value: string;
        };
    }) => void;
    mentions: Mention[];
}
