import React from 'react';
import { Widget } from '../../models/WidgetModels';
import CustomizeIcon from '../../../common/icons/CustomizeIcon';
import { TextInput } from 'motili-ui';
import useDrawerHeadingAndTitleStyles from './DrawerHeadingAndTitleStyles';

interface DrawerHeadingAndTitleProps {
    widgetData: Widget;
    setWidgetData: React.Dispatch<React.SetStateAction<Widget>>;
    dataTestId: string;
}

export function DrawerHeadingAndTitle(
    props: Readonly<DrawerHeadingAndTitleProps>
): JSX.Element {
    const styles = useDrawerHeadingAndTitleStyles();
    const { widgetData, setWidgetData, dataTestId } = props;

    const handleInput = (type: string, value: string): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: value,
            },
        });
    };

    return (
        <>
            <div className={styles.headingSection}>
                <div className={styles.customizeSection}>
                    <div className={styles.customizeIconWrapper}>
                        <CustomizeIcon color={'black'} width={24} height={24} />
                    </div>
                    <div>
                        <h3 className={styles.headingTypography}>
                            Customize Widget
                        </h3>
                    </div>
                </div>
                <div>
                    <h6 className={styles.requiredTextColor}>
                        <i>*Required Fields</i>
                    </h6>
                </div>
            </div>
            <div className={styles.formSection}>
                <TextInput
                    data-test-id={dataTestId}
                    label={'Widget Title'}
                    name={'widgetTitleInput'}
                    inputStyles={{ width: '100%' }}
                    value={widgetData?.config?.name}
                    maxLength={23}
                    onChange={(e: React.FormEvent<HTMLElement>) => {
                        const target = e.target as HTMLInputElement
                        handleInput('name', target.value);
                    }}
                    required
                />
            </div>
        </>
    );
}
