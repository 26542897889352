import React, { useState } from 'react';
import {
    useCreateUserPreferenceMutation,
    useGetUserPreferenceQuery,
} from 'widgets/store/api/UserPreferenceApi';
import { useGetWidgetsQuery } from 'widgets/store/api/DashboardWidgetsApi';
import { WidgetsLayout } from './WidgetsLayout';
import {
    filterShowableWidgets,
    filterShowableWidgetsBasedOnUserPreference,
} from '../../utils/WidgetUtils';
import { AddWidgetModal } from './AddWidgetModal';
import AddWidgetIcon from '../../common/icons/AddWidgetIcon';
import { AddWidgetLayout } from './AddWidgetLayout';
import { InternalLoading } from '../../common/InternalLoading';
import { getFullName } from '../../utils/utils';
import useWidgetContainerStyles from './WidgetsContainerStyles';

function WidgetsContainer() {
    const styles = useWidgetContainerStyles();

    const [showAddModal, setShowAddModal] = useState(false);

    const userName = getFullName();

    const [
        triggerCreateUserPreferenceMutation,
        { isLoading: isAddWidgetLoading },
    ] = useCreateUserPreferenceMutation();

    const { data: widgetsListData } = useGetWidgetsQuery(null);
    const { data: userPreferenceData, isLoading: isUserPreferenceLoading } =
        useGetUserPreferenceQuery(null);

    const isUserPreferenceEmpty =
        (!userPreferenceData ||
            userPreferenceData.length === 0 ||
            filterShowableWidgetsBasedOnUserPreference(userPreferenceData!)
                .length === 0) &&
        !isUserPreferenceLoading;

    /**
     * When a new widget is added, order should be one greater than the largest one present in existing preference array
     * @param widgets
     */
    const onAddWidgets = widgets => {
        let currentMaxOrder = 0;
        userPreferenceData?.forEach(pref => {
            if (pref.config.order > currentMaxOrder) {
                currentMaxOrder = pref.config.order;
            }
        });
        const widgetsToMutate = widgets.map(widget => {
            ++currentMaxOrder;
            return {
                widgetid: widget.id,
                widgetkey: widget.key,
                config: { ...widget.config, order: currentMaxOrder },
            };
        });
        triggerCreateUserPreferenceMutation(widgetsToMutate);
    };

    return (
        <div className={styles.App}>
            {widgetsListData && showAddModal && (
                <div>
                    <AddWidgetModal
                        widgets={filterShowableWidgets(widgetsListData)}
                        onClose={() => {
                            setShowAddModal(false);
                        }}
                        handleWidgetAddition={widgets => {
                            onAddWidgets(widgets);
                        }}
                    />
                </div>
            )}
            <div>
                <div>
                    <div style={{ color: '#5B657B' }}>Welcome {userName},</div>
                </div>
                <div className={styles.addWidgetSection}>
                    <div style={{ fontSize: '24px' }}>Dashboard Overview</div>
                    <div
                        className={styles.addWidgetLayout}
                        onClick={() => {
                            setShowAddModal(true);
                        }}
                    >
                        <AddWidgetIcon />
                        <span className={styles.addWidgetWrapper}>
                            Add Widgets
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <InternalLoading open={isAddWidgetLoading} />
            </div>
            {isUserPreferenceEmpty ? (
                <AddWidgetLayout setShowAddModal={setShowAddModal} />
            ) : (
                <WidgetsLayout />
            )}
        </div>
    );
}

export default WidgetsContainer;
