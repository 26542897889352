import _ from 'lodash';
import { CommentDetails } from 'comments/models/CommentsModel';
import { getAsyncAwaitHttpObj } from 'common/store/api/HttpObjectConfig';

export const getLoggedInUserDetails = () => {
    return JSON.parse(localStorage.getItem('motili.token') || '{}');
};

export const isLoggedIn = () => {
    return !!getLoggedInUserDetails()?.id;
};

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export enum UserTypes {
    Motili = 'motili',
    Client = 'client',
    Contractor = 'contractor',
}

export enum DocumentTypes {
    WorkOrder = 'WORK_ORDER',
    Order = 'ORDER',
    Draft = 'DRAFT',
    Quote = 'QUOTE',
}
export const getDocumentTypeToDisplay = (modelType: string) => {
    switch (modelType) {
        case 'WORK_ORDER':
            return 'Work Order';
        case 'ORDER':
            return 'Order';
        case 'DRAFT':
            return 'Draft';
        case 'QUOTE':
            return 'Quote';
    }
};

export const getDocumentTypeToDisplayInWidgets = (modelType: string) => {
    if (modelType?.toLowerCase() === 'work order') {
        return 'WO';
    }
    return modelType;
};

export const getDocumentTypeToDisplayInTooltip = (modelType: string) => {
    switch (modelType) {
        case 'WORK_ORDER':
            return 'WO';
        case 'ORDER':
            return 'Order';
        case 'DRAFT':
            return 'Draft';
        case 'QUOTE':
            return 'Quote';
    }
};

export function getColorBasedOnDepartment(department) {
    switch (department) {
        case 'Account Manager':
            return {
                color: '#654C0E',
                bgColor: '#F8F4EA',
            };
        case 'Bid Desk':
            return {
                color: '#6BA072',
                bgColor: '#EAFAEC',
            };
        case 'Contractor Relations':
            return {
                color: '#BC7676',
                bgColor: '#FCF4F4',
            };
        case 'Engineering':
            return {
                color: '#58B4B4',
                bgColor: '#EAFAFA',
            };
        case 'Finance':
            return {
                color: '#6BA072',
                bgColor: '#EAFAEC',
            };
        case 'Order Management':
            return {
                color: '#BF83C6',
                bgColor: '#FCEFFD',
            };
        case 'Operations':
            return {
                color: '#3F82A5',
                bgColor: '#3F82A510',
            };
        case 'Sales':
            return {
                color: '#F3A638',
                bgColor: '#F3A63810',
            };
        case 'Project Management':
            return {
                color: '#90A265',
                bgColor: '#F4F8EA',
            };
        default:
            return {
                color: '#BC7676',
                bgColor: '#FCF4F4',
            };
    }
}

export const getDraftDocNumbers = (comments: CommentDetails[]) => {
    if (comments && comments.length > 0) {
        let docs = comments.filter(
            comment => comment.modelType === DocumentTypes.Draft
        );
        return docs.map(comment => comment.documentNumber + '');
    }
};

export const getDocumentNumbers = (comments: CommentDetails[]) => {
    if (comments && comments.length > 0) {
        let docs = comments.filter(
            comment =>
                comment.modelType === DocumentTypes.WorkOrder ||
                comment.modelType === DocumentTypes.Quote ||
                comment.modelType === DocumentTypes.Order
        );
        return docs.map(comment => comment.documentNumber);
    }
};

export const getPathBasedOnUserType = () => {
    switch (getUserType()) {
        case UserTypes.Client:
            return 'cli';
        case UserTypes.Contractor:
            return 'con';
        default:
            return 'ops';
    }
};

export const getEndPointForDocumentType = (documentTypeId: string) => {
    let endpoint = '';
    switch (documentTypeId) {
        case DocumentTypes.WorkOrder:
            endpoint = 'work-orders';
            break;
        case DocumentTypes.Quote:
            endpoint = 'quotes';
            break;
        case DocumentTypes.Order:
            endpoint = 'orders';
            break;
        case DocumentTypes.Draft:
            endpoint = 'drafts';
            break;
        default:
            endpoint = 'UNKNOWN';
            break;
    }
    return endpoint;
};

export const getDraftsPayload = (documentIds: string[]) => {
    return {
        filter: {
            include: [
                {
                    relation: 'property',
                    scope: {
                        fields: ['stdAddress', 'stdDeliveryLine', 'timezone'],
                    },
                },
                { relation: 'client', scope: { fields: ['name'] } },
                {
                    relation: 'jobManager',
                    scope: { fields: ['firstName', 'lastName'] },
                },
            ],
            fields: [
                'id',
                'number',
                'documentTypeId',
                'documentSubTypeId',
                'tradeTypeId',
                'priorityId',
                'comments',
                'statusId',
                'extReferenceNumber1',
                'extReferenceNumber2',
                'propertyId',
                'clientId',
                'contractorId',
                'contractorTeamMemberId',
                'userId',
                'documentOrigination',
                'created',
                'updated',
                'opsJobManager',
                'tradeSubTypeId',
                'fulfillmentDetails',
                'sla',
                'jobId',
                'intPoNumber',
                'extPoNumber',
                'schedule',
                'issueId',
            ],
            where: {
                and: [
                    {
                        documentTypeId: {
                            inq: ['DRAFT'],
                        },
                    },
                    { number: { inq: documentIds } },
                ],
            },
        },
    };
};

export const getDocumentsPayload = (documentIds: string[]) => {
    return {
        filter: {
            fields: [
                'id',
                'number',
                'documentTypeId',
                'documentSubTypeId',
                'tradeTypeId',
                'priorityId',
                'comments',
                'statusId',
                'extReferenceNumber1',
                'extReferenceNumber2',
                'propertyId',
                'clientId',
                'coOwner',
                'contractorId',
                'contractorTeamMemberId',
                'userId',
                'documentOrigination',
                'created',
                'updated',
                'opsJobManager',
                'tradeSubTypeId',
                'fulfillmentDetails',
                'sla',
                'jobId',
                'intPoNumber',
                'extPoNumber',
                'schedule',
                'issueId',
                'user',
            ],
            include: [
                {
                    relation: 'property',
                    scope: {
                        fields: ['stdAddress', 'stdDeliveryLine', 'timezone'],
                    },
                },
                { relation: 'client', scope: { fields: ['name'] } },
                {
                    relation: 'user',
                    scope: {
                        fields: ['firstName', 'lastName'],
                    },
                },
                {
                    relation: 'jobManager',
                    scope: { fields: ['firstName', 'lastName'] },
                },
            ],
            where: {
                and: [
                    {
                        documentTypeId: {
                            inq: ['ORDER', 'WORK_ORDER', 'QUOTE'],
                        },
                    },
                    { number: { inq: documentIds } },
                ],
            },
        },
    };
};

export function createCommentForWorkOrder(id, docPayload, mentionsData) {
    const userType = getUserType();
    let objToSend = {};
    if (userType === UserTypes.Motili) {
        objToSend = {
            message: docPayload,
            messageType: UserTypes.Motili.toUpperCase(),
            mentionsData,
        };
    } else if (userType === UserTypes.Contractor) {
        objToSend = {
            message: docPayload,
            messageType: UserTypes.Contractor.toUpperCase(),
        };
    }
    const _comment = Object.assign({}, objToSend);
    return getAsyncAwaitHttpObj().post(`workOrders/${id}/comments`, _comment);
}

export function createCommentForQuote(id, comment, mentionsData, docPayload) {
    const userType = getUserType();
    let objToSend = {};
    if (userType === UserTypes.Motili) {
        objToSend = {
            message: comment.note,
            messageType: UserTypes.Motili.toUpperCase(),
            mentionsData,
        };
        const _comment = Object.assign({}, objToSend);
        return getAsyncAwaitHttpObj().post(`quotes/${id}/comments`, _comment);
    } else {
        objToSend = {
            message: docPayload,
            messageType: UserTypes.Contractor.toUpperCase(),
        };
        const _comment = Object.assign({}, objToSend);
        return getAsyncAwaitHttpObj().post(`quotes/${id}/comments`, _comment);
    }
}

export function createCommentForOrder(id, comment, mentionsData, docPayload) {
    const userType = getUserType();
    let objToSend = {};
    if (userType === UserTypes.Motili) {
        objToSend = {
            message: comment,
            messageType: UserTypes.Motili.toUpperCase(),
            mentionsData,
        };
    } else if (userType === UserTypes.Contractor) {
        objToSend = {
            message: docPayload,
            messageType: UserTypes.Contractor.toUpperCase(),
        };
    } else if (userType === UserTypes.Client) {
        objToSend = {
            message: docPayload,
            messageType: UserTypes.Client.toUpperCase(),
        };
    }
    const _comment = Object.assign({}, objToSend);
    return getAsyncAwaitHttpObj().post(`orders/${id}/comments`, _comment);
}

export async function createCommentForDrafts(id, text, mentionsData) {
    const data = Object.assign(
        {},
        {
            message: text,
            mentionsData,
            messageType: 'MOTILI',
        }
    );
    return getAsyncAwaitHttpObj().post(`Drafts/${id}/comments`, data);
}

export const getUserType = () => {
    return _.get(getLoggedInUserDetails(), 'user.userType', '');
};

export const getFullName = () => {
    return _.get(getLoggedInUserDetails(), 'user.fullName', '');
};

export const getUserEmail = () => {
    return _.get(getLoggedInUserDetails(), 'user.email', '');
};

export const getUserId = () => {
    return _.get(getLoggedInUserDetails(), 'userId', '');
};

export const isOrderDoc = documentTypeId => {
    return documentTypeId === DocumentTypes.Order;
};
