import * as React from 'react';
import { SVGProps } from 'react';

const AddReply = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 338 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={32}
        height={32}
        {...props}
    >
        <g
            data-name='Group 4769'
            fill='none'
            stroke='#17c2ed'
            strokeLinecap='round'
            strokeWidth={2}
        >
            <path
                data-name='Path 2420'
                d='m4.133 10.507 22.044-2.015L13.605 25.86l.072-10.143Z'
                strokeLinejoin='round'
                strokeWidth={2.00104}
            />
            <path
                data-name='Path 2421'
                d='M14.352 15.318 26.18 8.492'
                strokeWidth={2.00104}
            />
        </g>
    </svg>
);

export default AddReply;
