import { createUseStyles } from 'react-jss';

const useLibraryStyles = createUseStyles({
    root: {
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        padding: '24px',
        minHeight: '300px',
        height: '100%',
        width: '455px',
    },
    rootExpanded: {
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        padding: '24px',
        minHeight: '525px',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rootFullScreen: {
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        padding: '24px',
        width: '100%',
    },
    draggableAreaStyle: {
        display: 'flex',
        alignContent: 'start',
        cursor: 'grab',
    },
    dragOverlayAreaStyle: {
        display: 'flex',
        alignContent: 'start',
        cursor: 'grabbing',
    },
    widgetStatusFont: {
        fontWeight: '600',
        fontSize: '24px',
    },
    widgetHeader: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    widgetTitle: {
        fontSize: '28px',
        fontWeight: '600',
        lineHeight: '1em',
        paddingLeft: '5px',
    },
    toggleButtonMenuButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    popperStyle: {
        position: 'absolute',
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        paddingTop: '10px',
        width: '275px',
        zIndex: '3',
    },
    configureViewLayout: {
        height: '450px',
        width: '390px',
        margin: '10px',
        backgroundColor: '#F6F9FC',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    noContentLayout: {
        height: '450px',
        width: '390px',
        margin: '10px',
        backgroundColor: '#F6F9FC',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noContentLayoutFullScreen: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '250px',
        backgroundColor: '#F6F9FC',
        marginTop: '10px',
        borderRadius: '4px',
    },
    alertIconStyle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    alertIconWrapper: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: '#F3A638',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    chartAndTooltipContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingTop: '10%',
    },
    chartContainer: {
        display: 'block',
        boxSizing: 'border-box',
        height: '100%',
        width: '387px',
    },
    chartOpenContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '15px',
        width: '100%',
    },
    documentCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: '10px',
    },
    dragOverlayContainer: {
        width: '455px',
        height: '558px',
        padding: '24px',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
    },
    dragWidgetContainer: {
        height: '450px',
        width: '400px',
        marginLeft: '2px',
        marginTop: '25px',
        backgroundColor: '#F6F9FC',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dragWidgetMenuButton: {
        height: '40px',
        width: '38px',
        position: 'relative',
        border: '1px solid #DFE4ED',
        borderRadius: '4px',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
});

export default useLibraryStyles;
