import React, { useState, useEffect, useMemo } from 'react';
import {
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Chart,
    Legend,
    Tooltip as ChartToolTip,
} from 'chart.js';
import { CustomizeWidgetDrawer } from './ContractorDocumentExpirationWidgetDrawer';
import { Widget, ChartData } from '../../models/WidgetModels';
import { WidgetProps } from '../../render/RenderWidget';
import AlertIcon from 'common/icons/AlertIcon';
import DragIcon from 'common/icons/DragIcon';
import { useGetBucketCountQuery } from '../../store/api/ContractorExpiredDocumentsApi';
import { ContractorDocumentExpirationTable } from './ContractorDocumentExpirationTable';
import { MenuButton } from '../../components/menuButton/MenuButton';
import useLibraryStyles from '../../components/WidgetStyleLibrary';
import { ToolTipQuestionMarkIcon } from '../../components/toolTipQuestionMark/ToolTipQuestionMark';
import { DataTableHeader } from '../../components/tableHeader/DataTableHeader';
import { ContractorDocumentExpirationDisplayChartType } from './ContractorDocumentExpirationDisplayChartType';
import {
    DoughnutOptions,
    BarOptions,
    CredentialsFilter,
    ContractorCompanyFilter,
} from '../../models/ContractorDocumentExpirationModel';

Chart.register(
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
    ChartToolTip
);

/**
 * @param {WidgetProps} props - The props for the component. Should not be modified within the component.
 */
export function ContractorDocumentExpirationWidget(
    props: Readonly<WidgetProps>
): JSX.Element {
    const styles = useLibraryStyles();
    const {
        widgetItem: data,
        dragging,
        onUpdateWidget,
        listeners,
        attributes,
    } = props;

    const [activeTablePage, setActiveTablePage] = useState<number>(1);

    const convertDisplayTitle = (value: string) => {
        const convertedLabels: Record<string, string> = {
            DAYS_7: '7 Days',
            DAYS_30: '30 Days',
            EXPIRED: 'Expired',
        };

        return convertedLabels[value];
    };
    const getLabelColorMap = ():
        | { '7 Days': { backgroundColor: string } }
        | { '30 Days': { backgroundColor: string } }
        | {
              Expired: {
                  backgroundColor: string;
                  hoverBackgroundColor: string;
              };
          } => {
        return {
            '7 Days': {
                backgroundColor: '#E1C760',
            },
            '30 Days': {
                backgroundColor: '#54BF6D',
            },
            Expired: {
                backgroundColor: '#DB5151',
                hoverBackgroundColor: '#E43737',
            },
        };
    };

    // Build filters
    const contractorStatusFilter: string[] | null =
        data.config.contractorStatuses.length > 0
            ? data.config.contractorStatuses.map(status => {
                  return status.value;
              })
            : null;
    const getFilters = ():
        | CredentialsFilter[]
        | ContractorCompanyFilter[]
        | {} => {
        switch (data.config.documentType) {
            case 'CONTRACTOR_TEAM_CREDENTIALS':
                return {
                    urlFragment: 'contractor-team-member/expiring-credentials',
                    noOfDocumentsToShow: data.config.noOfDocumentsToShow,
                    credentialType: data.config.credentialType.value,
                    contractorStatuses: contractorStatusFilter,
                    page: activeTablePage - 1,
                    ...(data.config.states.length && {
                        states: data.config.states.map((record: any) => {
                            return record.value;
                        }),
                    }),
                };

            case 'CONTRACTOR_COMPANY':
                return {
                    urlFragment: 'contractor/expiring-documents',
                    documentType:
                        data.config.contractorCompanyDocumentType.value,
                    noOfDocumentsToShow: data.config.noOfDocumentsToShow,
                    contractorStatuses: contractorStatusFilter,
                    page: activeTablePage - 1,
                    ...(data.config.states.length && {
                        states: data.config.states.map((record: any) => {
                            return record.value;
                        }),
                    }),
                };

            default:
                return {};
        }
    };
    const filters: {} | CredentialsFilter[] | ContractorCompanyFilter[] =
        getFilters();

    // Count data api
    const { data: GetCountData } = useGetBucketCountQuery(
        { ...filters },
        { refetchOnMountOrArgChange: true }
    );
    const mappedBucketCountData = useMemo(() => {
        const compatibleBuckets: string[] = ['DAYS_7', 'DAYS_30', 'EXPIRED'];
        if (GetCountData) {
            return GetCountData.filter(record => {
                return compatibleBuckets.includes(record.bucket);
            }).map(record => {
                return {
                    label: convertDisplayTitle(record.bucket),
                    value: record.bucket,
                    count: record.count,
                };
            });
        }
        return [];
    }, [GetCountData]);

    const [noDataResults, setNoDataResults] = useState(false);
    const [configRequired, setConfigRequired] = useState(
        !data.config.initialConfig
    );
    const [displayTable, setDisplayTable] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [chartSelectedValue, setChartSelectedValue] = useState<string>('');
    const [chartData, setChartData] = useState<ChartData>({
        labels: [],
        values: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderColor: [],
                hoverBackgroundColor: [],
                borderRadius: 5,
                borderWidth: 2,
                hoverOffset: 2,
                minBarLength: 8,
            },
        ],
    });

    useEffect((): void => {
        if (!mappedBucketCountData?.length) {
            setNoDataResults(true);
            return;
        } else {
            setNoDataResults(false);
        }

        const labelColorMap = getLabelColorMap();
        const chartData: number[] | null =
            mappedBucketCountData?.length > 0
                ? mappedBucketCountData.map(record => record.count)
                : null;

        let inPercent;
        if (chartData) {
            const total = chartData.reduce((a, v) => a + v);
            inPercent = chartData.map(v => Math.max((v / total) * 100, 10));
        }

        if (chartSelectedValue) {
            const backgroundColors: string =
                labelColorMap[convertDisplayTitle(chartSelectedValue)]
                    ?.backgroundColor || '#f5f5f5';

            setChartData({
                labels:
                    mappedBucketCountData?.map(record => record.label) || [],
                values:
                    mappedBucketCountData?.map(record => record.value) || [],
                datasets: [
                    {
                        data:
                            data.config.chartType === 'Bar Graph'
                                ? mappedBucketCountData?.map(
                                      record => record.count
                                  )
                                : inPercent,
                        backgroundColor:
                            mappedBucketCountData?.map(record => {
                                return record.value === chartSelectedValue
                                    ? backgroundColors
                                    : '#f5f5f5';
                            }) || [],
                        borderColor: ['#ffffff'],
                        hoverBackgroundColor:
                            mappedBucketCountData?.map(
                                record =>
                                    labelColorMap[record.label]
                                        ?.hoverBackgroundColor ||
                                    labelColorMap[record.label]?.backgroundColor
                            ) || [],
                        borderRadius: 5,
                        borderWidth: 3,
                        hoverOffset: 2,
                        minBarLength: 8,
                    },
                ],
            });
        } else {
            setChartData({
                labels:
                    mappedBucketCountData?.map(record => record.label) || [],
                values:
                    mappedBucketCountData?.map(record => record.value) || [],
                datasets: [
                    {
                        data:
                            data.config.chartType === 'Bar Graph'
                                ? mappedBucketCountData?.map(
                                      record => record.count
                                  )
                                : inPercent,
                        backgroundColor:
                            mappedBucketCountData?.map(
                                record =>
                                    labelColorMap[record.label]
                                        ?.backgroundColor || '#FFFFFF'
                            ) || [],
                        borderColor: ['#ffffff'],
                        hoverBackgroundColor:
                            mappedBucketCountData?.map(
                                record =>
                                    labelColorMap[record.label]
                                        ?.hoverBackgroundColor ||
                                    labelColorMap[record.label]?.backgroundColor
                            ) || [],
                        borderRadius: 5,
                        borderWidth: 2,
                        hoverOffset: 2,
                        minBarLength: 8,
                    },
                ],
            });
        }
    }, [
        GetCountData,
        chartSelectedValue,
        data.config.chartType,
        mappedBucketCountData,
        dragging,
    ]);
    useEffect((): void => {
        if (noDataResults) {
            setDisplayTable(false);
        }
    }, [noDataResults]);
    useEffect((): void => {
        if (attributes['aria-pressed']) {
            setChartSelectedValue('');
            setDisplayTable(false);
        }
    }, [attributes]);

    const doughnutOptions: DoughnutOptions = {
        ...(dragging && { animation: false }),
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 15,
                    font: {
                        weight: 'bold',
                    },
                    generateLabels: function (chart) {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            return data.labels.map(function (label, i) {
                                const meta = chart.getDatasetMeta(0);
                                const style = meta.controller.getStyle(i);
                                const tableColor =
                                    chartSelectedValue &&
                                    chartSelectedValue === label
                                        ? 'black'
                                        : '#818181';
                                const chartData =
                                    mappedBucketCountData?.map(
                                        record => record.count
                                    ) || [];

                                return {
                                    fontColor: !chartSelectedValue
                                        ? 'black'
                                        : tableColor,
                                    text: `${label} (${chartData[i]})`,
                                    fillStyle: style.backgroundColor,
                                    datasetIndex: i,
                                };
                            });
                        }
                        return [];
                    },
                },
            },
            tooltip: {
                bodyAlign: 'center',
                callbacks: {
                    label: function (tooltipItem) {
                        const chartData =
                            mappedBucketCountData?.map(
                                record => record.count
                            ) || [];
                        const value = chartData[tooltipItem.dataIndex];
                        return `${value}`;
                    },
                },
            },
        },
        spacing: 3,
        cutout: '60%',
    };
    const barOptions: BarOptions = {
        ...(dragging && { animation: false }),
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'start',
                offset: -20,
                font: {
                    size: 14,
                    weight: 'bold',
                },
                color: function (context) {
                    const index = context.dataIndex;
                    const arrayIndex = mappedBucketCountData?.findIndex(
                        record => record.value === chartSelectedValue
                    );

                    if (chartSelectedValue && index !== arrayIndex) {
                        return '#818181';
                    } else {
                        return 'black';
                    }
                },
            },
            legend: {
                display: false,
            },
            tooltip: {
                bodyAlign: 'center',
            },
        },
    };

    const handleCustomiseWidgetMenuClick = (): void => {
        setDrawerOpen(true);
        setDisplayTable(false);
        setChartSelectedValue('');
    };
    const handleDeleteWidgetMenuClick = (): void => {
        setDisplayTable(false);
        setChartSelectedValue('');

        props.onDelete(data.id);
    };

    return (
        <div className={displayTable ? styles.rootExpanded : styles.root}>
            {drawerOpen && (
                <CustomizeWidgetDrawer
                    widgetDetails={data}
                    handleClose={() => {
                        setDrawerOpen(false);
                    }}
                    handleSubmit={(widgetDetails: Widget) => {
                        if (!widgetDetails.config.initialConfig) {
                            widgetDetails.config.initialConfig = true;
                            setConfigRequired(false);
                        }

                        onUpdateWidget(widgetDetails);
                        setDrawerOpen(false);
                    }}
                />
            )}
            <div
                style={{
                    height:
                        data.config.chartType === 'Bar Graph' &&
                        !noDataResults &&
                        !drawerOpen
                            ? '300px'
                            : '100%',
                }}
            >
                <div className={styles.widgetHeader}>
                    <div
                        className={styles.draggableAreaStyle}
                        {...listeners}
                        {...attributes}
                    >
                        <DragIcon />
                        <div className={styles.widgetTitle}>
                            {data.config.name}
                        </div>
                    </div>
                    {!displayTable && (
                        <MenuButton
                            key={data.id}
                            handleCustomiseWidgetMenuClick={
                                handleCustomiseWidgetMenuClick
                            }
                            handleDeleteWidgetMenuClick={
                                handleDeleteWidgetMenuClick
                            }
                            widgetId={data.id.toString()}
                        />
                    )}
                </div>
                <div>
                    {!configRequired && !noDataResults && !drawerOpen ? (
                        <div className={styles.chartAndTooltipContainer}>
                            <ContractorDocumentExpirationDisplayChartType
                                displayType={data.config.chartType}
                                setChartSelectedValue={setChartSelectedValue}
                                chartSelectedValue={chartSelectedValue}
                                setDisplayTable={setDisplayTable}
                                setActiveTablePage={setActiveTablePage}
                                chartData={chartData}
                                barOptions={barOptions}
                                doughnutOptions={doughnutOptions}
                            />
                            <ToolTipQuestionMarkIcon
                                dataTestId={'CDEWidgetTooltipButton'}
                                widgetId={data.id.toString()}
                            >
                                <>
                                    <strong>Tool Tip</strong>
                                    To view various document statuses on a
                                    table, click on the individual status shown
                                    on the chart.
                                    <br />
                                    <br />
                                    You can minimize the document statuses table
                                    by clicking on the same status again.
                                </>
                            </ToolTipQuestionMarkIcon>
                        </div>
                    ) : (
                        <>
                            {configRequired && !drawerOpen && (
                                <div
                                    className={styles.configureViewLayout}
                                    onClick={() => {
                                        setDrawerOpen(true);
                                    }}
                                >
                                    <div className={styles.widgetStatusFont}>
                                        Customize to Configure View
                                    </div>
                                </div>
                            )}
                            {noDataResults &&
                                !configRequired &&
                                !drawerOpen && (
                                    <div className={styles.noContentLayout}>
                                        <div className={styles.alertIconStyle}>
                                            <div
                                                className={
                                                    styles.alertIconWrapper
                                                }
                                            >
                                                <AlertIcon />
                                            </div>
                                            <div
                                                className={
                                                    styles.widgetStatusFont
                                                }
                                            >
                                                No Records to Show!
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {drawerOpen && (
                                <div className={styles.noContentLayout}>
                                    <div className={styles.widgetStatusFont}>
                                        Configuring View
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {displayTable && chartSelectedValue && (
                <div className={styles.chartOpenContainer}>
                    <DataTableHeader
                        chartSelectedValue={convertDisplayTitle(
                            chartSelectedValue
                        )}
                        handleCustomiseWidgetMenuClick={
                            handleCustomiseWidgetMenuClick
                        }
                        handleDeleteWidgetMenuClick={
                            handleDeleteWidgetMenuClick
                        }
                        widgetDetails={data}
                    />
                    <div style={{ paddingTop: '20px' }}>
                        <ContractorDocumentExpirationTable
                            mappedBucketCountData={mappedBucketCountData}
                            activeTablePage={activeTablePage}
                            documentFilters={filters}
                            chartSelectedValue={chartSelectedValue}
                            recordsPerPage={data.config.noOfDocumentsToShow}
                            onPageClick={pageNumber => {
                                setActiveTablePage(pageNumber);
                            }}
                            data={data}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
