import React, { useRef } from 'react';
import { InteractionItem } from 'chart.js';
import { Bar, Doughnut, getElementAtEvent } from 'react-chartjs-2';
import useLibraryStyles from '../../components/WidgetStyleLibrary';
import { ClosedContainerProps } from 'widgets/models/WidgetModels';

export function ExceptionFlagDisplayChartType(
    props: ClosedContainerProps & {
        labelIndex: number;
        setLabelIndex: React.Dispatch<React.SetStateAction<number>>;
    }
): JSX.Element {
    const styles = useLibraryStyles();
    const chartRef = useRef(null);
    const {
        displayType,
        chartData,
        setChartSelectedValue,
        setDisplayTable,
        setActiveTablePage,
        barOptions,
        doughnutOptions,
        chartSelectedValue,
        labelIndex,
        setLabelIndex,
    } = props;

    const toggleTable = (event): void => {
        if (chartRef?.current) {
            const clickedElement: InteractionItem[] = getElementAtEvent(
                chartRef.current,
                event
            );

            if (clickedElement.length) {
                if (displayType === 'Pie Chart') {
                    applyIndex(clickedElement[0].index, 0);
                } else if (displayType === 'Bar Graph') {
                    applyIndex(
                        clickedElement[0]?.datasetIndex,
                        clickedElement[0].index
                    );
                    setLabelIndex(clickedElement[0].index);
                }
            } else {
                setChartSelectedValue('');
                setDisplayTable(false);
            }
            setActiveTablePage(1);
        }
    };

    const applyIndex = (index: number, stackedIndex: number): void => {
        const selectedValue: string = chartData?.values[index];
        if (
            labelIndex === stackedIndex &&
            selectedValue === chartSelectedValue
        ) {
            setChartSelectedValue('');
            setDisplayTable(false);
        } else {
            setChartSelectedValue(selectedValue);
            setDisplayTable(true);
        }
    };
    return (
        <>
            {displayType === 'Pie Chart' && (
                <div className={styles.chartContainer}>
                    <Doughnut
                        data-test-id={'EFWidgetDoughnutChart'}
                        ref={chartRef}
                        data={chartData}
                        options={doughnutOptions}
                        onClick={toggleTable}
                    />
                </div>
            )}
            {displayType === 'Bar Graph' && (
                <div
                    className={styles.chartContainer}
                    style={{ minHeight: 250 }}
                >
                    <Bar
                        data-test-id={'EFWidgetBarChart'}
                        ref={chartRef}
                        data={chartData}
                        options={barOptions}
                        onClick={toggleTable}
                    />{' '}
                </div>
            )}
        </>
    );
}
