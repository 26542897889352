import * as React from 'react';
import { SVGProps } from 'react';

const BackIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 141 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <path
            data-name='Path 2563'
            d='m10.306 2.498-5.4 5.4 5.4 5.406'
            fill='none'
            stroke='#17c2ed'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={3}
        />
    </svg>
);

export default BackIcon;
