import {
    CommentsFilterOptions,
    MicroFrontendConstants,
} from '../common/Constants';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    useGetCommentsQuery,
    useGetSelectiveFeedsQuery,
    useLazyGetCommentsQuery,
    useLazyGetSelectiveFeedsQuery,
} from './store/api/FeedsApi';
import { getUnReadCount } from '../common/store/api/HttpObjectConfig';
import { getUserType, isLoggedIn, UserTypes } from '../utils/utils';
import { CommentsDrawer } from './CommentsContainer';
import AlertHandler from '../common/AlertHandler';
import { setUnreadMessagesCount } from 'comments/store/api/AppConfigSlice';
import {
    useGetDraftStatusesQuery,
    useGetOrderStatusesQuery,
    useGetWorkOrderStatusesQuery,
    useGetQuoteStatusesQuery,
} from 'comments/store/api/StatusesApi';

import { useGetUsersAndGroupListQuery } from 'comments/store/api/UsersAndGroupListApi';
import { useAppDispatch } from 'comments/store/CommentsStore';
import { appConfigDataSelector } from 'comments/selector/CommentsSelector';
import { Box } from '@mui/material';
import { clearDocumentComments } from 'comments/store/api/ShowAllComments';

const initialCommentsFilter = {
    scenario: CommentsFilterOptions.Allcomments,
};
/**
 *
 * @param props
 * @returns
 */
export function CommentsWrapper() {
    const dispatch = useAppDispatch();
    const appConfig = useSelector(appConfigDataSelector);
    const [fetchScenario, setFetchScenario] = React.useState(
        initialCommentsFilter
    );
    const { data: commentsData } = useGetCommentsQuery(
        CommentsFilterOptions.Allcomments,
        { skip: !isLoggedIn() }
    );
    const userType = getUserType();
    //Skip GetUsersAndGroups Api call for Non Motili users, since we dont display suggestions for them
    const { data: usersAndGroupApiData } = useGetUsersAndGroupListQuery(
        'getusersandgroups',
        {
            skip: !isLoggedIn() || userType !== UserTypes.Motili,
        }
    );
    const [fetchFeeds] = useLazyGetCommentsQuery();

    useGetDraftStatusesQuery(null, {
        skip:
            !isLoggedIn() ||
            userType === UserTypes.Client ||
            userType === UserTypes.Contractor,
    });
    useGetOrderStatusesQuery(null, { skip: !isLoggedIn() });
    useGetWorkOrderStatusesQuery(null, { skip: !isLoggedIn() });
    useGetQuoteStatusesQuery(null, { skip: !isLoggedIn() });

    const [triggerSelectiveFeeds] = useLazyGetSelectiveFeedsQuery();
    const { data: selectiveFeeds } = useGetSelectiveFeedsQuery(fetchScenario, {
        skip:
            !isLoggedIn() ||
            fetchScenario.scenario === initialCommentsFilter.scenario,
    });

    //When the user logs in check for unread msgs.If atleast one exists, show dot in comment icon.
    //Update appConfig reducer to mark check has made, so that onRefresh it doesnot happen again. It should happen only on re-login
    useEffect(() => {
        if (appConfig.shouldCheckForUnreadMsgAndAddDot && commentsData) {
            //Wait for selective feeds to be fetched.

            if (commentsData?.Items?.length >= 0) {
                let hasUnreads = false;
                commentsData.Items.forEach(feed => {
                    if (
                        feed.unreadObjectIds &&
                        feed.unreadObjectIds.length >= 0
                    ) {
                        hasUnreads = true;
                    }
                });
                if (hasUnreads) {
                    document.dispatchEvent(
                        new CustomEvent(
                            MicroFrontendConstants.AddDotToCommentIcon
                        )
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentsData]);

    const selectCommentsBasedOnScenario = () => {
        if (fetchScenario.scenario === CommentsFilterOptions.Allcomments) {
            return commentsData;
        } else {
            return selectiveFeeds;
        }
    };

    const fetchComments = () =>
        fetchFeeds(CommentsFilterOptions.Allcomments).then(async res => {
            try {
                const unreadCount = await getUnReadCount();
                dispatch(setUnreadMessagesCount(unreadCount.data));
            } catch (e) {}
        });

    useEffect(() => {
        if (fetchScenario.scenario === CommentsFilterOptions.Allcomments) {
            fetchComments();
        } else if (
            fetchScenario.scenario === CommentsFilterOptions.Unreadcomments
        ) {
            triggerSelectiveFeeds(fetchScenario);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchScenario]);

    return (
        <Box>
            <AlertHandler />
            <CommentsDrawer
                fetchScenario={fetchScenario}
                setCommentsDrawerInitialState={() => {
                    setFetchScenario(initialCommentsFilter);
                }}
                setFetchScenario={setFetchScenario}
                usersAndGroupApiData={usersAndGroupApiData || []}
                commentsResponse={selectCommentsBasedOnScenario()}
                resetToAllFeeds={() => {
                    dispatch(clearDocumentComments());
                    setFetchScenario({
                        scenario: CommentsFilterOptions.Allcomments,
                    });
                }}
            />
        </Box>
    );
}
