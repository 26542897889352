import { createUseStyles } from 'react-jss';

const useDrawerHeadingAndTitleStyles = createUseStyles({
    headingSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    customizeSection: {
        display: 'flex',
        alignItems: 'center',
    },
    customizeIconWrapper: {
        border: '1px solid #EDF0F5',
        padding: '2px',
        marginRight: '12px',
        backgroundColor: '#FFFFFF',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headingTypography: {
        fontSize: '24px',
    },
    formSection: {
        marginTop: '36px',
    },
    requiredTextColor: {
        color: '#57c0e8',
    },
});

export default useDrawerHeadingAndTitleStyles;
