import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep, unionBy } from 'lodash';
import { RootState } from '../store/CommentsStore';
import { getDocumentsPayload, getDraftsPayload } from '../../utils/utils';
import {
    accessToken,
    getAsyncAwaitHttpObj,
} from '../../common/store/api/HttpObjectConfig';

//This API is used to fetch the details of Documents except Drafts(i.e WO, Quotes, Orders)
export const fetchOtherDocuments = createAsyncThunk(
    'documents/fetchOtherDocuments',
    async (otherDocsToFetch: string[], { getState }) => {
        //Fetch documents, check if any document already exists in store. If it exists replace it with new one.
        try {
            const state: RootState = getState();
            let existingState: RootState = cloneDeep(state);
            let existingDocs = existingState.documentsList.documents;
            const response = await getAsyncAwaitHttpObj().post(
                `/documentSearches/search?access_token=${accessToken()}`,
                getDocumentsPayload(otherDocsToFetch)
            );
            const newDocs = response.data;
            return unionBy(newDocs, existingDocs, 'id');
        } catch (error) {
            throw Error(
                'An Unknown Error Occurred; Unable to Retrieve template business channel (MOTILI)'
            );
        }
    }
);
//This API is used to fetch the details of Drafts Documents.
export const fetchDraftDocuments = createAsyncThunk(
    'documents/fetchDraftDocuments',
    async (draftsToFetch: string[], { getState }) => {
        try {
            const state: RootState = getState();
            let existingState: RootState = cloneDeep(state);
            let existingDrafts = existingState.documentsList.drafts;
            const response = await getAsyncAwaitHttpObj().post(
                `/drafts/search?access_token=${accessToken()}`,
                getDraftsPayload(draftsToFetch)
            );
            const newDocs = response.data;
            return unionBy(newDocs, existingDrafts, 'id');
        } catch (error) {
            throw Error(
                'An Unknown Error Occurred; Unable to Retrieve template business channel (MOTILI)'
            );
        }
    }
);
