import { createUseStyles } from 'react-jss';

const useTableHeaderStyles = createUseStyles({
    tableHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    expandedTableMenuButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    italicFont: {
        fontStyle: 'italic',
        paddingLeft: '25px',
    },
    headerAndFilterIcon: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default useTableHeaderStyles;
