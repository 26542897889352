import { createSlice } from '@reduxjs/toolkit';

const configurationSlice = createSlice({
    name: 'nonPersistentConfigurationSlice',
    initialState: {
        isCommentsDrawerOpened: false,
    },
    reducers: {
        setCommentNavDrawerOpenStatus: (state, action) => {
            state.isCommentsDrawerOpened = action.payload;
        },
    },
});

export default configurationSlice.reducer;
export const { setCommentNavDrawerOpenStatus } = configurationSlice.actions;
