import React, { useState } from 'react';
import { Widget, BaseUrl } from '../../models/WidgetModels';
import { ToggleButtonGroup } from '../../components/toggleButtonGroup/ToggleButtonGroup';
import {
    useGetContractorStatusesQuery,
    useGetCredentialsListQuery,
    useGetContractorCompanyDocumentsListQuery,
} from '../../store/api/ContractorExpiredDocumentsApi';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import { DocumentTypeFilterOptions } from '../../models/ContractorDocumentExpirationModel';
import { DrawerHeadingAndTitle } from '../../components/drawer/DrawerHeadingAndTitle';
import {
    ButtonPrimary,
    ButtonSecondary,
    DropDownSelect,
    RadioGroup,
} from 'motili-ui';
import { DrawerStateDropdown } from '../../components/drawer/DrawerStateDropdown';
import { DrawerContainer } from '../../components/drawer/DrawerContainer';
import { ToggleIconButtonGroup } from '../../components/toggleIconButtonGroup/ToggleIconButtonGroup';
import useDrawerStyles from '../../components/drawer/DrawerStyles';

interface ContractorDocumentExpirationWidgetDrawerProps {
    widgetDetails: Widget;
    handleSubmit: (widgetDetails: Widget) => void;
    handleClose: () => void;
}

export function CustomizeWidgetDrawer(
    props: ContractorDocumentExpirationWidgetDrawerProps
) {
    const styles = useDrawerStyles();
    const {
        handleClose,
        widgetDetails: initialWidgetDetails,
        handleSubmit,
    } = props;

    const { data: getContractorStatuses } = useGetContractorStatusesQuery(null);
    const { data: getCredentialsList } = useGetCredentialsListQuery(null);
    const { data: getContractorCompanyDocumentsList } =
        useGetContractorCompanyDocumentsListQuery(null);

    const statusDropdownOptions: { label: string; value: string }[] | [] =
        getContractorStatuses
            ? getContractorStatuses.map(status => {
                  return {
                      label: snakeCaseToTitleCase(status),
                      value: status,
                  };
              })
            : [];
    const credentialDropdownOptions: { label: string; value: string }[] | [] =
        getCredentialsList
            ? getCredentialsList.map(credential => {
                  return {
                      label: credential.description,
                      value: credential.name,
                  };
              })
            : [];
    const contractorCompanyDropdownOptions:
        | { label: string; value: string }[]
        | [] = getContractorCompanyDocumentsList
        ? getContractorCompanyDocumentsList.map(credential => {
              return {
                  label: credential.description,
                  value: credential.name,
              };
          })
        : [];

    const [widgetData, setWidgetData] = useState(initialWidgetDetails);

    const handleDropdownSelect = async (
        type: string,
        value: any
    ): Promise<void> => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: value,
            },
        });
    };
    const updateDocumentTypeFilter = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                documentType: (event.target as HTMLInputElement)
                    .value as DocumentTypeFilterOptions,
            },
        });
    };
    const handleButtonSelect = (event): void => {
        const value: string = event.target.value
            ? event.target.value
            : event.target.id;
        const valueType: string = event.target.getAttribute('value-type');
        const type: string = event.target.getAttribute('data-type-id');

        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: valueType === 'number' ? Number(value) : value,
            },
        });
    };

    return (
        <DrawerContainer handleClose={handleClose}>
            <>
                {/*widget title*/}
                <DrawerHeadingAndTitle
                    widgetData={widgetData}
                    setWidgetData={setWidgetData}
                    dataTestId={'CDEWidgetTitleInput'}
                />

                <div className={styles.filterContainer}>
                    {/*/!*chart type*!/*/}
                    <div>
                        <div className={styles.dataVisualTypeRow}>
                            <h4>Data Visual Type</h4>
                        </div>
                        <div>
                            <ToggleIconButtonGroup
                                options={[
                                    {
                                        id: 'PieChartButton',
                                        img: `${BaseUrl.widgetImage}/donut-icon.svg`,
                                        value: 'Pie Chart',
                                        dataTestId: 'CDEPieChartButton',
                                    },
                                    {
                                        id: 'BarGraphButton',
                                        img: `${BaseUrl.widgetImage}/bar-chart-icon.svg`,
                                        value: 'Bar Graph',
                                        dataTestId: 'CDEBarGraphButton',
                                    },
                                ]}
                                type={'chartType'}
                                selectedOption={widgetData.config.chartType}
                                handleButtonSelect={handleButtonSelect}
                            />
                        </div>
                    </div>

                    {/*/!*per page*!/*/}
                    <div className={styles.marginTop20}>
                        <h4>Select the Number of Records Per Page</h4>
                    </div>
                    <ToggleButtonGroup
                        options={[
                            {
                                id: 'fiveButton',
                                text: '5',
                                value: 5,
                                dataTestId: 'CDEWidgetFivePerPageButton',
                            },
                            {
                                id: 'tenButton',
                                text: '10',
                                value: 10,
                                dataTestId: 'CDEWidgetTenPerPageButton',
                            },
                        ]}
                        type={'noOfDocumentsToShow'}
                        selectedOption={widgetData.config.noOfDocumentsToShow}
                        handleButtonSelect={handleButtonSelect}
                    />

                    {/*/!*filter heading*!/*/}
                    <div className={styles.marginTop20}>
                        <h4>Filter</h4>
                    </div>

                    <RadioGroup
                        data-test-id={'CDEWidgetRadios'}
                        className={'document-type-radio'}
                        name={'filter-select'}
                        label={'Document Type'}
                        required
                        options={[
                            {
                                label: 'Contractor Company Documents',
                                value: 'CONTRACTOR_COMPANY',
                                id: 'contractor-company',
                            },
                            {
                                label: 'Contractor Team Credentials',
                                value: 'CONTRACTOR_TEAM_CREDENTIALS',
                                id: 'contractor-team-credentials',
                            },
                        ].map(opt => ({
                            ...opt,
                            checked:
                                widgetData?.config?.documentType === opt.value,
                        }))}
                        onChange={updateDocumentTypeFilter}
                    />

                    {/*/!*contractor company document type or contractor team member credential type dropdowns*!/*/}
                    {widgetData.config.documentType ===
                        'CONTRACTOR_COMPANY' && (
                        <DropDownSelect
                            className={styles.marginTop20}
                            id={
                                'CDEWidgetContractorCompanyDocumentTypeDropdown'
                            }
                            label={'Contractor Company Document Type'}
                            required={true}
                            isMulti={false}
                            onChange={value => {
                                handleDropdownSelect(
                                    'contractorCompanyDocumentType',
                                    value
                                );
                            }}
                            options={contractorCompanyDropdownOptions}
                            value={
                                widgetData.config.contractorCompanyDocumentType
                            }
                        />
                    )}
                    {widgetData.config.documentType ===
                        'CONTRACTOR_TEAM_CREDENTIALS' && (
                        <DropDownSelect
                            className={styles.marginTop20}
                            id={'CDEWidgetCredentialTypeDropdown'}
                            label={'Contractor Team Member Credential Type'}
                            required={true}
                            isMulti={false}
                            onChange={value => {
                                handleDropdownSelect('credentialType', value);
                            }}
                            options={credentialDropdownOptions}
                            value={
                                widgetData.config.credentialType.value
                                    ? widgetData.config.credentialType
                                    : undefined
                            }
                        />
                    )}

                    {/*/!*Contractor status*!/*/}
                    <DropDownSelect
                        className={styles.marginTop20}
                        id={'CDEWidgetContractorStatusDropdown'}
                        label={'Contractor Status'}
                        isMulti={true}
                        onChange={value => {
                            handleDropdownSelect('contractorStatuses', value);
                        }}
                        options={statusDropdownOptions}
                        value={widgetData.config.contractorStatuses}
                    />

                    {/*/!*state*!/*/}
                    <DrawerStateDropdown
                        widgetData={widgetData}
                        setWidgetData={setWidgetData}
                        id={'CDEStateSelectDropdown'}
                    />
                </div>

                <div className={styles.confirmCancelContainer}>
                    <div className={styles.cancelButtonRightPadding}>
                        <ButtonSecondary onClick={handleClose}>
                            Cancel
                        </ButtonSecondary>
                    </div>
                    <div>
                        <ButtonPrimary
                            onClick={() => {
                                handleSubmit(widgetData);
                            }}
                            disabled={
                                (widgetData.config.documentType ===
                                    'CONTRACTOR_TEAM_CREDENTIALS' &&
                                    !widgetData.config.credentialType.value) ||
                                widgetData.config.name.length === 0
                            }
                        >
                            Confirm
                        </ButtonPrimary>
                    </div>
                </div>
            </>
        </DrawerContainer>
    );
}
