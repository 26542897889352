import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { dataDashboardBaseQuery } from '../../../common/store/api/BaseQueryConfig';
import moment from 'moment/moment';
import {
    WorkOrderTypes,
    State,
    User,
    WorkOrderCountAPI,
    WorkOrderAPI,
    WorkOrderFilters,
    ClientApi,
    ChildClientApi,
    ContractorApi,
} from '../../models/KeyWorkOrderStatusesModel';

export const getKeyWorkOrderStatusesWidgetApi = createApi({
    reducerPath: 'workOrderStatusesReducer',
    baseQuery: dataDashboardBaseQuery,
    tagTypes: [
        'work_orders',
        'work_order_count',
        'work_order_types',
        'work_order_statuses',
        'regions_and_states',
        'users',
        'clients',
        'childClients',
        'contractors',
    ],
    endpoints: builder => ({
        GetFilteredWorkOrders: builder.query<WorkOrderAPI, WorkOrderFilters>({
            query: filters => ({
                url: `/v1/work-order?page=${filters.page}&size=${filters.noOfDocumentsToShow}`,
                method: 'POST',
                body: {
                    ...filters,
                    ...(filters.dateRangeType === 'FROM_CREATION_DATE'
                        ? {
                              sinceCreatedDate: moment()
                                  .subtract(filters.dateRangeFrom, 'days')
                                  .toISOString(),
                          }
                        : {
                              sinceScheduledDate: moment()
                                  .subtract(filters.dateRangeFrom, 'days')
                                  .toISOString(),
                          }),
                },
            }),
            providesTags: ['work_orders'],
        }),
        GetFilteredWorkOrderCount: builder.query<
            WorkOrderCountAPI[],
            WorkOrderFilters
        >({
            query: filters => ({
                url: `/v1/work-order/status-id-counts`,
                method: 'POST',
                body: {
                    ...filters,
                    ...(filters.dateRangeType === 'FROM_CREATION_DATE'
                        ? {
                              sinceCreatedDate: moment()
                                  .subtract(filters.dateRangeFrom, 'days')
                                  .toISOString(),
                          }
                        : {
                              sinceScheduledDate: moment()
                                  .subtract(filters.dateRangeFrom, 'days')
                                  .toISOString(),
                          }),
                },
            }),
            providesTags: ['work_order_count'],
        }),
        GetWorkOrderTypes: builder.query<WorkOrderTypes[], null>({
            query: () => ({
                url: `/v1/work-order/types`,
                method: 'GET',
            }),
            providesTags: ['work_order_types'],
        }),
        GetRegionsAndStates: builder.query<State[], null>({
            query: () => ({
                url: `/v1/region`,
                method: 'GET',
            }),
            providesTags: ['regions_and_states'],
        }),
        GetWorkOrderStatuses: builder.query<string[], null>({
            query: () => ({
                url: `metadata/v1/work-order-statuses`,
                method: 'GET',
            }),
            providesTags: ['work_order_statuses'],
        }),
        GetUsers: builder.query<User[], null>({
            query: () => ({
                url: `v1/user`,
                method: 'GET',
            }),
            providesTags: ['users'],
        }),
        GetClients: builder.query<ClientApi[], null>({
            query: () => ({
                url: `v1/client`,
                method: 'GET',
            }),
            providesTags: ['clients'],
        }),
        GetContractors: builder.query<ContractorApi[], null>({
            query: () => ({
                url: 'v1/contractor',
                method: 'GET',
            }),
            providesTags: ['contractors'],
        }),
        GetChildClients: builder.query<ChildClientApi[], string | null>({
            query: (path): any => ({
                url: `v1/client?${path}`,
                method: 'GET',
            }),
            providesTags: ['childClients'],
        }),
    }),
});

export const {
    useGetFilteredWorkOrdersQuery,
    useGetFilteredWorkOrderCountQuery,
    useGetWorkOrderTypesQuery,
    useGetRegionsAndStatesQuery,
    useGetWorkOrderStatusesQuery,
    useGetUsersQuery,
    useGetClientsQuery,
    useGetChildClientsQuery,
    useGetContractorsQuery,
} = getKeyWorkOrderStatusesWidgetApi;
