import React, { MouseEventHandler } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import useButtonStyles from '../ToggleButtonGroupStyles';

interface ToggleButtonGroupProps {
    options: {
        id: string;
        text: string;
        value: string | number;
        dataTestId: string;
    }[];
    type: string;
    selectedOption: string | number;
    handleButtonSelect: MouseEventHandler<HTMLDivElement>;
}

export function ToggleButtonGroup(props: ToggleButtonGroupProps): JSX.Element {
    const styles = useButtonStyles();
    const { handleButtonSelect, options, type, selectedOption } = props;

    return (
        <ButtonGroup onClick={handleButtonSelect}>
            {options.map(option => (
                <Button
                    key={option.id}
                    id={option.id}
                    data-type-id={type}
                    value={option.value}
                    value-type={typeof option.value}
                    data-test-id={option.dataTestId}
                    className={
                        selectedOption === option.value
                            ? styles.activeButton
                            : styles.inactiveButton
                    }
                >
                    {option.text}
                </Button>
            ))}
        </ButtonGroup>
    );
}
