import { createUseStyles } from 'react-jss';

const useDrawerStyles = createUseStyles({
    drawerOverlay: {
        backgroundColor: 'black',
        opacity: '50%',
        position: 'fixed',
        top: 0,
        left: 0,
        width: `calc(100% - 670px)`,
        height: '100%',
        zIndex: 49,
    },
    drawerContainer: {
        position: 'fixed',
        top: 0,
        right: 0,
        width: '670px',
        height: '100%',
        padding: '48px 32px',
        backgroundColor: '#f7f7f7',
        overflow: 'auto',
        zIndex: 49,
    },
    filterContainer: {
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '20px',
        marginTop: '12px',
    },
    confirmCancelContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '25px',
    },
    cancelButtonRightPadding: {
        paddingRight: '15px',
    },
    dataVisualTypeRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    boldText: {
        fontWeight: 'bold',
    },
    errorMessageTextColor: {
        color: 'red',
    },
    marginTop20: {
        marginTop: '20px',
    },
    multipleInputRow: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    radioInputRow: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px', 
        position: 'absolute', 
        top: '458px', 
        left: '46px'
    },
    singleDropdownSelectRow: {
        marginTop: '20px',
        width: '263px',
    },
    dropdownSelectWidth: {
        width: '263px',
    },
});

export default useDrawerStyles;
