import { createUseStyles } from 'react-jss';

const useButtonStyles = createUseStyles({
    activeButton: {
        backgroundColor: '#0e2248',
        '&:hover, &:focus': {
            backgroundColor: '#0e2248',
            color: 'white',
        },
    },
    inactiveButton: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        '&:hover': {
            backgroundColor: '#0e2248',
            color: 'white',
        },
    },
    inactiveIconButton: {
        backgroundColor: '#d8dce3',
        color: 'black',
        '&:hover': {
            backgroundColor: '#0e2248',
            color: 'white',
        },
    },
    maxHeight: {
        height: '75px',
    },
});

export default useButtonStyles;
