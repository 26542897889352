import React, { useMemo } from 'react';
import Moment from 'react-moment';
import { Widget, TableHeaders, BaseUrl } from '../../models/WidgetModels';
import { DataTable } from 'motili-ui';
import { Tooltip } from 'react-tooltip';
import {
    snakeCaseToTitleCase,
    shortenedAddress,
} from '../../../utils/WidgetUtils';
import { useGetContractorDocumentDataQuery } from '../../store/api/ContractorExpiredDocumentsApi';
import useMenuButtonStyles from '../../components/menuButton/MenuButtonStyles';

interface ContractorDocumentExpirationTableProps {
    mappedBucketCountData: { label: string; value: string; count: number }[];
    activeTablePage: number;
    onPageClick: (e: number) => void;
    documentFilters: object;
    chartSelectedValue: string;
    recordsPerPage: number;
    data: Widget;
}

export const ContractorDocumentExpirationTable = (
    props: ContractorDocumentExpirationTableProps
): JSX.Element => {
    const styles = useMenuButtonStyles();
    const {
        mappedBucketCountData,
        activeTablePage,
        onPageClick,
        documentFilters,
        chartSelectedValue,
        recordsPerPage,
        data,
    } = props;

    const totalNumberOfRecords:
        | {
              label: string;
              value: string;
              count: number;
          }[]
        | undefined = mappedBucketCountData?.filter(
        obj => obj.value === chartSelectedValue
    );

    const tableHeaders: TableHeaders[] = [
        {
            displayName: 'Document Type',
            value: 'documentType',
            sortable: true,
            ascending: false,
        },
        {
            displayName: 'Name',
            value: 'name',
            sortable: true,
            ascending: false,
        },
        {
            displayName: 'Contractor Status',
            value: 'status',
            sortable: true,
            ascending: false,
        },
        {
            displayName: 'Until Expiration',
            value: 'daysUntilExpiration',
            sortable: true,
            ascending: false,
        },
        {
            displayName: 'Expiration Date',
            value: 'expirationDate',
            sortable: true,
            ascending: false,
        },
    ];

    const { data: GetFilteredContractorDocumentData } =
        useGetContractorDocumentDataQuery(
            {
                ...documentFilters,
                page: activeTablePage - 1,
                expirationBucket: chartSelectedValue,
            },
            { refetchOnMountOrArgChange: true }
        );

    function formatPhoneNumber(phoneNumber: string): string {
        const areaCode = phoneNumber.slice(0, 3);
        const firstPart = phoneNumber.slice(3, 6);
        const secondPart = phoneNumber.slice(6);

        return `(${areaCode})-${firstPart}-${secondPart}`;
    }

    const mappedWorkOrderCountData:
        | []
        | Array<{
              daysUntilExpiration: string;
              documentType: React.ReactElement;
              expirationDate: React.ReactElement;
              name: React.ReactElement;
              status: string;
          }> = useMemo(() => {
        if (GetFilteredContractorDocumentData) {
            return GetFilteredContractorDocumentData.result.map(
                (record, index) => {
                    const contractorLink = `/#/ops/contractors/${record.contractor.id}/details`;
                    const contractorTeamMemberLink = `/#/ops/contractors/${record.contractor.id}/team`;
                    const stdAddress = shortenedAddress(
                        record.contractor.address.stdDeliveryLine
                    );
                    const cityStateZip = `${record.contractor.address.stdCity}, ${record.contractor.address.stdRegion} ${record.contractor.address.stdPostalCode}`;

                    let phoneNumberToDisplay;
                    switch (data.config.documentType) {
                        case 'CONTRACTOR_COMPANY':
                            phoneNumberToDisplay =
                                record.contractor.phoneContacts[0].phoneNumber;
                            break;
                        case 'CONTRACTOR_TEAM_CREDENTIALS':
                            phoneNumberToDisplay = record.user?.mobile
                                ? formatPhoneNumber(record.user?.mobile)
                                : 'No Phone Number';
                            break;
                        default:
                            phoneNumberToDisplay = null;
                            break;
                    }

                    const infoCardContent = (
                        <div
                            id='menu-list-quote'
                            style={{ maxWidth: 'fit-content' }}
                        >
                            <div className={styles.menuItemStyle}>
                                <span className={styles.menuItemIconStyle}>
                                    <img
                                        alt='phone icon'
                                        src={`${BaseUrl.widgetImage}/phone-Icon.svg`}
                                    />
                                </span>
                                <span className='menuItemText menuItemTextStyle'>
                                    {phoneNumberToDisplay}
                                </span>
                            </div>
                            <div className={styles.menuItemStyle}>
                                <span className={styles.menuItemIconStyle}>
                                    <img
                                        alt='home icon'
                                        src={`${BaseUrl.widgetImage}/home-Icon.svg`}
                                    />
                                </span>
                                <div className={styles.menuItemTextStyle}>
                                    <span>{stdAddress}</span>
                                    <br />
                                    <span>{cityStateZip}</span>
                                </div>
                            </div>
                        </div>
                    );

                    return {
                        documentType: (
                            <div>
                                <img
                                    id={`con-doc-exp-info-${record.contractor.id}-${index}`}
                                    key={index}
                                    alt='info icon'
                                    className={styles.infoTipStyle}
                                    src={`${BaseUrl.widgetImage}/info-icon.svg`}
                                />
                                <Tooltip
                                    id={`con-doc-exp-info-tooltip-${record.contractor.id}-${index}`}
                                    anchorSelect={`#con-doc-exp-info-${record.contractor.id}-${index}`}
                                    openOnClick
                                    place='bottom-start'
                                    opacity={1}
                                    style={{
                                        backgroundColor: '#0e2248',
                                        zIndex: 5,
                                        borderRadius: 6,
                                        maxWidth: 'fit-content',
                                    }}
                                    disableStyleInjection={'core'}
                                >
                                    {infoCardContent}
                                </Tooltip>

                                {record.category || record.label}
                            </div>
                        ),
                        status: snakeCaseToTitleCase(record.contractor.status),
                        name:
                            data.config.documentType ===
                            'CONTRACTOR_TEAM_CREDENTIALS' ? (
                                <a
                                    href={contractorTeamMemberLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {record.user?.firstName}{' '}
                                    {record.user?.lastName}
                                </a>
                            ) : (
                                <a
                                    href={contractorLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {record.contractor.name}
                                </a>
                            ),
                        daysUntilExpiration:
                            record.daysToExpiration >= 0 ? (
                                `${record.daysToExpiration} Days`
                            ) : (
                                <div style={{ color: 'red' }}>Expired</div>
                            ),
                        expirationDate: (
                            <Moment format='MMM D, YYYY'>
                                {record.expiration}
                            </Moment>
                        ),
                    };
                }
            );
        }

        return [];
    }, [GetFilteredContractorDocumentData, data.config.documentType, styles]);

    return (
        <DataTable
            headerBackground='#0E2248'
            headerTextColor='#FFFFFF'
            data-test-id='CDEWidgetClosedTable'
            totalRecordCount={totalNumberOfRecords[0]?.count}
            records={mappedWorkOrderCountData}
            tableHeaders={tableHeaders}
            showPagination
            activePage={activeTablePage}
            recordsPerPage={recordsPerPage}
            onPageClick={pageNumber => {
                onPageClick(pageNumber);
            }}
            onListItemClick={() => {}}
            onTableHeaderItemClick={() => {}}
        />
    );
};
