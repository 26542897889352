import React, { useState, useEffect, useMemo } from 'react';
import {
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Chart,
    Legend,
    Tooltip,
    ChartOptions,
} from 'chart.js';
import { Widget, ChartData, DataSet } from '../../models/WidgetModels';
import {
    EXCEPTION_FLAG,
    ExceptionFlagFilters,
} from 'widgets/models/ExceptionFlagModel';
import { WidgetProps } from '../../render/RenderWidget';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import AlertIcon from 'common/icons/AlertIcon';
import DragIcon from 'common/icons/DragIcon';
import { CustomizeWidgetDrawer } from './ExceptionFlagDrawer';
import { DataTableHeader } from '../../components/tableHeader/DataTableHeader';
import { MenuButton } from '../../components/menuButton/MenuButton';
import { ToolTipQuestionMarkIcon } from '../../components/toolTipQuestionMark/ToolTipQuestionMark';
import { ExceptionFlagTable } from './ExceptionFlagTable';
import { ExceptionFlagDisplayChartType } from './ExceptionFlagDisplayChartType';
import { useGetFilteredWorkOrderExceptionCountQuery } from 'widgets/store/api/ExceptionFlagWidgetApi';
import useLibraryStyles from '../../components/WidgetStyleLibrary';

import _ from 'lodash';

Chart.register(
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
    Tooltip
);

const defaultChartData = {
    labels: [],
    values: [],
    datasets: [
        {
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderRadius: 5,
            borderWidth: 1,
            hoverOffset: 2,
            minBarLength: 8,
        },
    ],
};

/**
 * @param {WidgetProps} props - The props for the component. Should not be modified within the component.
 */
export function ExceptionFlagWidget(props: Readonly<WidgetProps>): JSX.Element {
    const styles = useLibraryStyles();
    const {
        widgetItem: data,
        dragging,
        onUpdateWidget,
        listeners,
        attributes,
    } = props;

    const [labelIndex, setLabelIndex] = useState<number>(0);
    const [activeTablePage, setActiveTablePage] = useState<number>(1);
    const userIds = useMemo(() => {
        if (data.config.view === 'Just Me') {
            return [data.accountid];
        } else if (data.config.view === 'All') {
            return null;
        } else {
            return data.config.user.map(record => Number(record.value));
        }
    }, [data?.config?.view, data.config?.user, data.accountid]);

    // Main filter for count and work order api calls
    const workOrderFilters: ExceptionFlagFilters | {} = {
        page: activeTablePage - 1,
        noOfDocumentsToShow: data?.config?.noOfDocumentsToShow || 5,
        exceptionFlags: data?.config?.exceptionFlags?.map(flag => flag.value),
        exceptionFlagBucket: data?.config?.exceptionFlagBucket?.value,
        userIds,
        workOrderUserType: data?.config?.workOrderUserType,
    };

    // Api call to get count data for chart/graph
    const response = useGetFilteredWorkOrderExceptionCountQuery(
        {
            ...workOrderFilters,
        },
        { refetchOnMountOrArgChange: true }
    );
    const GetFilteredWorkOrderExceptionCountData = response?.data?.result;
    const mappedWorkOrderCountData = useMemo(() => {
        if (GetFilteredWorkOrderExceptionCountData) {
            return _.flatten(
                GetFilteredWorkOrderExceptionCountData.map(record => {
                    return record.counts.map(record => ({
                        label: record.label,
                        value: record.label,
                        count: record.count,
                    }));
                })
            );
        }
        return [];
    }, [GetFilteredWorkOrderExceptionCountData]);

    const [noDataResults, setNoDataResults] = useState<boolean>(false);
    const [configRequired, setConfigRequired] = useState<boolean>(
        !data.config.initialConfig
    );
    const [displayTable, setDisplayTable] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [chartData, setChartData] = useState<ChartData>(defaultChartData);

    const [chartSelectedValue, setChartSelectedValue] = useState<
        EXCEPTION_FLAG | string
    >('');

    // Settings for chart/graph
    const doughnutOptions: ChartOptions<'doughnut'> = {
        ...(dragging && { animation: false }),
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 15,
                    font: {
                        weight: 'bold',
                    },
                    generateLabels: function (chart) {
                        const data = chart.data;
                        const chartData: number[] =
                            mappedWorkOrderCountData?.map(
                                record => record.count
                            ) || [];
                        const meta = chart.getDatasetMeta(0);

                        if (data?.labels?.length && data.datasets.length) {
                            return data.labels.map(function (label, i) {
                                const style = meta.controller.getStyle(i, true);
                                const tableColor: string =
                                    chartSelectedValue &&
                                    snakeCaseToTitleCase(chartSelectedValue) ===
                                        label
                                        ? 'black'
                                        : '#818181';

                                return {
                                    fontColor: !chartSelectedValue
                                        ? 'black'
                                        : tableColor,
                                    text: `${label} (${chartData[i]})`,
                                    fillStyle: style.backgroundColor,
                                    datasetIndex: i,
                                };
                            });
                        }
                        return [];
                    },
                },
            },
            tooltip: {
                bodyAlign: 'center',
                callbacks: {
                    label: function (tooltipItem) {
                        const chartData: number[] =
                            mappedWorkOrderCountData?.map(
                                record => record.count
                            ) || [];
                        const value: number = chartData[tooltipItem.dataIndex];
                        return `${value}`;
                    },
                },
            },
        },
        spacing: 3,
        cutout: '60%',
    };

    const barOptions: ChartOptions<'bar'> = {
        ...(dragging && { animation: false }),
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 15,
                    font: {
                        weight: 'bold',
                    },
                },
            },
            tooltip: {
                bodyAlign: 'center',
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
            },
        },
    };

    useEffect((): void => {
        if (
            !mappedWorkOrderCountData?.length ||
            !GetFilteredWorkOrderExceptionCountData?.length
        ) {
            setNoDataResults(true);
            return;
        }

        const chartData: number[] = mappedWorkOrderCountData.map(
            record => record.count
        );
        const total: number = chartData.reduce((a, v) => a + v, 0);
        const inPercent: number[] = chartData.map(v =>
            Math.max((v / total) * 100, 1)
        );

        const backgroundColors: string[] = [
            '#0e2248',
            '#667fae',
            '#2c6ee8',
            '#334669',
            '#57c0e8',
        ];

        setNoDataResults(false);
        let dataSets: DataSet[] = [];
        const values = _.uniq(
            mappedWorkOrderCountData?.map(record => record.value)
        );
        const configureStackedBarGraphData = (exception: string) => {
            let data: number[] = [];
            if (userIds && GetFilteredWorkOrderExceptionCountData) {
                for (const userId of userIds) {
                    const record = GetFilteredWorkOrderExceptionCountData.find(
                        r => r.user?.id === userId
                    );
                    const exceptionObject = record?.counts.find(
                        r => r.label === exception
                    );
                    if (exceptionObject) {
                        data.push(exceptionObject.count);
                    } else {
                        data.push(null as unknown as number);
                    }
                }
            }
            return data;
        };
        if (data.config.chartType === 'Bar Graph') {
            for (const key of values) {
                const _data = configureStackedBarGraphData(key);
                if (!_data.length) {
                    continue;
                }
                dataSets = [
                    ...dataSets,
                    {
                        label: snakeCaseToTitleCase(key),
                        data: _data,
                        backgroundColor: values
                            .filter(value => value === key)
                            .map(value =>
                                !chartSelectedValue ||
                                value === chartSelectedValue
                                    ? backgroundColors[values.indexOf(value)]
                                    : '#f5f5f5'
                            ),
                        borderColor: ['#ffffff'],
                        borderRadius: 5,
                        borderWidth: 3,
                        hoverOffset: 2,
                        minBarLength: 8,
                    },
                ];
            }
        } else if (data.config.chartType === 'Pie Chart') {
            dataSets = [
                {
                    data: inPercent,
                    backgroundColor: values.map((flag, index) =>
                        !chartSelectedValue || flag === chartSelectedValue
                            ? backgroundColors[index]
                            : '#f5f5f5'
                    ),
                    borderColor: ['#ffffff'],
                    borderRadius: 5,
                    borderWidth: 3,
                    hoverOffset: 2,
                    minBarLength: 8,
                },
            ];
        }

        setChartData({
            labels:
                data.config.chartType === 'Bar Graph'
                    ? GetFilteredWorkOrderExceptionCountData?.map(
                          record =>
                              record.user?.firstName +
                              ' ' +
                              record.user?.lastName
                      ) || []
                    : mappedWorkOrderCountData.map(record =>
                          snakeCaseToTitleCase(record.label)
                      ),
            values,
            datasets: dataSets,
        });
    }, [
        chartSelectedValue,
        GetFilteredWorkOrderExceptionCountData,
        data.config.chartType,
        mappedWorkOrderCountData,
        userIds,
    ]);
    useEffect((): void => {
        if (noDataResults) {
            setDisplayTable(false);
        }
    }, [noDataResults]);
    useEffect((): void => {
        if (attributes['aria-pressed']) {
            setChartSelectedValue('');
            setDisplayTable(false);
        }
    }, [attributes]);

    const handleCustomiseWidgetMenuClick = (): void => {
        setDrawerOpen(true);
        setDisplayTable(false);
        setChartSelectedValue('');
    };
    const handleDeleteWidgetMenuClick = (): void => {
        setDisplayTable(false);
        setChartSelectedValue('');

        props.onDelete(data.id);
    };

    return (
        <div
            className={displayTable ? styles.rootExpanded : styles.root}
            style={{ minHeight: 400 }}
        >
            {drawerOpen && (
                <CustomizeWidgetDrawer
                    widgetDetails={data}
                    handleClose={() => {
                        setDrawerOpen(false);
                    }}
                    handleSubmit={(widgetDetails: Widget) => {
                        if (!widgetDetails.config.initialConfig) {
                            widgetDetails.config.initialConfig = true;
                            setConfigRequired(false);
                        }

                        onUpdateWidget(widgetDetails);
                        setDrawerOpen(false);
                    }}
                />
            )}
            <div
                style={{
                    height:
                        data.config.chartType === 'Bar Graph' &&
                        !noDataResults &&
                        !drawerOpen
                            ? '300px'
                            : '100%',
                }}
            >
                <div className={styles.widgetHeader}>
                    <div
                        className={styles.draggableAreaStyle}
                        {...listeners}
                        {...attributes}
                    >
                        <DragIcon />
                        <div className={styles.widgetTitle}>
                            {data.config.name}
                        </div>
                    </div>
                    {!displayTable && (
                        <MenuButton
                            key={data.id}
                            handleCustomiseWidgetMenuClick={
                                handleCustomiseWidgetMenuClick
                            }
                            handleDeleteWidgetMenuClick={
                                handleDeleteWidgetMenuClick
                            }
                            widgetId={data.id.toString()}
                        />
                    )}
                </div>
                <div>
                    {!configRequired && !noDataResults && !drawerOpen ? (
                        <div className={styles.chartAndTooltipContainer}>
                            <ExceptionFlagDisplayChartType
                                displayType={data.config.chartType}
                                setChartSelectedValue={setChartSelectedValue}
                                chartSelectedValue={chartSelectedValue}
                                setDisplayTable={setDisplayTable}
                                setActiveTablePage={setActiveTablePage}
                                chartData={chartData}
                                barOptions={barOptions}
                                doughnutOptions={doughnutOptions}
                                labelIndex={labelIndex}
                                setLabelIndex={setLabelIndex}
                            />
                            <ToolTipQuestionMarkIcon
                                dataTestId={'EFWidgetTooltipButton'}
                                widgetId={data.id.toString()}
                            >
                                <>
                                    <strong>Tool Tip</strong>
                                    Select the Owner's exceptions on the chart
                                    to view the related Work Orders on a table.
                                    <br />
                                    <br />
                                    Reselect the Owner's exceptions to minimize
                                    the Work Order table.
                                </>
                            </ToolTipQuestionMarkIcon>
                        </div>
                    ) : (
                        <>
                            {configRequired && !drawerOpen && (
                                <div
                                    className={styles.configureViewLayout}
                                    onClick={() => {
                                        setDrawerOpen(true);
                                    }}
                                >
                                    <div className={styles.widgetStatusFont}>
                                        Customize to Configure View
                                    </div>
                                </div>
                            )}
                            {noDataResults &&
                                !configRequired &&
                                !drawerOpen && (
                                    <div className={styles.noContentLayout}>
                                        <div className={styles.alertIconStyle}>
                                            <div
                                                className={
                                                    styles.alertIconWrapper
                                                }
                                            >
                                                <AlertIcon />
                                            </div>
                                            <div
                                                className={
                                                    styles.widgetStatusFont
                                                }
                                            >
                                                No Records to Show!
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {drawerOpen && (
                                <div className={styles.noContentLayout}>
                                    <div className={styles.widgetStatusFont}>
                                        Configuring View
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {displayTable && chartSelectedValue && (
                <div className={styles.chartOpenContainer}>
                    <DataTableHeader
                        chartSelectedValue={chartSelectedValue}
                        handleCustomiseWidgetMenuClick={
                            handleCustomiseWidgetMenuClick
                        }
                        handleDeleteWidgetMenuClick={
                            handleDeleteWidgetMenuClick
                        }
                        widgetDetails={data}
                    />
                    <div style={{ paddingTop: '20px' }}>
                        <ExceptionFlagTable
                            originalWorkOrderCountData={
                                GetFilteredWorkOrderExceptionCountData
                            }
                            labelIndex={labelIndex}
                            displayType={data.config.chartType}
                            userIds={(userIds && [userIds[labelIndex]]) ?? []}
                            mappedWorkOrderCountData={mappedWorkOrderCountData}
                            activeTablePage={activeTablePage}
                            workOrderFilters={workOrderFilters}
                            chartSelectedValue={
                                chartSelectedValue as EXCEPTION_FLAG
                            }
                            recordsPerPage={data.config.noOfDocumentsToShow}
                            onPageClick={pageNumber => {
                                setActiveTablePage(pageNumber);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
