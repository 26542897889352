import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { MicroFrontendConstants } from 'common/Constants';
import { getUserPreferenceApi } from 'widgets/store/api/UserPreferenceApi';
import { getWidgetsApi } from 'widgets/store/api/DashboardWidgetsApi';
import { getKeyWorkOrderStatusesWidgetApi } from 'widgets/store/api/KeyWorkOrderStatusesWidgetApi';
import { WidgetsWrapper } from './WidgetsWrapper';
import { useAppDispatch } from 'widgets/store/WidgetsStore';

export default function WidgetsMF() {
    const dispatch = useAppDispatch();

    const eventHandler = () => {
        dispatch(getUserPreferenceApi.util.resetApiState());
        dispatch(getWidgetsApi.util.resetApiState());
        dispatch(getKeyWorkOrderStatusesWidgetApi.util.resetApiState());
    };

    useEffect(() => {
        document.addEventListener(
            MicroFrontendConstants.OnParentLogout,
            eventHandler,
            false
        );
        WebFont.load({
            google: {
                families: ['Nunito Sans:300,400,500,600,700'],
            },
        });

        return () => {
            document.removeEventListener(
                MicroFrontendConstants.OnParentLogout,
                eventHandler
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return <WidgetsWrapper />;
}
