import { LAYOUT } from 'common/Constants';
import _, { cloneDeep } from 'lodash';
import { Widget, WidgetDefinition } from 'widgets/models/WidgetModels';

export const DEFAULT_COL_FOR_WIDGETS = 12;
export const filterShowableWidgetsBasedOnUserPreference = (
    widgetsListData: Widget[]
) => {
    const widgetsArr = cloneDeep(widgetsListData);
    _.remove(
        widgetsArr,
        (widgetItem: Widget) => widgetItem.widgets.key === LAYOUT
    );
    return widgetsArr;
};

export const filterShowableWidgets = (widgetsListData: WidgetDefinition[]) => {
    const widgetsArr = cloneDeep(widgetsListData);
    _.remove(
        widgetsArr,
        (widgetItem: WidgetDefinition) => widgetItem.key === LAYOUT
    );
    return widgetsArr;
};

export const snakeCaseToTitleCase = data => {
    const isAllUppercase: boolean = data === data.toUpperCase();

    const labels = isAllUppercase ? data.toLowerCase().split('_') : data.split('_');

    const convertedWords = labels.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return convertedWords.join(' ');
};

export const shortenedAddress = data => {
    const longAddress = data.split(',');
    return longAddress[0];
};
