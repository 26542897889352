import React, { useEffect, useState } from 'react';
import {Widget, BaseUrl } from '../../models/WidgetModels';
import {
    useGetWorkOrderTypesQuery,
    useGetWorkOrderStatusesQuery,
    useGetUsersQuery,
    useGetContractorsQuery,
} from '../../store/api/KeyWorkOrderStatusesWidgetApi';
import { DropDownSelect, ButtonPrimary, ButtonSecondary, RadioGroup } from 'motili-ui';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import { DrawerHeadingAndTitle } from '../../components/drawer/DrawerHeadingAndTitle';
import { DrawerClientDropdown } from '../../components/drawer/drawerClientAndChildDropdown/DrawerClientDropdown';
import { DrawerDateRangeDropdown } from '../../components/drawer/DrawerDateRangeDropdown';
import { DrawerStateDropdown } from '../../components/drawer/DrawerStateDropdown';
import { DrawerContainer } from '../../components/drawer/DrawerContainer';
import { ToggleButtonGroup } from '../../components/toggleButtonGroup/ToggleButtonGroup';
import { ToggleIconButtonGroup } from '../../components/toggleIconButtonGroup/ToggleIconButtonGroup';
import useDrawerStyles from '../../components/drawer/DrawerStyles';

interface CustomizeWidgetDrawerProps {
    widgetDetails: Widget;
    handleSubmit: (widgetDetails: Widget) => void;
    handleClose: () => void;
}

export function CustomizeWidgetDrawer(
    props: CustomizeWidgetDrawerProps
): JSX.Element {
    const styles = useDrawerStyles();
    const {
        widgetDetails: initialWidgetDetails,
        handleSubmit,
        handleClose,
    } = props;

    const [widgetData, setWidgetData] = useState(initialWidgetDetails);
    const [userOpen, setUserOpen] = useState<boolean>(
        widgetData.config.view === 'Other'
    );
    const [statusErrorMessage, setStatusErrorMessage] =
        useState<boolean>(false);
    const [maxStatusErrorMessage, setMaxStatusErrorMessage] =
        useState<boolean>(false);

    // Api calls to gather all dropdown data
    const { data: getWorkOrderTypesData } = useGetWorkOrderTypesQuery(null);
    const { data: getWorkOrderStatusesData } =
        useGetWorkOrderStatusesQuery(null);
    const { data: getUserData } = useGetUsersQuery(null);
    const { data: getContractorData } = useGetContractorsQuery(null);

    // Structuring api data to input needed format/values
    const workOrderTypeDropdownOptions: { label: string; value: string }[] =
        getWorkOrderTypesData?.map(status => {
            return {
                label: snakeCaseToTitleCase(status.workOrderTypeId),
                value: status.workOrderTypeId,
            };
        }) || [];
    const workOrderStatusDropdownOptions: {
        label: string;
        value: string;
    }[] =
        getWorkOrderStatusesData?.map(status => {
            return {
                label:
                    status === 'DRAFTED'
                        ? 'Waiting for Approval'
                        : snakeCaseToTitleCase(status),
                value: status,
            };
        }) || [];
    const userDropdownOptions: { label: string; value: string }[] =
        getUserData?.map(elem => {
            return {
                label: `${elem.firstName} ${elem.lastName}`,
                value: elem.id,
            };
        }) || [];
    const contractorDropdownOptions: { label: string; value: number }[] =
        getContractorData?.map(elem => {
            return {
                label: elem.name,
                value: elem.id,
            };
        }) || [];

    useEffect((): void => {
        setStatusErrorMessage(widgetData.config.workOrderStatuses.length === 0);
        if (widgetData.config.workOrderStatuses.length < 5) {
            setMaxStatusErrorMessage(false);
        }
    }, [widgetData.config.workOrderStatuses]);
    useEffect((): void => {
        const clearUserDropdown = (): void => {
            setUserOpen(widgetData.config.view === 'Other');

            if (widgetData.config.view !== 'Other') {
                setWidgetData(prevWidgetData => ({
                    ...prevWidgetData,
                    config: {
                        ...prevWidgetData.config,
                        user: [],
                    },
                }));
            }
        };

        clearUserDropdown();
    }, [widgetData.config.view, setWidgetData]);

    const handleButtonSelect = (event): void => {
        const value: string | number = event.target.value
            ? event.target.value
            : event.target.id;
        const valueType: string = event.target.getAttribute('value-type');
        const type: string = event.target.getAttribute('data-type-id');

        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: valueType === 'number' ? Number(value) : value,
            },
        });
    };
    const handleDropdownSelect = (type: string, value: []): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: value,
            },
        });
    };
    const handleRadioSelect = (type: string, value: string): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: value,
            },
        });
    };
    const workOrderStatusOnChange = (selectedStatus: []): void => {
        if (selectedStatus.length > 5) {
            setMaxStatusErrorMessage(true);
        } else {
            handleDropdownSelect('workOrderStatuses', selectedStatus);
        }
    };

    return (
        <DrawerContainer handleClose={handleClose}>
            <>
                {/*widget title*/}
                <DrawerHeadingAndTitle
                    widgetData={widgetData}
                    setWidgetData={setWidgetData}
                    dataTestId={'KWOSWidgetTitleInput'}
                />

                <div className={styles.filterContainer}>
                    {/*/!*chart type*!/*/}
                    <div>
                        <div className={styles.dataVisualTypeRow}>
                            <h4>Data Visual Type</h4>
                        </div>
                        <div>
                            <ToggleIconButtonGroup
                                options={[
                                    {
                                        id: 'PieChartButton',
                                        img: `${BaseUrl.widgetImage}/donut-icon.svg`,
                                        value: 'Pie Chart',
                                        dataTestId: 'KWOSPieChartButton',
                                    },
                                    {
                                        id: 'BarGraphButton',
                                        img: `${BaseUrl.widgetImage}/bar-chart-icon.svg`,
                                        value: 'Bar Graph',
                                        dataTestId: 'KWOSBarGraphButton',
                                    },
                                ]}
                                type={'chartType'}
                                selectedOption={widgetData.config.chartType}
                                handleButtonSelect={handleButtonSelect}
                            />
                        </div>
                    </div>

                    {/*/!*per page*!/*/}
                    <div className={styles.marginTop20}>
                        <h4>Select the Number of Records Per Page</h4>
                    </div>
                    <div>
                        <ToggleButtonGroup
                            options={[
                                {
                                    id: 'fiveButton',
                                    text: '5',
                                    value: 5,
                                    dataTestId: 'KWOSWidgetFivePerPageButton',
                                },
                                {
                                    id: 'tenButton',
                                    text: '10',
                                    value: 10,
                                    dataTestId: 'KWOSWidgetTenPerPageButton',
                                },
                            ]}
                            type={'noOfDocumentsToShow'}
                            selectedOption={
                                widgetData.config.noOfDocumentsToShow
                            }
                            handleButtonSelect={handleButtonSelect}
                        />
                    </div>

                    {/*/!*filter heading*!/*/}
                    <div className={styles.marginTop20}>
                        <h4>Filter</h4>
                    </div>

                    {/*/!*filter type clients or contractors*!/*/}
                    <div style={{ height: 20}} />
                    <div className={styles.radioInputRow}>
                        <RadioGroup 
                            options={[{
                                label: 'Client',
                                value: 'client',
                                checked: widgetData.config.workOrderDataType === 'client'
                            }]}
                            onChange={e => {
                                handleRadioSelect(
                                    'workOrderDataType',
                                    e.target.value
                                );
                            }}
                            label={''}
                            name={'client-data-type-radio'}
                            className={'clientRadio'}
                        />
                        <RadioGroup 
                            options={[{
                                label: 'Contractor',
                                value: 'contractor',
                                checked: widgetData.config.workOrderDataType === 'contractor'
                            },]}
                            onChange={e => {
                                handleRadioSelect(
                                    'workOrderDataType',
                                    e.target.value
                                );
                            }}
                            label={''}
                            name={'contractor-data-type-radio'}
                            className={'contractorRadio'}
                        />
                    </div>

                    {widgetData.config.workOrderDataType ===
                        'contractor' && (
                        <div
                            className={styles.marginTop20}
                            style={{ width: '263px' }}
                        >
                            <DropDownSelect
                                id={'KWOSWidgetWorkOrderContractorDropdown'}
                                isMulti={true}
                                onChange={value => {
                                    handleDropdownSelect(
                                        'contractors',
                                        value
                                    );
                                }}
                                name={'work-order-contractor-select'}
                                label={'Contractor(s)'}
                                /*@ts-ignore*/
                                options={contractorDropdownOptions}
                                /*@ts-ignore*/
                                value={widgetData.config.contractors}
                            />
                        </div>
                    )}

                    {widgetData.config.workOrderDataType ===
                        'client' && (
                            <div className={styles.marginTop20}>
                                <DrawerClientDropdown
                                    widgetData={widgetData}
                                    setWidgetData={setWidgetData}
                                />
                            </div>
                    )}

                    {/*/!*work order statuses & work order type *!/*/}
                    <div className={styles.multipleInputRow}>
                        <div className={styles.dropdownSelectWidth}>
                            <DropDownSelect
                                id={'KWOSWidgetWorkOrderStatusDropdown'}
                                required={true}
                                isMulti={true}
                                onChange={selectedStatus => {
                                    workOrderStatusOnChange(selectedStatus);
                                }}
                                name={'work-order-status-select'}
                                label={'Work Order Statuses (Limit up to 5)'}
                                options={workOrderStatusDropdownOptions}
                                value={widgetData.config.workOrderStatuses}
                            />
                            {statusErrorMessage && (
                                <div className={styles.errorMessageTextColor}>
                                    Work order status is required
                                </div>
                            )}
                            {maxStatusErrorMessage && (
                                <div className={styles.errorMessageTextColor}>
                                    Status limit reached
                                </div>
                            )}
                        </div>
                        <div className={styles.dropdownSelectWidth}>
                            <DropDownSelect
                                id={'KWOSWidgetWorkOrderTypeDropdown'}
                                isMulti={true}
                                onChange={value => {
                                    handleDropdownSelect(
                                        'workOrderTypes',
                                        value
                                    );
                                }}
                                name={'work-order-type-select'}
                                label={'Work Order Type'}
                                options={workOrderTypeDropdownOptions}
                                value={widgetData.config.workOrderTypes}
                            />
                        </div>
                    </div>

                    {/*/!*date range type & date range from*!/*/}
                    <DrawerDateRangeDropdown
                        widgetData={widgetData}
                        setWidgetData={setWidgetData}
                        id={'KWOSDrawerDateRangeDropdown'}
                    />

                    {/*/!*state*!/*/}
                    <DrawerStateDropdown
                        widgetData={widgetData}
                        setWidgetData={setWidgetData}
                        id={'KWOSStateSelectDropdown'}
                    />

                    {/*/!*View*!/*/}
                    <div className={styles.marginTop20}>
                        <h6 className={styles.boldText}>View</h6>
                    </div>
                    <div>
                        <ToggleButtonGroup
                            options={[
                                {
                                    id: 'JustMeButton',
                                    text: 'Just Me',
                                    value: 'Just Me',
                                    dataTestId: 'KWOSWidgetJustMeButton',
                                },
                                {
                                    id: 'OtherButton',
                                    text: 'Other',
                                    value: 'Other',
                                    dataTestId: 'KWOSWidgetOtherButton',
                                },
                                {
                                    id: 'AllViewButton',
                                    text: 'All',
                                    value: 'All',
                                    dataTestId: 'KWOSWidgetViewAllButton',
                                },
                            ]}
                            type={'view'}
                            selectedOption={widgetData.config.view}
                            handleButtonSelect={handleButtonSelect}
                        />
                    </div>

                    {/*/!*user*!/*/}
                    {userOpen && (
                        <div className={styles.singleDropdownSelectRow}>
                            <DropDownSelect
                                id={'KWOSWidgetUserDropdown'}
                                required={true}
                                isMulti={true}
                                onChange={value => {
                                    handleDropdownSelect('user', value);
                                }}
                                name={'user-select'}
                                label={'User'}
                                options={userDropdownOptions}
                                value={widgetData.config.user}
                            />
                        </div>
                    )}

                    {/*/!*Resident Status*!/*/}
                    <div className={styles.marginTop20}>
                        <ToggleButtonGroup
                            options={[
                                {
                                    id: 'UnoccupiedButton',
                                    text: 'Unoccupied',
                                    value: 'Unoccupied',
                                    dataTestId: 'KWOSWidgetUnoccupiedButton',
                                },
                                {
                                    id: 'OccupiedButton',
                                    text: 'Occupied',
                                    value: 'Occupied',
                                    dataTestId: 'KWOSWidgetOccupiedButton',
                                },
                                {
                                    id: 'AllResidentStatusButton',
                                    text: 'All',
                                    value: 'All',
                                    dataTestId:
                                        'KWOSWidgetResidentStatusAllButton',
                                },
                            ]}
                            type={'residentStatus'}
                            selectedOption={widgetData.config.residentStatus}
                            handleButtonSelect={handleButtonSelect}
                        />
                    </div>
                </div>

                <div className={styles.confirmCancelContainer}>
                    <div className={styles.cancelButtonRightPadding}>
                        <ButtonSecondary onClick={handleClose}>
                            Cancel
                        </ButtonSecondary>
                    </div>
                    <div>
                        <ButtonPrimary
                            disabled={
                                statusErrorMessage ||
                                (userOpen &&
                                    widgetData.config.user.length === 0) ||
                                widgetData.config.name.length === 0
                            }
                            onClick={() => {
                                handleSubmit(widgetData);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </div>
                </div>
            </>
        </DrawerContainer>
    );
}
