import { createUseStyles } from 'react-jss';

const useToolTipQuestionMarkStyles = createUseStyles({
    toolTipOpenContainer: {
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start',
        padding: '15px',
        width: '275px',
    },
    button: {
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: '0',
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
    },
});

export default useToolTipQuestionMarkStyles;
