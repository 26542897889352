import React, { useEffect } from 'react';
import Calender from '../common/icons/Calender';
import Clock from '../common/icons/Clock';
import OverflowIcon from '../common/icons/OverflowIcon';
import LetteredAvatar from 'react-avatar';
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Theme,
    useTheme,
    Popover,
} from '@mui/material';
import { SXCssObject } from './atoms/StyleModel';
import ShowAllComments from '../common/icons/ShowAllComments';
import MarkAsRead from '../common/icons/MarkAsRead';
import { getColorBasedOnDepartment, isLoggedIn } from '../utils/utils';
import { useUpdateReadStatusMutation } from './store/api/MarkAsRead';
import {
    CommentDetails,
    CommentsResponse,
    CommentsScenario,
    CommentType,
} from './models/CommentsModel';
import {
    getCommentsApi,
    useGetCommentsQuery,
    useGetSelectiveFeedsQuery,
} from './store/api/FeedsApi';
import { setUnreadMessagesCount } from './store/api/AppConfigSlice';
import { getUnReadCount } from '../common/store/api/HttpObjectConfig';
import { CommentsFilterOptions, ReduxConstants } from '../common/Constants';
import { cloneDeep, get } from 'lodash';
import AlertHandler from '../common/AlertHandler';
import { ErrorMessages } from './ErrorMessages';
import { clearDocumentComments } from './store/api/ShowAllComments';
import { useAppDispatch } from 'comments/store/CommentsStore';

const createCssObject = (theme: Theme): SXCssObject => ({
    wrapper: {
        marginBottom: '11px',
        background: ' #F6F9FC 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
    },
    woContainer: {
        fontSize: '14px',
        color: '#17C2ED',
        display: 'flex',
        justifyContent: 'left',
        padding: '10px',
    },
    userDetailsSection: {
        p: '8px 8px 0px',
        borderRadius: '4px',
    },
    hightlightComment: {
        backgroundColor: '#F6F9FC',
    },
    readIconSection: {
        width: '12px',
        marginRight: '12px',
    },
    userDetailsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dotsWrapper: {
        marginRight: '12px',
        display: 'flex',
        alignItems: 'center',
        width: '6px',
    },
    dots: {
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#17C2ED',
    },
    userDetailsWrapper: {
        font: '14px',
        color: '#797F8D',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginLeft: 1,
    },
    userNameSection: {
        marginRight: '8px',
    },
    overflowSection: {
        height: '24px',
        padding: '0px 5px',
        backgroundColor: '#F6F9FC',
        borderRadius: '4px',
        cursor: 'pointer',
        position: 'relative',
    },
    overflowIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    menuPaperStyle: {
        marginRight: '-10px',
        backgroundColor: theme.palette.primary.dark,
        overflow: 'visible',
        borderRadius: '6px',
        maxWidth: '300px',
        position: 'relative',
        marginTop: '12px',
        boxShadow: '0px 5px 31px #0000004F',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: '17px',
            width: '12px',
            height: '12px',
            backgroundColor: theme.palette.primary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: '3px',
        },
    },
    menuItemStyle: {
        display: 'flex',
        alignItems: 'center',
        zIndex: 4,
        width: '240px',
        p: '8px',
        mx: '8px',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    menuItemIconStyle: {
        paddingRight: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    menuItemTextStyle: {
        color: theme.palette.text.secondary,
    },
    department: {
        fontSize: '12px',
        fontWeight: 500,
        borderRadius: '4px',
        width: 'fit-content',
        height: '24px',
        padding: '5px 8px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4px',
    },
    additionalDepartmentsButtonStyle: {
        cursor: 'pointer',
        color: '#17C2ED',
        backgroundColor: '#F6F9FC',
        padding: '6px',
        height: '24px',
        borderRadius: '4px',
        marginLeft: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        opacity: '0.7',
    },
    tooltipPaperStyle: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        top: '4px',
        marginBottom: '4px',
        position: 'relative',
    },
    additionalDepartmentsPaperStyle: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '7px',
        maxWidth: '170px',
        minWidth: '120px',
        whiteSpace: 'nowrap',
        padding: '6px',
        zIndex: 2,
        position: 'relative',
        boxShadow: '0px 3px 6px #0E131521',
    },
    additionalDepartmentWrapper: {
        fontSize: '12px',
        '&:not(:last-child)': {
            marginBottom: '5px',
        },
    },
    additionalDepartmentSpan: {
        borderRadius: '4px',
        padding: '6px 8px',
        display: 'inline-block',
    },
    commentsSection: {
        fontSize: '14px',
        color: '#5B657B',
        display: 'flex',
        justifyContent: 'left',
        paddingLeft: '55px',
        paddingRight: '50px',
        cursor: 'pointer',
    },
    collapsedComment: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        transition: 'transform .2s',
        wordBreak: 'break-word',
    },
    fullCommentMessage: {
        wordBreak: 'break-word',
    },
    expandCollapseComments: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '12px',
        marginLeft: '55px',
    },
    commentsTimeSection: {
        display: 'flex',
        fontSize: '12px',
        color: '#AAAFBA',
        marginLeft: '10px',
    },
    showAllLabel: {
        marginLeft: '5px',
        color: '#5B657B',
        fontSize: '12px',
    },
    timeSection: {
        paddingLeft: '14px',
        display: 'flex',
        alignItems: 'center',
    },
});

const NO_OF_DEPTS_VISIBLE = 2;
interface Props {
    highlightableComment?: string;
    commentMessage: CommentDetails;
    isExpandView?: boolean;
    expandComments?: (commentId?: string) => void;
    onCommentClick?: () => void;
    fetchScenario: CommentsScenario;
}
export const CommentLayout = React.forwardRef(
    (props: Props, documentRef: any) => {
        const {
            fetchScenario,
            commentMessage,
            isExpandView,
            onCommentClick,
            expandComments,
            highlightableComment = undefined,
        } = props;
        const theme = useTheme();
        const timeStampToShow =
            commentMessage?.updatedTs || commentMessage?.createdTs;
        const shouldHighlight =
            highlightableComment &&
            highlightableComment === commentMessage?.commentId
                ? true
                : false;
        const currentMessageDivId = `comment-${commentMessage.documentNumber}-${commentMessage.commentId}`;

        const { data: selectiveFeeds } = useGetSelectiveFeedsQuery(
            fetchScenario,
            { skip: !isLoggedIn() }
        );
        const { data: allCommentsData } = useGetCommentsQuery(
            CommentsFilterOptions.Allcomments,
            { skip: !isLoggedIn() }
        );
        const [
            additionalDepartmentsMenuOpen,
            setAdditionalDepartmentsMenuOpen,
        ] = React.useState(false);

        const popperStyle: React.CSSProperties = {
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: '0px',
            paddingTop: '3px',
            paddingBottom: '3px',
            zIndex: 3,
        };

        const cssObject = createCssObject(theme);
        const anchorRef = React.useRef<HTMLDivElement>(null);
        const departmentsRef = React.useRef<HTMLDivElement>(null);

        const [commentClicked, setCommentClicked] = React.useState(false);
        const [isOptionsOpen, setOptionsVisibility] = React.useState(false);
        const [isAllCommentsLoading, setAllCommentsLoading] =
            React.useState(false);
        var regex = /(@+[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,3})/gi;
        const [updateReadStatus] = useUpdateReadStatusMutation();
        const isUnreadFilterApplied =
            fetchScenario.scenario === CommentsFilterOptions.Unreadcomments;
        const commentsData = isUnreadFilterApplied
            ? selectiveFeeds
            : allCommentsData;
        const commentFromFeed = commentsData?.Items?.find(
            comment => comment.documentNumber === commentMessage.documentNumber
        );

        const isUnRead = commentFromFeed?.unreadObjectIds?.some(
            unreads => unreads.latestUnreadObjectId === commentMessage.objectId
        );
        const dispatch = useAppDispatch();
        //Unread/read menu is only for Comments present in notifications API and not for comments present only in local/document.
        const shouldShowMenu =
            !commentMessage.commentType ||
            commentMessage.commentType ===
                CommentType.COMMENT_FROM_NOTIFICATION;

        const handleOptionsMenuClick = () => {
            setOptionsVisibility(!isOptionsOpen);
        };

        const handleOptionsMenuClose = () => {
            setOptionsVisibility(false);
        };

        useEffect(() => {
            if (shouldHighlight) {
                try {
                    // This puts the document to the top - Higher level scroll
                    if (documentRef && documentRef.current) {
                        documentRef.current.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                    //This operation within setTimeout scroll the individual comment message(on which 'Show all Comment' was clicked) to top - Inner scroll
                    setTimeout(() => {
                        // element within Inner scroll - The individual comment message which should be highlighted in blue and scrolled to top.
                        const commentMsgToScrollToTop =
                            document.getElementById(currentMessageDivId);
                        const offSetToScroll =
                            commentMsgToScrollToTop?.offsetTop;

                        //Container layout of commentMsgToScrollToTop
                        const parentElement = document.getElementById(
                            `feed-item${commentMessage.documentNumber}`
                        );
                        const parentOffSet = parentElement?.offsetTop;
                        if (parentOffSet && offSetToScroll) {
                            parentElement.scrollTop =
                                offSetToScroll - parentOffSet;
                        }
                    }, 400);
                } catch (e) {}
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [shouldHighlight]);

        const markCommentAsUnreadInRedux = (commentsList: CommentsResponse) => {
            commentsList.Items.forEach(comment => {
                if (comment.documentNumber === commentMessage.documentNumber) {
                    const existingUnreadObjectIds =
                        comment?.unreadObjectIds || [];
                    comment.unreadObjectIds = [
                        ...existingUnreadObjectIds,
                        {
                            latestUnreadObjectId: commentMessage.objectId!,
                        },
                    ];
                }
            });
            return commentsList;
        };

        /**
         *
         * @param commentsList  - Data source to modify. Either Commentslist from SelectiveFeeds Query or AllFeeds RTK query
         * To mark a message as Read, remove the objectId of the individual message from the unreadObjectIds array in data source
         * @returns The updated commentsList
         */
        const markCommentAsReadInRedux = (
            commentsDataInRedux: CommentsResponse
        ) => {
            let commentsList = cloneDeep(commentsDataInRedux);
            commentsList.Items.forEach(comment => {
                if (
                    comment.documentNumber === commentMessage.documentNumber &&
                    comment.unreadObjectIds &&
                    comment.unreadObjectIds.length >= 1
                ) {
                    comment.unreadObjectIds = comment.unreadObjectIds.filter(
                        msg => {
                            return !(
                                msg.latestUnreadObjectId ===
                                commentMessage.objectId
                            );
                        }
                    );
                }
            });
            //For unread page: When there is only one msg in a doc that is unread and if it is marked as read, then delete that item in comments array stored in redux
            if (
                fetchScenario.scenario === CommentsFilterOptions.Unreadcomments
            ) {
                commentsList.Items = commentsList.Items.filter(
                    comment =>
                        comment.unreadObjectIds &&
                        comment.unreadObjectIds.length >= 1
                );
                return commentsList;
            }
            return commentsList;
        };

        /**
         * Update API.
         * On Success, Take the ObjectId of the comment message for which read status is updated. Add it to the array in feed(Selective feed/AllFeed)
         *
         */
        const handleMarkAsUnread = () => {
            updateReadStatus([
                {
                    objectId: commentMessage.objectId!,
                    isRead: false,
                    documentId: commentMessage.documentId,
                },
            ])
                .unwrap()
                .then(res => {
                    if (res) {
                        const commentInFeed = commentsData!.Items.find(
                            comment =>
                                comment.documentNumber ===
                                commentMessage.documentNumber
                        );
                        if (!commentInFeed) return;
                        const dispatchEvent =
                            getCommentsApi.util.updateQueryData(
                                isUnreadFilterApplied
                                    ? ReduxConstants.GetSelectiveFeeds
                                    : ReduxConstants.GetComments,
                                isUnreadFilterApplied
                                    ? fetchScenario
                                    : CommentsFilterOptions.Allcomments,
                                (commentsList: CommentsResponse) => {
                                    return markCommentAsUnreadInRedux(
                                        commentsList
                                    );
                                }
                            );
                        dispatch(dispatchEvent);
                    }
                })
                .then(async () => {
                    try {
                        const res = await getUnReadCount();
                        dispatch(setUnreadMessagesCount(res.data));
                    } catch (e) {}
                })
                .catch(() => {
                    AlertHandler.error(
                        ErrorMessages.UnableToChangeMessageStatus
                    );
                });
        };

        /**
         * Update API.
         * On Success, Take the ObjectId of the comment message for which read status is updated. Remove it from the array in feed(Selective feed/AllFeed) depending on scenario
         *
         */
        const handleMarkAsRead = () => {
            updateReadStatus([
                {
                    objectId: commentMessage.objectId!,
                    isRead: true,
                    documentId: commentMessage.documentId,
                },
            ])
                .unwrap()
                .then(res => {
                    if (res) {
                        const isCommentExistInFeed = commentsData!.Items.find(
                            comment =>
                                comment.documentNumber ===
                                commentMessage.documentNumber
                        );
                        if (!isCommentExistInFeed) return;
                        const isUnreadScenario =
                            fetchScenario.scenario ===
                            CommentsFilterOptions.Unreadcomments;
                        const dispatchEvent =
                            getCommentsApi.util.updateQueryData(
                                isUnreadScenario
                                    ? ReduxConstants.GetSelectiveFeeds
                                    : ReduxConstants.GetComments,
                                isUnreadScenario
                                    ? fetchScenario
                                    : CommentsFilterOptions.Allcomments,
                                (commentsList: CommentsResponse) => {
                                    return markCommentAsReadInRedux(
                                        commentsList
                                    );
                                }
                            );
                        dispatch(dispatchEvent);
                    }
                })
                .then(async () => {
                    try {
                        const res = await getUnReadCount();
                        dispatch(setUnreadMessagesCount(res.data));
                    } catch (e) {}
                })
                .catch(() => {
                    AlertHandler.error(
                        ErrorMessages.UnableToChangeMessageStatus
                    );
                });
        };

        function convertStringToEmail(str) {
            str = str.replace(regex, (emailWithPrefix: string) => {
                const email = emailWithPrefix.substring(1);
                return `<a href="mailto:${email}">${emailWithPrefix}</a>`;
            });
            return str;
        }

        let commentedUserDepartments = get(
            commentMessage,
            'commentedUserDepartments',
            []
        );
        commentedUserDepartments = commentedUserDepartments || [];
        const numberOfDepartments = commentedUserDepartments.length;

        const initialDepartmentsToShow =
            numberOfDepartments >= NO_OF_DEPTS_VISIBLE + 1
                ? commentedUserDepartments.slice(0, NO_OF_DEPTS_VISIBLE)
                : commentedUserDepartments;

        const additionalDepartmentsToShow =
            numberOfDepartments >= NO_OF_DEPTS_VISIBLE + 1
                ? commentedUserDepartments.slice(
                      NO_OF_DEPTS_VISIBLE,
                      numberOfDepartments
                  )
                : null;

        return (
            <Box id={currentMessageDivId} sx={{ paddingTop: '5px' }}>
                <Box
                    sx={[
                        cssObject.userDetailsSection,
                        shouldHighlight && cssObject.hightlightComment,
                    ]}
                >
                    <Box>
                        <Box sx={cssObject.userDetailsHeader}>
                            <Box style={{ display: 'flex' }}>
                                <Box sx={cssObject.dotsWrapper}>
                                    {/* Dots should be visible when comment is in Unread state */}
                                    {isUnRead && (
                                        <Box sx={cssObject.dots}></Box>
                                    )}
                                </Box>
                                <LetteredAvatar
                                    name={commentMessage.commentedUserName}
                                    size={'35'}
                                    round
                                    textSizeRatio={2}
                                />
                                <Box sx={cssObject.userDetailsWrapper}>
                                    <Box
                                        sx={[
                                            cssObject.userNameSection,
                                            isUnRead
                                                ? { color: '#0E2248' }
                                                : { color: '#797F8D' },
                                        ]}
                                    >
                                        {commentMessage.commentedUserName}
                                    </Box>
                                    {initialDepartmentsToShow?.map(
                                        (item, index) => {
                                            const departmentColor =
                                                getColorBasedOnDepartment(item);
                                            return (
                                                <Box
                                                    key={index}
                                                    component={'span'}
                                                    sx={[
                                                        cssObject.department,
                                                        {
                                                            color: departmentColor.color,
                                                            backgroundColor:
                                                                departmentColor.bgColor,
                                                        },
                                                    ]}
                                                >
                                                    {item}
                                                </Box>
                                            );
                                        }
                                    )}
                                    {additionalDepartmentsToShow && (
                                        <Box sx={{ position: 'relative' }}>
                                            <Box
                                                ref={departmentsRef}
                                                sx={
                                                    cssObject.additionalDepartmentsButtonStyle
                                                }
                                                onClick={() =>
                                                    setAdditionalDepartmentsMenuOpen(
                                                        true
                                                    )
                                                }
                                            >
                                                +{' '}
                                                {
                                                    additionalDepartmentsToShow.length
                                                }
                                            </Box>
                                            <Popover
                                                open={
                                                    additionalDepartmentsMenuOpen
                                                }
                                                anchorEl={
                                                    departmentsRef.current
                                                }
                                                style={{ marginTop: '5px' }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                onClose={() =>
                                                    setAdditionalDepartmentsMenuOpen(
                                                        false
                                                    )
                                                }
                                            >
                                                <Paper
                                                    sx={
                                                        cssObject.additionalDepartmentsPaperStyle
                                                    }
                                                >
                                                    {additionalDepartmentsToShow.map(
                                                        (department, index) => (
                                                            <Box
                                                                sx={
                                                                    cssObject.additionalDepartmentWrapper
                                                                }
                                                                key={index}
                                                            >
                                                                <Box
                                                                    sx={[
                                                                        {
                                                                            color: getColorBasedOnDepartment(
                                                                                department
                                                                            )
                                                                                .color,
                                                                            backgroundColor:
                                                                                getColorBasedOnDepartment(
                                                                                    department
                                                                                )
                                                                                    .bgColor,
                                                                        },
                                                                        cssObject.additionalDepartmentSpan,
                                                                    ]}
                                                                >
                                                                    {department}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    )}
                                                </Paper>
                                            </Popover>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {shouldShowMenu && (
                                <Box
                                    sx={cssObject.overflowSection}
                                    onClick={handleOptionsMenuClick}
                                >
                                    <Box
                                        ref={anchorRef}
                                        sx={cssObject.overflowIconWrapper}
                                    >
                                        <OverflowIcon height={16} width={16} />
                                    </Box>
                                    <Popper
                                        anchorEl={anchorRef.current}
                                        placement='bottom-start'
                                        open={isOptionsOpen}
                                        style={{ ...popperStyle }}
                                        transition
                                        disablePortal
                                    >
                                        <Paper sx={cssObject.menuPaperStyle}>
                                            <ClickAwayListener
                                                onClickAway={() => {
                                                    handleOptionsMenuClose();
                                                }}
                                            >
                                                <MenuList id='menu-list-quote'>
                                                    <MenuItem
                                                        sx={
                                                            cssObject.menuItemStyle
                                                        }
                                                        onClick={() => {
                                                            if (isUnRead) {
                                                                handleMarkAsRead();
                                                            } else {
                                                                handleMarkAsUnread();
                                                            }
                                                            handleOptionsMenuClose();
                                                        }}
                                                    >
                                                        <Box
                                                            component={'span'}
                                                            sx={
                                                                cssObject.menuItemIconStyle
                                                            }
                                                        >
                                                            <MarkAsRead
                                                                color={
                                                                    theme
                                                                        .palette
                                                                        .common
                                                                        .white
                                                                }
                                                            />
                                                        </Box>
                                                        <Box
                                                            component={'span'}
                                                            sx={
                                                                cssObject.menuItemTextStyle
                                                            }
                                                        >
                                                            {isUnRead
                                                                ? 'Mark as Read'
                                                                : 'Mark as Unread'}
                                                        </Box>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ mt: '12px', mb: '20px', marginLeft: 1 }}>
                                <Box
                                    sx={cssObject.commentsSection}
                                    onClick={() => {
                                        setCommentClicked(true);
                                        if (isUnRead) {
                                            handleMarkAsRead();
                                        }
                                        onCommentClick && onCommentClick();
                                    }}
                                >
                                    {
                                        <Box
                                            sx={[
                                                !commentClicked &&
                                                    cssObject.collapsedComment,
                                                commentClicked &&
                                                    cssObject.fullCommentMessage,
                                                isUnRead
                                                    ? { color: '#0E2248' }
                                                    : { color: '#797F8D' },
                                            ]}
                                            dangerouslySetInnerHTML={{
                                                __html: convertStringToEmail(
                                                    commentMessage.comment
                                                ),
                                            }}
                                        />
                                    }
                                </Box>
                            </Box>
                            <Box sx={cssObject.expandCollapseComments}>
                                <Box sx={cssObject.commentsTimeSection}>
                                    {timeStampToShow && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Calender color={'black'} />
                                                <Box
                                                    style={{
                                                        paddingLeft: '4px',
                                                    }}
                                                >
                                                    {new Intl.DateTimeFormat(
                                                        'en-US',
                                                        {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: '2-digit',
                                                        }
                                                    ).format(
                                                        new Date(
                                                            timeStampToShow
                                                        )
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box sx={cssObject.timeSection}>
                                                <Clock color={'black'} />
                                                <Box
                                                    style={{
                                                        paddingLeft: '4px',
                                                    }}
                                                >
                                                    {new Date(
                                                        timeStampToShow
                                                    ).toLocaleTimeString(
                                                        'en-US',
                                                        {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        }
                                                    )}
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                                {/* Show All Comments link will be hidden, for expanded view */}
                                {!isExpandView && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            color: '797F8D',
                                        }}
                                        onClick={async () => {
                                            dispatch(clearDocumentComments());
                                            setAllCommentsLoading(true);
                                            if (expandComments) {
                                                await expandComments(
                                                    commentMessage.commentId!
                                                );
                                            }
                                            setAllCommentsLoading(false);
                                        }}
                                    >
                                        {isAllCommentsLoading ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <ShowAllComments />
                                        )}
                                        <Box
                                            component={'span'}
                                            sx={cssObject.showAllLabel}
                                        >
                                            Show All Comments
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
);
