import { WorkOrderUserType } from './ExceptionFlagModel';

export enum BaseUrl {
    widgetImage = 'https://static.motili.com/images/widgets'
}

export interface Widget {
    accountid: number;
    widgetid: number;
    id: number;
    widgets: {
        name: string;
        key: string;
        id: number;
    };
    config: {
        initialConfig: boolean;
        column: number;
        noOfDocumentsToShow: number;
        name: string;
        order: number;
        chartType: string;
        workOrderStatuses: MultiDropdown[];
        clients: Client[];
        contractors: Contractor[];
        childClients: ChildClient[];
        dateRangeType: { label: string; value: string };
        dateRangeFrom: { label: string; value: number; date: string };
        workOrderTypes: MultiDropdown[];
        states: MultiDropdown[];
        view: string;
        user: MultiDropdown[];
        residentStatus: string;
        documentType: string;
        contractorStatuses: MultiDropdown[];
        contractorCompanyDocumentType: { label: string; value: string };
        credentialType: { label: string; value: string };
        exceptionFlags?: MultiDropdown[];
        exceptionFlagBucket?: MultiDropdown;
        workOrderUserType?: WorkOrderUserType | null;
        workOrderDataType: string;
    };
}

export interface WidgetDefinition {
    name: string;
    description: string;
    key: string;
    config?: {
        column: number;
        filters: string;
        noOfDocumentsToShow: number;
        name: string;
    };
    id: string;
}

export interface MultiDropdown {
    label: string;
    value: string;
}

export interface Client {
    label: string;
    value: number;
    option: boolean;
}

export interface Contractor {
    label: string;
    value: number;
}

export interface ChildClient {
    label: string;
    value: number;
    parentId: number | null;
}
export interface DataSet {
    label?: string;
    data: number[];
    backgroundColor: string[];
    hoverBackgroundColor?: string[];
    borderColor: string[];
    borderRadius: number;
    borderWidth: number;
    hoverOffset: number;
    minBarLength: number;
}
export interface ChartData {
    labels: string[];
    values: string[];
    datasets: DataSet[];
}

export enum DateRangeTypes {
    fromCreationDate = 'FROM_CREATION_DATE',
    // fromScheduledDate = "FROM_SCHEDULED_DATE",
}

export enum DateRangeFroms {
    '7 Days' = 7,
    '30 Days' = 30,
    '60 Days' = 60,
    '90 Days' = 90,
    '6 Months' = 180,
    '1 Year' = 365,
    '2 Years' = 730,
}

export interface TableHeaders {
    displayName: string;
    value: string;
    sortable: boolean;
    ascending: boolean;
}

export interface ClosedContainerProps {
    displayType: string;
    chartData: ChartData;
    chartSelectedValue: string;
    setChartSelectedValue: React.Dispatch<React.SetStateAction<string>>;
    setDisplayTable: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveTablePage: React.Dispatch<React.SetStateAction<number>>;
    barOptions: object;
    doughnutOptions: object;
}
