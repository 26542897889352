import * as React from 'react';
import { SVGProps } from 'react';

const ShowAllComments = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 123 \u2013 26'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <g data-name='Group 4704'>
            <g data-name='Group 4703' fill='#17c2ed'>
                <path
                    data-name='Path 2446'
                    d='M7.913.711A7.608 7.608 0 1 1 .298 8.299 7.6 7.6 0 0 1 7.913.711Zm-6.1 7.6a6.091 6.091 0 1 0 6.111-6.083 6.1 6.1 0 0 0-6.109 6.081Z'
                />
                <path
                    data-name='Path 2447'
                    d='M7.147 8.731V6.053a.758.758 0 1 1 1.515.028V8.747a1.541 1.541 0 0 0 .122-.1c.277-.277.552-.558.83-.834a.764.764 0 1 1 1.076 1.082q-1.1 1.106-2.206 2.206a.774.774 0 0 1-1.157 0Q6.214 9.995 5.108 8.882a.764.764 0 1 1 1.078-1.079c.3.3.6.606.907.908a.252.252 0 0 0 .054.02Z'
                />
            </g>
        </g>
    </svg>
);

export default ShowAllComments;
