import { createUseStyles } from 'react-jss';

const useFullscreenModalStyles = createUseStyles({
    fullscreenModal: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '75px',
        backgroundColor: '#f7f7f7',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        zIndex: 49,
    },
    closeButton: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
});

export default useFullscreenModalStyles;
