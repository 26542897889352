import React, { useEffect, useState } from 'react';
import { Widget } from '../../models/WidgetModels';
import { useGetUsersQuery } from '../../store/api/KeyWorkOrderStatusesWidgetApi';
import {
    DropDownSelect,
    ButtonPrimary,
    ButtonSecondary,
    RadioGroup,
} from 'motili-ui';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import { DrawerHeadingAndTitle } from '../../components/drawer/DrawerHeadingAndTitle';
import { DrawerContainer } from '../../components/drawer/DrawerContainer';
import { ToggleButtonGroup } from '../../components/toggleButtonGroup/ToggleButtonGroup';
import useDrawerStyles from '../../components/drawer/DrawerStyles';
import {
    useGetExceptionFlagBucketsQuery,
    useGetExceptionFlagsQuery,
} from 'widgets/store/api/ExceptionFlagWidgetApi';
import { WorkOrderUserType } from 'widgets/models/ExceptionFlagModel';

interface CustomizeWidgetDrawerProps {
    widgetDetails: Widget;
    handleSubmit: (widgetDetails: Widget) => void;
    handleClose: () => void;
}

export function CustomizeWidgetDrawer(
    props: CustomizeWidgetDrawerProps
): JSX.Element {
    const styles = useDrawerStyles();
    const {
        widgetDetails: initialWidgetDetails,
        handleSubmit,
        handleClose,
    } = props;

    const [widgetData, setWidgetData] = useState(initialWidgetDetails);
    const [userOpen, setUserOpen] = useState<boolean>(
        widgetData.config.view === 'Other'
    );
    const [statusErrorMessage, setStatusErrorMessage] =
        useState<boolean>(false);
    const [maxStatusErrorMessage, setMaxStatusErrorMessage] =
        useState<boolean>(false);
    const [ownerErrorMessage, setOwnerErrorMessage] = useState<boolean>(false);
    const [maxOwnerErrorMessage, setMaxOwnerErrorMessage] =
        useState<boolean>(false);

    const { data: getWorkOrderExceptionFlags } =
        useGetExceptionFlagsQuery(undefined);
    const { data: getWorkOrderExceptionFlagBuckets } =
        useGetExceptionFlagBucketsQuery(undefined);
    const { data: getUserData } = useGetUsersQuery(null);

    const workOrderExceptionFlagsDropdownOptions =
        getWorkOrderExceptionFlags?.map(record => {
            return {
                label: snakeCaseToTitleCase(record.name),
                value: record.name,
            };
        }) || [];
    const workOrderExceptionFlagBucketsDropdownOptions =
        getWorkOrderExceptionFlagBuckets
            ?.filter(
                record => record.name !== 'ERROR' && record.name !== 'FUTURE'
            )
            .map(record => {
                return {
                    label: record.description,
                    value: record.name,
                };
            }) || [];
    const userDropdownOptions: { label: string; value: string }[] =
        getUserData?.map(elem => {
            return {
                label: `${elem.firstName} ${elem.lastName}`,
                value: elem.id,
            };
        }) || [];

    useEffect((): void => {
        setStatusErrorMessage(widgetData.config?.exceptionFlags?.length === 0);
        if (
            !widgetData.config.exceptionFlags ||
            widgetData.config.exceptionFlags.length < 5
        ) {
            setMaxStatusErrorMessage(false);
        }
    }, [widgetData.config?.exceptionFlags]);

    useEffect((): void => {
        setOwnerErrorMessage(widgetData.config?.user?.length === 0);
        if (!widgetData.config.user || widgetData.config.user.length < 5) {
            setMaxOwnerErrorMessage(false);
        }
    }, [widgetData.config?.user]);

    useEffect((): void => {
        const clearUserDropdown = (): void => {
            setUserOpen(widgetData.config.view === 'Other');

            if (widgetData.config.view !== 'Other') {
                setWidgetData(prevWidgetData => ({
                    ...prevWidgetData,
                    config: {
                        ...prevWidgetData.config,
                        user: [],
                    },
                }));
            }
        };
        clearUserDropdown();
    }, [widgetData.config.view, setWidgetData]);

    useEffect(() => {
        if (widgetData.config.view === 'Just Me') {
            setWidgetData({
                ...widgetData,
                config: { ...widgetData.config, chartType: 'Pie Chart' },
            });
        } else if (widgetData.config.view === 'All') {
            setWidgetData({
                ...widgetData,
                config: {
                    ...widgetData.config,
                    chartType: 'Pie Chart',
                },
            });
        } else {
            setWidgetData({
                ...widgetData,
                config: { ...widgetData.config, chartType: 'Bar Graph' },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetData?.config?.view, widgetData?.config?.workOrderUserType]);

    const handleNoPerPageButtonSelect = (event): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                noOfDocumentsToShow: Number(event.target.value),
            },
        });
    };
    const handleUserButtonSelect = (event): void => {
        if (event.target.value === 'Other') {
            setUserOpen(true);
        } else {
            setUserOpen(false);
        }
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                view: event.target.value,
                user: [],
            },
        });
    };
    const handleDropdownSelect = (type: string, value: []): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: value,
            },
        });
    };
    const workOrderExceptionOnChange = (selectedExceptionFlags: []): void => {
        if (selectedExceptionFlags.length > 5) {
            setMaxStatusErrorMessage(true);
        } else {
            handleDropdownSelect('exceptionFlags', selectedExceptionFlags);
        }
    };
    const userOnChange = (userIds: []): void => {
        if (userIds.length > 5) {
            setMaxOwnerErrorMessage(true);
        } else {
            handleDropdownSelect('user', userIds);
        }
    };

    const updateDocumentTypeFilter = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                workOrderUserType: (event.target as HTMLInputElement)
                    .value as WorkOrderUserType,
            },
        });
    };

    return (
        <DrawerContainer handleClose={handleClose}>
            <>
                <DrawerHeadingAndTitle
                    widgetData={widgetData}
                    setWidgetData={setWidgetData}
                    dataTestId={'EFWidgetTitleInput'}
                />
                <div className={styles.filterContainer}>
                    <div>
                        <div className={styles.dataVisualTypeRow}>
                            <h4>Select the Number of Records Per Page</h4>
                        </div>
                    </div>
                    <div>
                        <ToggleButtonGroup
                            options={[
                                {
                                    id: 'fiveButton',
                                    text: '5',
                                    value: 5,
                                    dataTestId: 'EFWidgetFivePerPageButton',
                                },
                                {
                                    id: 'tenButton',
                                    text: '10',
                                    value: 10,
                                    dataTestId: 'EFWidgetTenPerPageButton',
                                },
                            ]}
                            type={'noOfDocumentsToShow'}
                            selectedOption={
                                widgetData.config.noOfDocumentsToShow
                            }
                            handleButtonSelect={handleNoPerPageButtonSelect}
                        />
                    </div>
                    <div className={styles.marginTop20}>
                        <h4>Filter</h4>
                    </div>
                    <div className={styles.multipleInputRow}>
                        <div className={styles.dropdownSelectWidth}>
                            <DropDownSelect
                                data-test-id={
                                    'EFWidgetWorkOrderExceptionDropdown'
                                }
                                required={true}
                                isMulti={true}
                                onChange={exceptionFlag => {
                                    workOrderExceptionOnChange(exceptionFlag);
                                }}
                                name={'work-order-exception-select'}
                                label={'Exception Flag Type (Limit up to 5)'}
                                options={workOrderExceptionFlagsDropdownOptions}
                                value={widgetData.config.exceptionFlags}
                            />
                            {statusErrorMessage && (
                                <div className={styles.errorMessageTextColor}>
                                    Exception Flag Type is required
                                </div>
                            )}
                            {maxStatusErrorMessage && (
                                <div className={styles.errorMessageTextColor}>
                                    Exception limit reached
                                </div>
                            )}
                        </div>
                        {/*/!*date range type & date range from*!/*/}
                        <div style={{ width: '263px' }}>
                            <DropDownSelect
                                data-test-id={
                                    'EFWidgetExceptionFlagBucketDropdown'
                                }
                                required={true}
                                isMulti={false}
                                onChange={option => {
                                    handleDropdownSelect(
                                        'exceptionFlagBucket',
                                        option
                                    );
                                }}
                                name={'exception-flag-bucket-select'}
                                label={'Days Since Exception Flag Placed'}
                                options={
                                    workOrderExceptionFlagBucketsDropdownOptions
                                }
                                value={widgetData.config.exceptionFlagBucket}
                            />
                        </div>
                    </div>
                    <div>
                        <RadioGroup
                            options={[
                                {
                                    label: 'Owner View',
                                    value: WorkOrderUserType.OWNER,
                                    id: WorkOrderUserType.OWNER,
                                },
                                {
                                    label: 'Approval Owner View',
                                    value: WorkOrderUserType.APPROVAL_OWNER,
                                    id: WorkOrderUserType.APPROVAL_OWNER,
                                },
                            ].map(opt => ({
                                ...opt,
                                checked:
                                    widgetData?.config?.workOrderUserType ===
                                    opt.value,
                            }))}
                            name={'filter-select'}
                            label={''}
                            onChange={updateDocumentTypeFilter}
                            className={'document-type-select'}
                            required
                            data-test-id={'CDEWidgetRadios'}
                        />
                    </div>
                    <div>
                        <ToggleButtonGroup
                            options={[
                                {
                                    id: 'JustMeButton',
                                    text: 'Just Me',
                                    value: 'Just Me',
                                    dataTestId: 'EFWidgetJustMeButton',
                                },
                                {
                                    id: 'OtherButton',
                                    text: 'Other',
                                    value: 'Other',
                                    dataTestId: 'EFWidgetOtherButton',
                                },
                                {
                                    id: 'AllViewButton',
                                    text: 'All',
                                    value: 'All',
                                    dataTestId: 'EFWidgetViewAllButton',
                                },
                            ]}
                            type={'view'}
                            selectedOption={widgetData.config.view}
                            handleButtonSelect={handleUserButtonSelect}
                        />
                    </div>
                    {userOpen && (
                        <div className={styles.singleDropdownSelectRow}>
                            <DropDownSelect
                                data-test-id={'EFWidgetUserDropdown'}
                                required={true}
                                isMulti={true}
                                onChange={value => {
                                    userOnChange(value);
                                }}
                                name={'user-select'}
                                label={
                                    widgetData.config.workOrderUserType ===
                                    'OWNER'
                                        ? 'Select Owner (Limit up to 5)'
                                        : 'Select Approval Owner (Limit up to 5)'
                                }
                                options={userDropdownOptions}
                                value={widgetData.config.user}
                            />
                            {ownerErrorMessage && (
                                <div className={styles.errorMessageTextColor}>
                                    At least one owner is required
                                </div>
                            )}
                            {maxOwnerErrorMessage && (
                                <div className={styles.errorMessageTextColor}>
                                    Owner limit reached
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.confirmCancelContainer}>
                    <div className={styles.cancelButtonRightPadding}>
                        <ButtonSecondary
                            data-test-id={'EFWidgetDrawerCancelButton'}
                            onClick={handleClose}
                        >
                            Cancel
                        </ButtonSecondary>
                    </div>
                    <div>
                        <ButtonPrimary
                            data-test-id={'EFWidgetDrawerConfirmButton'}
                            disabled={
                                statusErrorMessage ||
                                (ownerErrorMessage &&
                                    widgetData.config.view === 'Other') ||
                                (userOpen &&
                                    widgetData.config.user.length === 0) ||
                                widgetData.config.workOrderUserType === null ||
                                widgetData.config.name.length === 0
                            }
                            onClick={() => {
                                handleSubmit(widgetData);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </div>
                </div>
            </>
        </DrawerContainer>
    );
}
