import * as React from 'react';
import { SVGProps } from 'react';

const DragIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 103 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <g
            data-name='Group 5408'
            transform='translate(-167 7678)'
            fill='#c2c8ce'
        >
            <circle
                data-name='Ellipse 383'
                cx={2}
                cy={2}
                r={2}
                transform='translate(173 -7675)'
            />
            <circle
                data-name='Ellipse 384'
                cx={2}
                cy={2}
                r={2}
                transform='translate(173 -7668)'
            />
            <circle
                data-name='Ellipse 385'
                cx={2}
                cy={2}
                r={2}
                transform='translate(173 -7661)'
            />
            <circle
                data-name='Ellipse 386'
                cx={2}
                cy={2}
                r={2}
                transform='translate(181 -7675)'
            />
            <circle
                data-name='Ellipse 387'
                cx={2}
                cy={2}
                r={2}
                transform='translate(181 -7668)'
            />
            <circle
                data-name='Ellipse 388'
                cx={2}
                cy={2}
                r={2}
                transform='translate(181 -7661)'
            />
        </g>
    </svg>
);

export default DragIcon;
