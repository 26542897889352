import { createUseStyles } from 'react-jss';

const useAddWidgetLayoutStyles = createUseStyles({
    root: {
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '48px',
        paddingBottom: '48px',
        margin: '12px 0',
    },
    addWidgetBlock: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    addWidgetContainer: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        padding: '8px',
        borderRadius: '4px',
    },
    stackIconBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        backgroundColor: '#1976d2',
        borderRadius: '50%',
    },
    addWidgetWrapper: {
        marginLeft: '8px',
        fontWeight: 'bold',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
    },
});

export default useAddWidgetLayoutStyles;
