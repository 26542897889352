import React, { ReactElement, JSXElementConstructor, useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import useDrawerStyles from './DrawerStyles';

interface DrawerContainerProps {
    handleClose: () => void;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export function DrawerContainer(props: DrawerContainerProps): JSX.Element {
    const styles = useDrawerStyles();
    const { handleClose, children } = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <div className={styles.drawerOverlay}></div>
            <ClickAwayListener onClickAway={() => handleClose()}>
                <div className={styles.drawerContainer}>{children}</div>
            </ClickAwayListener>
        </>
    );
}
