import React from 'react';
import {snakeCaseToTitleCase} from "../../../utils/WidgetUtils";
import useLibraryStyles from './DocumentDisplayCardStyles';

interface DocumentDisplayCardProps {
    id: number;
    number: string;
    status: string;
    documentType: string;
    clientName: string;
}

export function DocumentDisplayCard(props: Readonly<DocumentDisplayCardProps>): JSX.Element {
    const styles = useLibraryStyles();
    const { id, number, status, documentType, clientName } = props;

    const urlBases = {
        'WORK_ORDER': 'work-orders',
        'ORDER': 'orders',
        'DRAFT': 'drafts',
        'QUOTE': 'quotes'
    }
    const statusColors = {
        COMPLETED: {
            color: '#15b039',
            backgroundColor: '#edfaea'
        },
        NO_CONTRACTOR_FOUND: {
            color: '#e63232',
            backgroundColor: '#fceaea'
        },
        'DEFAULT': {
            color: '#654c0e',
            backgroundColor: '#f8f4ea'
        }
    }
    const statusCSS = statusColors[status] ? statusColors[status] : statusColors['DEFAULT'];

    return (
        <div className={styles.docDisplayCardContainer}>
            <div className={styles.idAndStatusRow}>
                <div className={styles.staticText}>
                    {snakeCaseToTitleCase(documentType)} #
                    <br/>
                    <a className={styles.fontSize14} target="_blank" href={`${window.location.href.replace('dashboard', '')}${urlBases[documentType]}/${id}/details`} rel={'noopener noreferrer'}>{number}</a>
                </div>
                <div className={styles.statusColorDisplay} style={{color: statusCSS.color, backgroundColor: statusCSS.backgroundColor}}>
                    {snakeCaseToTitleCase(status)}
                </div>
            </div>
            <div className={styles.detailsRow}>
                <div className={styles.staticText}>
                    Details
                    <br/>
                    <strong className={styles.clientNameText}>{clientName}</strong>
                </div>
            </div>
        </div>
    );
}
