import * as React from 'react';
import { SVGProps } from 'react';

const MarkAsRead = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 150 \u2013 10'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <g data-name='Group 5706'>
            <g data-name='Group 5378'>
                <g data-name='Group 5541'>
                    <path
                        data-name='Path 2562'
                        d='M2.15 15.404a.961.961 0 0 1-.961-.962v-10a3.058 3.058 0 0 1 3.054-3.054h7.853a3.057 3.057 0 0 1 3.054 3.054v5.948a3.057 3.057 0 0 1-3.054 3.054h-7.5l-1.842 1.743a.517.517 0 0 1-.082.064.957.957 0 0 1-.522.153Zm.219-.621ZM4.242 2.5a1.943 1.943 0 0 0-1.941 1.941v9.641l1.688-1.6a.558.558 0 0 1 .383-.153h7.724a1.943 1.943 0 0 0 1.942-1.941V4.44a1.943 1.943 0 0 0-1.942-1.936Z'
                        fill='#fff'
                    />
                </g>
            </g>
            <g data-name='Group 5595'>
                <path
                    data-name='Path 2561'
                    d='M7.405 9.925a.559.559 0 0 1-.4-.175L4.57 7.2a.607.607 0 0 1 0-.835.554.554 0 0 1 .8 0l2.04 2.138 3.9-3.9a.554.554 0 0 1 .8.014.606.606 0 0 1-.014.834L7.79 9.76a.556.556 0 0 1-.385.165Z'
                    fill='#fff'
                />
            </g>
        </g>
    </svg>
);

export default MarkAsRead;
