import React from 'react';
import { Widget } from '../../models/WidgetModels';
import { DateRangeTypes, DateRangeFroms } from '../../models/WidgetModels';
import { DropDownSelect } from 'motili-ui';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';

interface DrawerDateRangeDropdownProps {
    widgetData: Widget;
    setWidgetData: React.Dispatch<React.SetStateAction<Widget>>;
    id: string;
}

export function DrawerDateRangeDropdown(props: Readonly<DrawerDateRangeDropdownProps>) {
    const { widgetData, setWidgetData, id } = props;

    // Structuring api data to input needed format/values
    const dateRangeTypeArray = Array.from(
        Object.keys(DateRangeTypes),
        key => DateRangeTypes[key]
    );
    const dateRangeTypeData: { label: string; value: any }[] =
        dateRangeTypeArray.map(type => {
            return { label: snakeCaseToTitleCase(type), value: type };
        });

    const dateRangeFromData: { label: string; value: any }[] = Object.keys(
        DateRangeFroms
    )
        .filter(
            key =>
                !isNaN(
                    Number(DateRangeFroms[key as keyof typeof DateRangeFroms])
                )
        )
        .map(key => ({
            label: key,
            value: DateRangeFroms[key as keyof typeof DateRangeFroms],
        }));

    const handleDateRangeDropdown = (
        type: string,
        value: {
            label: string;
            value: string | number;
            date: string;
        }[]
    ): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                [type]: value,
            },
        });
    };

    return (
        <div
            style={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ width: '263px' }}>
                <DropDownSelect
                    required={true}
                    isMulti={false}
                    onChange={value => {
                        handleDateRangeDropdown('dateRangeType', value);
                    }}
                    name={'date-range-type-select'}
                    label={'Date Range Type'}
                    options={dateRangeTypeData}
                    value={widgetData.config.dateRangeType}
                />
            </div>
            <div style={{ width: '263px' }}>
                <DropDownSelect
                    required={true}
                    isMulti={false}
                    onChange={value => {
                        handleDateRangeDropdown('dateRangeFrom', {
                            ...value,
                            date: '',
                        });
                    }}
                    name={'date-range-from-select'}
                    label={'Date Range From'}
                    options={dateRangeFromData}
                    /*@ts-ignore*/
                    value={widgetData.config.dateRangeFrom}
                    id={id}
                />
            </div>
        </div>
    );
}
