import { createUseStyles } from 'react-jss';

const useAddWidgetModalStyles = createUseStyles({
    widgetContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    paddingTop25: {
        paddingTop: '25px',
    },
    widgetHeading: {
        fontWeight: 'bold',
        color: 'black',
    },
    widgetDescription: {
        color: '#5B657B',
        fontSize: '14px',
        marginTop: '8px',
    },
    widgetItemStyle: {
        borderRadius: '8px',
        padding: '15px',
        margin: '10px',
        background: 'white',
        minWidth: '275px',
        maxWidth: '275px',
        height: '400px',
    },
    clickableIconWrapper: {
        cursor: 'pointer',
        borderRadius: '8px',
        padding: '5px',
        position: 'relative',
        backgroundColor: '#d8dce3',
        height: '200px',
    },
    clickableIconStyle: {
        right: '8px',
        top: '8px',
        position: 'absolute',
    },
    clickableImageStyle: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingLeft: '25px',
        width: '90%',
        height: '100%',
    },
});

export default useAddWidgetModalStyles;
