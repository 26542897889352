import * as React from 'react';
import { SVGProps } from 'react';

const Clock = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 122 \u2013 23'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <g data-name='Group 4691' fill='#aaafba'>
            <path
                data-name='Path 2443'
                d='M7.96 14.128a6.064 6.064 0 1 1 6.067-6.051 6.083 6.083 0 0 1-6.067 6.051ZM3.002 8.057a4.961 4.961 0 1 0 4.963-4.954 4.955 4.955 0 0 0-4.963 4.954Z'
            />
            <path
                data-name='Path 2444'
                d='M8.51 6.241v1.475a.317.317 0 0 0 .083.2c.649.657 1.3 1.308 1.956 1.961a.535.535 0 0 1 .151.568.527.527 0 0 1-.42.378.514.514 0 0 1-.488-.145Q8.673 9.564 7.559 8.445a.513.513 0 0 1-.153-.373V4.733a.55.55 0 0 1 1.1.006c.005.5 0 1 0 1.5Z'
            />
        </g>
    </svg>
);

export default Clock;
