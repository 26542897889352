import React, { MouseEventHandler } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import useButtonStyles from '../ToggleButtonGroupStyles';

interface ToggleIconButtonGroupProps {
    options: { id: string; img: string; value: string; dataTestId: string }[];
    type: string;
    selectedOption: string | number;
    handleButtonSelect: MouseEventHandler<HTMLDivElement>;
}
export function ToggleIconButtonGroup(
    props: ToggleIconButtonGroupProps
): JSX.Element {
    const styles = useButtonStyles();
    const { handleButtonSelect, options, type, selectedOption } = props;

    return (
        <ButtonGroup onClick={handleButtonSelect}>
            {options.map(option => (
                <Button
                    key={option.id}
                    id={option.id}
                    className={
                        selectedOption === option.value
                            ? styles.activeButton
                            : styles.inactiveIconButton
                    }
                >
                    <img
                        className={styles.maxHeight}
                        id={option.value}
                        data-type-id={type}
                        data-test-id={option.dataTestId}
                        src={option.img}
                        alt={option.value}
                    />
                </Button>
            ))}
        </ButtonGroup>
    );
}
