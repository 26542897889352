import { createSlice } from '@reduxjs/toolkit';
const initialValue = {
    comments: [],
    docNumber: '',
};
const showAllComments = createSlice({
    name: 'showAllCommentsSlice',
    initialState: initialValue,
    reducers: {
        setDocumentComments: (state, action) => {
            state.comments = action.payload.comments;
            state.docNumber = action.payload.docNumber;
        },
        clearDocumentComments: state => {
            state.comments = [];
            state.docNumber = '';
        },
    },
});

export default showAllComments.reducer;

export const { setDocumentComments, clearDocumentComments } =
    showAllComments.actions;
