import { createApi } from '@reduxjs/toolkit/query/react';
import { WidgetDefinition } from 'widgets/models/WidgetModels';
import { axiosBaseQuery } from '../../../common/store/api/BaseQueryConfig';

// Define a service using a base URL and expected endpoints
export const getWidgetsApi = createApi({
    reducerPath: 'widgets',
    baseQuery: axiosBaseQuery,
    tagTypes: ['widget_details'],
    endpoints: builder => ({
        GetWidgets: builder.query<WidgetDefinition[], null>({
            query: () => ({
                url: `/DashboardWidgets`,
                method: 'GET',
            }),
            providesTags: ['widget_details'],
        }),
    }),
});

export const { useGetWidgetsQuery, useLazyGetWidgetsQuery } = getWidgetsApi;
