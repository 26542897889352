import React, { useEffect, useState } from 'react';
import { Widget } from '../../../models/WidgetModels';
import { useGetClientsQuery } from '../../../store/api/KeyWorkOrderStatusesWidgetApi';
import { DrawerChildClientDropdown } from './DrawerChildClientDropdown';
import { DropDownSelect } from 'motili-ui';

interface DrawerClientDropdownProps {
    widgetData: Widget;
    setWidgetData: React.Dispatch<React.SetStateAction<Widget>>;
}

export function DrawerClientDropdown(
    props: Readonly<DrawerClientDropdownProps>
): JSX.Element {
    const { widgetData, setWidgetData } = props;

    const [path, setPath] = useState<string | null>(null);
    const [childClientOpen, setChildClientOpen] = useState<boolean>(
        widgetData.config.clients.length > 0
    );

    useEffect((): void => {
        const getParentId: number[] | null = widgetData.config.clients
            ? widgetData.config.clients
                  .filter(client => client.option)
                  .map(elem => elem['value'])
            : null;
        setPath(
            getParentId ? `parent_id=${getParentId?.join('&parent_id=')}` : null
        );
        const hasClientChild: boolean = widgetData.config.clients.some(
            client => client.option
        );
        setChildClientOpen(hasClientChild);
    }, [widgetData.config.clients]);

    // Api calls to gather all dropdown data
    const { data: getClientsData } = useGetClientsQuery(null);

    // Structuring api data to input needed format/values
    const clientDropdownOptions: {
        label: string;
        value: number;
        option: boolean;
    }[] =
        getClientsData
            ?.map(elem => {
                return {
                    label: elem.name,
                    value: elem.id,
                    option: elem.hasChild,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const handleClientDropdown = (
        selectedClients: {
            label: string;
            value: number;
            option: boolean;
            parentId: number;
        }[]
    ): void => {
        setWidgetData({
            ...widgetData,
            config: {
                ...widgetData?.config,
                clients: selectedClients,
                childClients: widgetData.config.childClients.filter(child =>
                    selectedClients.some(
                        client => client.value === child.parentId
                    )
                ),
            },
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ width: '263px' }}>
                <DropDownSelect
                    data-test-id={'KWOSWidgetClientDropdown'}
                    isMulti={true}
                    onChange={selectedClients => {
                        handleClientDropdown(selectedClients);
                    }}
                    name={'client-select'}
                    label={'Client(s)'}
                    /*@ts-ignore*/
                    options={clientDropdownOptions}
                    /*@ts-ignore*/
                    value={widgetData.config.clients}
                />
            </div>
            {childClientOpen && path && (
                <DrawerChildClientDropdown
                    widgetData={widgetData}
                    setWidgetData={setWidgetData}
                    path={path}
                />
            )}
        </div>
    );
}
