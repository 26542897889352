import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { dataDashboardBaseQuery } from '../../../common/store/api/BaseQueryConfig';
import {
    WorkOrderExceptionCountAPIResponse,
    WorkOrderAPI,
    ExceptionFlagBucket,
    ExceptionFlag,
    ExceptionFlagFilters,
} from '../../models/ExceptionFlagModel';

export const getExceptionFlagWidgetApi = createApi({
    reducerPath: 'exceptionFlagReducer',
    baseQuery: dataDashboardBaseQuery,
    tagTypes: [
        'exception_flags',
        'exception_flag_count',
        'exception_flag_bucket',
        'exception_flags',
    ],
    endpoints: builder => ({
        GetFilteredWorkOrderExceptions: builder.query<
            WorkOrderAPI,
            ExceptionFlagFilters
        >({
            query: filters => ({
                url: `/v1/work-order/exception?page=${filters.page}&size=${filters.noOfDocumentsToShow}`,
                method: 'POST',
                body: filters,
            }),
            providesTags: ['exception_flags'],
        }),
        GetFilteredWorkOrderExceptionCount: builder.query<
            WorkOrderExceptionCountAPIResponse,
            ExceptionFlagFilters
        >({
            query: filters => ({
                url: `/v1/work-order/exception/count/by-exception-flag`,
                method: 'POST',
                body: filters,
            }),
            providesTags: ['exception_flag_count'],
        }),
        GetExceptionFlagBuckets: builder.query<
            ExceptionFlagBucket[],
            undefined
        >({
            query: () => ({
                url: `metadata/v1/exception-flag-buckets`,
                method: 'GET',
            }),
            providesTags: ['exception_flag_bucket'],
        }),
        GetExceptionFlags: builder.query<ExceptionFlag[], undefined>({
            query: () => ({
                url: `metadata/v1/exception-flags`,
                method: 'GET',
            }),
            providesTags: ['exception_flags'],
        }),
    }),
});

export const {
    useGetFilteredWorkOrderExceptionsQuery,
    useGetFilteredWorkOrderExceptionCountQuery,
    useGetExceptionFlagBucketsQuery,
    useGetExceptionFlagsQuery,
} = getExceptionFlagWidgetApi;
