import React, { useEffect, useState } from 'react';
import Tooltip from '../common/icons/Tooltip';
import AddReply from '../common/icons/AddReply';
import { CommentLayout } from './CommentLayout';
import {
    Box,
    Paper,
    Theme,
    useTheme,
    Grid,
    CircularProgress,
    Popper,
    ClickAwayListener,
    Collapse,
    Button,
} from '@mui/material';
import { SXCssObject } from './atoms/StyleModel';
import HideAllComments from '../common/icons/HideAllComments';
import {
    accessToken,
    getAsyncAwaitHttpObj,
} from '../common/store/api/HttpObjectConfig';
import {
    getDocumentTypeToDisplay,
    getEndPointForDocumentType,
    getLoggedInUserDetails,
    DocumentTypes,
    createCommentForWorkOrder,
    createCommentForOrder,
    createCommentForQuote,
    createCommentForDrafts,
    getUserEmail,
    capitalizeFirstLetter,
    getDocumentTypeToDisplayInTooltip,
    getPathBasedOnUserType,
    getDocumentsPayload,
    getDraftsPayload,
    isOrderDoc,
    UserTypes,
    getUserType,
    isLoggedIn,
} from '../utils/utils';
import {
    CommentDetails,
    CommentsScenario,
    CommentType,
    UserOrGroupListResponse,
} from 'comments/models/CommentsModel';
import {
    useGetDraftStatusesQuery,
    useGetOrderStatusesQuery,
    useGetWorkOrderStatusesQuery,
    useGetQuoteStatusesQuery,
} from 'comments/store/api/StatusesApi';
import { useSelector } from 'react-redux';
import {
    documentsList,
    individualDocumentComments,
} from 'comments/selector/CommentsSelector';
import { get, trim } from 'lodash';
import FormMentionsTextInput from './atoms/AddReplyAutoSuggest';
import AlertHandler from '../common/AlertHandler';
import { ErrorMessages } from './ErrorMessages';
import { useAppDispatch } from 'comments/store/CommentsStore';
import {
    clearDocumentComments,
    setDocumentComments,
} from 'comments/store/api/ShowAllComments';
import { TransitionGroup } from 'react-transition-group';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        pt: '8px',
        pb: '16px',
        mx: '10px',
        mt: '8px',
    },
    wrapper: {
        background: ' #F6F9FC 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        ml: '8px',
        mr: '8px',
        marginBottom: '8px',
    },
    dotsWrapper: {
        marginRight: '12px',
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center',
        width: '6px',
    },
    dots: {
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#17C2ED',
    },
    woContainer: {
        fontSize: '14px',
        color: '#17C2ED',
        display: 'flex',
        fontWeight: 500,
        justifyContent: 'left',
        margin: '10px 8px 10px 0px',
    },
    overflowSection: {
        padding: '5px 0px',
        backgroundColor: '#F6F9FC',
        cursor: 'pointer',
        position: 'relative',
    },
    expandedComment: {
        overflowY: 'scroll',
        maxHeight: '350px',
        '&::-webkit-scrollbar': {
            marginLeft: '4px',
            width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#666666',
            borderRadius: '10px',
        },
    },
    popOverStyle: {
        left: '8px',
        top: '-4px',
        pointerEvents: 'none',
    },
    userDetailsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    userDetailsWrapper: {
        fontSize: '14px',
        color: '#797F8D',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginLeft: 1,
    },
    tooltipPaperStyle: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        top: '-24px',
        position: 'relative',
        left: '6px',
        width: '380px',
    },
    menuPaperStyle: {
        backgroundColor: theme.palette.primary.dark,
        overflow: 'visible',
        borderRadius: '6px',
        marginLeft: '20px',
        width: '400px',
        zIndex: 2,
        position: 'relative',
        marginTop: '-4px',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '-4px',
            width: '12px',
            height: '12px',
            top: '18px',
            backgroundColor: theme.palette.primary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: '3px',
        },
    },
    tooltipWrapper: {
        padding: '20px 28px 16px',
    },
    tooltipLabel: {
        color: '#94A7CB',
    },
    tooltipText: {
        color: theme.palette.common.white,
        width: '100%',
        wordBreak: 'break-all',
        paddingRight: '12px',
    },
    commentsContainer: {
        p: '8px',
    },
    department: {
        fontSize: '12px',
        borderRadius: '4px',
        width: 'fit-content',
        height: '24px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
    },
    commentsSection: {
        fontSize: '14px',
        color: '#5B657B',
        display: 'flex',
        justifyContent: 'left',
    },
    expandCollapseComments: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    commentsTimeSection: {
        display: 'flex',
        fontSize: '12px',
        color: '#AAAFBA',
        marginLeft: '10px',
    },
    showAllLabel: {
        marginLeft: '5px',
        color: '#5B657B',
        fontSize: '12px',
    },
    timeSection: {
        paddingLeft: 2,
        display: 'flex',
        alignItems: 'center',
    },
    submitBtnStyle: {
        display: 'flex',
        minWidth: '52px',
        alignItems: 'center',
        opacity: 1,
        '&:hover': {
            backgroundColor: 'white',
        },
        '&:disabled': {
            opacity: 0.5,
        },
    },
});
interface Props {
    comment: CommentDetails;
    index: number;
    handleCommentsDrawerVisibility: (visibility: boolean) => void;
    fetchScenario: CommentsScenario;
    usersAndGroupApiData: UserOrGroupListResponse[];
}

interface UserOrGroupToDisplay {
    id: string;
    display: string;
}

export const Comment = (props: Props) => {
    const {
        comment,
        handleCommentsDrawerVisibility,
        usersAndGroupApiData,
        fetchScenario,
    } = props;
    const [addReplyLoader, setAddReplyLoader] = useState(false);
    const dispatch = useAppDispatch();
    const docCommentsInfo = useSelector(individualDocumentComments);
    const [highlightableComment, setHighlightableComment] = useState('');
    const userType = getUserType();
    const { data: getDraftData } = useGetDraftStatusesQuery(null, {
        skip:
            !isLoggedIn() ||
            userType === UserTypes.Client ||
            userType === UserTypes.Contractor,
    });
    const { data: getOrderData } = useGetOrderStatusesQuery(null, {
        skip: !isLoggedIn(),
    });
    const { data: getWorkOrderData } = useGetWorkOrderStatusesQuery(null, {
        skip: !isLoggedIn(),
    });
    const { data: getQuoteData } = useGetQuoteStatusesQuery(null, {
        skip: !isLoggedIn(),
    });
    const [userAndGroupList, setUsersAndGroupList] = useState<
        UserOrGroupToDisplay[]
    >([]);
    const [activeMessage, setActiveMessage] = useState('');
    const allDocumentsList = useSelector(documentsList);
    const isUnreadMessageExistsInDocument =
        comment.unreadObjectIds && comment.unreadObjectIds.length >= 1;
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const ref: React.RefObject<HTMLDivElement> = React.createRef();
    const [expandedComments, setExpandedComments] = React.useState(false);

    const commentsArr = expandedComments ? docCommentsInfo.comments : [comment];
    const popperStyle: React.CSSProperties = {
        position: 'absolute',
        top: 'auto',
        left: 'auto',
        right: 'auto',
        paddingTop: '3px',
        zIndex: 3,
    };

    const documentData = [
        ...(allDocumentsList.documents || []),
        ...(allDocumentsList.drafts || []),
    ].find(doc => doc.number === comment.documentNumber + '');

    const docType = documentData && documentData.documentTypeId;

    const getStatusFromDoc = (statusId: string) => {
        if (docType === DocumentTypes.WorkOrder) {
            return getWorkOrderData?.find(doc => doc.id === statusId);
        } else if (docType === DocumentTypes.Quote) {
            return getQuoteData?.find(doc => doc.id === statusId);
        } else if (docType === DocumentTypes.Draft) {
            return getDraftData?.find(doc => doc.id === statusId);
        } else if (docType === DocumentTypes.Order) {
            return getOrderData?.find(doc => doc.id === statusId);
        }
    };

    useEffect(() => {
        if (usersAndGroupApiData && usersAndGroupApiData.length > 0) {
            const usersList = usersAndGroupApiData.map(userOrGroup => {
                return {
                    id: userOrGroup.username,
                    display: userOrGroup.username,
                };
            });
            setUsersAndGroupList(usersList);
        }
    }, [usersAndGroupApiData]);

    const tooltipInfo = (() => {
        if (!documentData || !documentData.client) {
            return null;
        }
        let owner = '-';
        if (documentData.jobManager?.firstName) {
            owner =
                documentData.jobManager?.firstName +
                (documentData.jobManager?.lastName
                    ? ' ' + documentData.jobManager?.lastName
                    : '');
        }
        let statusToDisplay = getStatusFromDoc(documentData.statusId)
            ? getStatusFromDoc(documentData.statusId).display
            : capitalizeFirstLetter(documentData.statusId);
        let info = {
            client: documentData.client?.name,
            owner: owner,
            status: statusToDisplay,
            property: documentData.property?.stdAddress,
        };
        return info;
    })();

    const [addReply, ShowAddReply] = React.useState(false);
    const docTypeToDisplay = getDocumentTypeToDisplay(comment.modelType);
    const [isTooltipOpen, setTooltipOpen] = React.useState(false);

    // When user clicks hide all comments or clicks 'Show all comments' on anothe doc,
    // we clear the Documents API data(that contains all comments of doc) that is stored in redux and collapse the comments list.
    useEffect(() => {
        if (
            !docCommentsInfo ||
            !docCommentsInfo.comments ||
            docCommentsInfo.comments.length === 0 ||
            docCommentsInfo.docNumber !== comment.documentNumber
        ) {
            setExpandedComments(false);
        }
    }, [docCommentsInfo, comment.documentNumber]);

    const theme = useTheme();
    const cssObject = createCssObject(theme);

    //When user clicks "Show all comments", we fetch the Document details to get list of all comments for that doc and store it in redux and display.
    //We also hit /CommentNotifications API (to get tagged comments) and map the records with All comments data before storing it in redux
    const expandComments = async (commentId?: string) => {
        setHighlightableComment('');
        if (!documentData) return;
        try {
            const taggedComments = await getAsyncAwaitHttpObj().get(
                `/CommentNotifications/${
                    getLoggedInUserDetails().userId
                }/document/${comment.documentId}`
            );
            let response;
            if (docType === DocumentTypes.Draft) {
                response = await getAsyncAwaitHttpObj().post(
                    `/drafts/search?access_token=${accessToken()}`,
                    getDraftsPayload([documentData.number])
                );
            } else {
                response = await getAsyncAwaitHttpObj().post(
                    `/documentSearches/search?access_token=${accessToken()}`,
                    getDocumentsPayload([documentData.number])
                );
            }
            let commentsFromDoc = get(response, 'data[0].comments', []);
            const allComments = commentsFromDoc.map(comment => {
                const isExistsInFeedDetail = taggedComments.data.find(
                    feedDetail => feedDetail.commentId === comment.id
                );
                const commentList = {
                    documentId:
                        documentData.documentTypeId + '_' + documentData.number,
                    documentNumber: documentData.number,
                    commentedUserName: isExistsInFeedDetail
                        ? isExistsInFeedDetail.commentedUserName
                        : comment.fullName,
                    modelType: documentData.documentTypeId,
                    comment: isExistsInFeedDetail
                        ? isExistsInFeedDetail.comment
                        : comment.message,
                    commentId: isExistsInFeedDetail
                        ? isExistsInFeedDetail.commentId
                        : comment.id,
                    messageType: comment.messageType,
                    updatedTs: isExistsInFeedDetail
                        ? isExistsInFeedDetail.updatedTs
                        : comment.created,
                    createdTs: isExistsInFeedDetail
                        ? isExistsInFeedDetail.createdTs
                        : comment.created,
                    commentedUserDepartments:
                        isExistsInFeedDetail?.commentedUserDepartments,
                    objectId:
                        documentData.documentTypeId +
                        '_' +
                        documentData.number +
                        '_' +
                        comment.id,
                    userEmail: getUserEmail(),
                    documentPrimaryKey: documentData.id,
                    commentType: isExistsInFeedDetail
                        ? CommentType.COMMENT_FROM_NOTIFICATION
                        : CommentType.COMMENT_FROM_DOCUMENT,
                };
                return commentList;
            });
            dispatch(
                setDocumentComments({
                    comments: allComments.reverse(),
                    docNumber: comment.documentNumber,
                })
            );
            setExpandedComments(true);
            setHighlightableComment(commentId!);
        } catch (e) {
            AlertHandler.error(ErrorMessages.UnableToLoadAllComments);
        }
    };

    const hideComments = () => {
        setExpandedComments(false);
    };

    const getMentions = (str, mentions) =>
        mentions.reduce((acc: UserOrGroupToDisplay[], val) => {
            if (str.includes(`@[${val.display}](${val.id})`)) {
                acc.push(val.display);
            }
            return acc;
        }, []);

    // remove mentions meta data and replace with its display
    const removeMentions = (str, mentions) =>
        mentions.reduce((acc, val) => {
            if (acc.includes(`@[${val.display}](${val.id})`)) {
                return acc.replaceAll(
                    `@[${val.display}](${val.id})`,
                    `@${val.display}`
                );
            }
            return acc;
        }, trim(str));

    const handleAdd = () => {
        const mentions = getMentions(activeMessage, userAndGroupList);
        const note = removeMentions(activeMessage, userAndGroupList);
        const url = window.location.href;
        let mentionsData: any = {
            mentions,
            url,
            isManual: true,
        };
        if (getUserType() === UserTypes.Motili) {
            mentionsData = {
                ...mentionsData,
                primaryKey: comment.documentPrimaryKey,
                modelType: comment.modelType,
                jobId: comment.jobId,
            };
        }
        const docPayload = {
            mentionsData,
            note,
            additionalOptions: {
                pinned: false,
                addToComment: false,
            },
            newRating: {},
        };
        if (comment.modelType === DocumentTypes.Draft) {
            return createCommentForDrafts(documentData?.id, note, mentionsData);
        } else if (comment.modelType === DocumentTypes.Quote) {
            const data = { note: { note } };
            return createCommentForQuote(
                comment.documentPrimaryKey,
                data,
                mentionsData,
                docPayload
            );
        } else if (comment.modelType === DocumentTypes.Order) {
            const data = { note };
            return createCommentForOrder(
                comment.documentPrimaryKey,
                data,
                mentionsData,
                docPayload
            );
        }
        return createCommentForWorkOrder(
            comment.documentPrimaryKey,
            docPayload,
            mentionsData
        );
    };

    const appendActiveMessage = commentResponse => {
        const commentToSave = removeMentions(activeMessage, userAndGroupList);
        const addedComment: CommentDetails = {
            documentId: comment.documentId,
            documentNumber: comment.documentNumber,
            commentedUserName: comment.userName,
            commentedUserDepartments: comment.commentedUserDepartments,
            comment: commentToSave,
            commentId: commentResponse?.id,
            updatedTs: new Date().toString(),
            createdTs: new Date().toString(),
            commentedUserEmail: comment.userEmail,
            userEmail: comment.userEmail,
            userName: comment.userName,
            modelType: comment.modelType,
            unreadObjectIds: comment.unreadObjectIds,
            documentPrimaryKey: comment.documentPrimaryKey,
            jobId: comment.jobId,
            messageType: 'MOTILI',
            commentType: CommentType.LOCAL_COMMENT, //Message is added from client side when the user keys in a comment using "Add Reply".
        };
        return addedComment;
    };

    const handleChange = () => {
        setAddReplyLoader(true);
        handleAdd()
            .then(res => {
                AlertHandler.success(
                    ErrorMessages.AddReplySuccess +
                        `${docTypeToDisplay} ${comment.documentNumber}`
                );
                setHighlightableComment('');
                //When the comments window is expanded and a comment is posted, update it in client side(redux data) so it is also shown in list to user
                if (expandedComments) {
                    dispatch(
                        setDocumentComments({
                            ...docCommentsInfo,
                            comments: [
                                appendActiveMessage(res.data),
                                ...docCommentsInfo.comments,
                            ],
                        })
                    );
                }
                setActiveMessage('');
            })
            .catch(e => {
                AlertHandler.error(ErrorMessages.UnableToReply);
            })
            .finally(() => {
                setAddReplyLoader(false);
            });
    };
    return (
        <Box ref={ref} id={`feed${comment.documentNumber}`}>
            <Box sx={cssObject.root}>
                <Box sx={cssObject.wrapper}>
                    <Box sx={cssObject.dotsWrapper}>
                        {isUnreadMessageExistsInDocument && (
                            <Box sx={cssObject.dots}></Box>
                        )}
                    </Box>
                    <Box sx={cssObject.woContainer}>
                        {/* change the document type to dynamic one */}
                        {documentData ? (
                            <a
                                href={`/#/${getPathBasedOnUserType()}/${getEndPointForDocumentType(
                                    documentData.documentTypeId
                                )}/${documentData.id}/details`}
                                onClick={() => {
                                    handleCommentsDrawerVisibility(false);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {getDocumentTypeToDisplayInTooltip(
                                    comment.modelType
                                )}
                                #{comment.documentNumber}
                            </a>
                        ) : (
                            <Box>
                                {getDocumentTypeToDisplayInTooltip(
                                    comment.modelType
                                )}
                                #{comment.documentNumber}
                            </Box>
                        )}
                    </Box>
                    {tooltipInfo && (
                        <Box sx={cssObject.overflowSection}>
                            <Box
                                onClick={() => setTooltipOpen(true)}
                                // onMouseLeave={handlePopoverClose}
                                sx={{ display: 'flex' }}
                                ref={anchorRef}
                            >
                                <Tooltip />
                            </Box>
                            <Popper
                                open={isTooltipOpen}
                                anchorEl={anchorRef.current}
                                style={popperStyle}
                                placement={'top-end'}
                                transition
                                disablePortal
                            >
                                <Paper sx={cssObject.tooltipPaperStyle}>
                                    <Box />
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            setTooltipOpen(false);
                                        }}
                                    >
                                        <Paper sx={cssObject.menuPaperStyle}>
                                            <Box sx={cssObject.tooltipWrapper}>
                                                <Box sx={{ color: '#94A7CB' }}>
                                                    {docTypeToDisplay}{' '}
                                                    Information
                                                </Box>
                                                <hr
                                                    style={{
                                                        border: '0.5px solid #516180',
                                                        margin: '15px 0px',
                                                    }}
                                                />
                                                <Grid
                                                    container
                                                    sx={{
                                                        marginBottom: '12px',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={
                                                                cssObject.tooltipLabel
                                                            }
                                                        >
                                                            Client
                                                        </Box>
                                                        <Box
                                                            sx={
                                                                cssObject.tooltipText
                                                            }
                                                        >
                                                            {tooltipInfo.client ||
                                                                '-'}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={
                                                                cssObject.tooltipLabel
                                                            }
                                                        >
                                                            Status
                                                        </Box>
                                                        <Box
                                                            sx={
                                                                cssObject.tooltipText
                                                            }
                                                        >
                                                            {tooltipInfo.status ||
                                                                '-'}
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            marginTop: '12px',
                                                        }}
                                                        xs={6}
                                                    >
                                                        <Box
                                                            sx={
                                                                cssObject.tooltipLabel
                                                            }
                                                        >
                                                            Owner
                                                        </Box>
                                                        <Box
                                                            sx={
                                                                cssObject.tooltipText
                                                            }
                                                        >
                                                            {tooltipInfo.owner ||
                                                                '-'}
                                                        </Box>
                                                    </Grid>
                                                    {!isOrderDoc(docType) && (
                                                        <Grid
                                                            item
                                                            sx={{
                                                                marginTop:
                                                                    '12px',
                                                            }}
                                                            xs={6}
                                                        >
                                                            <Box
                                                                sx={
                                                                    cssObject.tooltipLabel
                                                                }
                                                            >
                                                                Property
                                                            </Box>
                                                            <Box
                                                                sx={
                                                                    cssObject.tooltipText
                                                                }
                                                            >
                                                                {tooltipInfo.property ||
                                                                    '-'}
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </ClickAwayListener>
                                </Paper>
                            </Popper>
                        </Box>
                    )}
                </Box>
                <Box>
                    <>
                        <Box
                            sx={[cssObject.expandedComment]}
                            id={`feed-item${comment.documentNumber}`}
                        >
                            <>
                                <TransitionGroup>
                                    {commentsArr.map((item, identifier) => {
                                        return (
                                            <Collapse
                                                key={item.commentId}
                                                timeout={400}
                                            >
                                                <Box
                                                    sx={
                                                        cssObject.commentsContainer
                                                    }
                                                    key={identifier}
                                                >
                                                    <CommentLayout
                                                        fetchScenario={
                                                            fetchScenario
                                                        }
                                                        highlightableComment={
                                                            expandedComments
                                                                ? highlightableComment
                                                                : ''
                                                        }
                                                        commentMessage={item}
                                                        isExpandView={
                                                            expandedComments
                                                        }
                                                        expandComments={
                                                            expandComments
                                                        }
                                                        ref={ref}
                                                        onCommentClick={
                                                            expandedComments
                                                                ? undefined
                                                                : () => {
                                                                      ShowAddReply(
                                                                          true
                                                                      );
                                                                  }
                                                        }
                                                    />
                                                </Box>
                                            </Collapse>
                                        );
                                    })}
                                </TransitionGroup>
                            </>
                        </Box>
                        {expandedComments && (
                            <Box sx={{ px: '16px' }}>
                                <hr
                                    style={{
                                        opacity: 0.2,
                                        border: '0.5px solid #707070',
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Box
                                        component={'span'}
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={hideComments}
                                    >
                                        <HideAllComments />
                                        <Box
                                            component={'span'}
                                            sx={cssObject.showAllLabel}
                                            onClick={() => {
                                                dispatch(
                                                    clearDocumentComments()
                                                );
                                                setHighlightableComment('');
                                                setExpandedComments(false);
                                                ShowAddReply(false);
                                            }}
                                        >
                                            Hide All Comments
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </>

                    {/* When Show All comments is clicked or Comment is clicked, display Add a Reply Field */}
                    {(expandedComments || addReply) && (
                        <Box sx={{ px: '16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    border: '1px solid #DFE4ED',
                                    borderRadius: '4px',
                                }}
                            >
                                <Box sx={{ width: '100%', display: 'flex' }}>
                                    <Box sx={{ width: 'calc(100% - 50px)' }}>
                                        <FormMentionsTextInput
                                            value={activeMessage}
                                            placeholder={'Add a Reply'}
                                            onChange={e =>
                                                setActiveMessage(e.target.value)
                                            }
                                            mentions={userAndGroupList}
                                        />
                                    </Box>
                                    <Button
                                        disableRipple
                                        sx={cssObject.submitBtnStyle}
                                        onClick={handleChange}
                                        disabled={
                                            !/(?:\w|\d){3,}/.test(activeMessage)
                                        }
                                    >
                                        {addReplyLoader ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <AddReply />
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
