import React from 'react';
import { LoadingSpinner } from 'motili-ui';
import useInternalLoadingStyles from './InternalLoadingStyles';

interface Props {
    open: boolean;
}
//To be used in Comments Nav
export function LoadingOverlayInNavDrawer(props: Props) {
    const styles = useInternalLoadingStyles();

    return (
        <>
            {props.open && (
                <div className={styles.loadingOverlayComments}>
                    <LoadingSpinner />
                </div>
            )}
        </>
    );
}

//To be used in Widgets
export function InternalLoading(props: Props) {
    const styles = useInternalLoadingStyles();

    return (
        <>
            {props.open && (
                <div className={styles.loadingOverlayWidget}>
                    <LoadingSpinner />
                </div>
            )}
        </>
    );
}
