import { createUseStyles } from 'react-jss';

const useDeleteWidgetConfirmationModalStyles = createUseStyles({
    alertIconStyle: {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    alertIconWrapper: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: '#f3a638',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '5px',
    },
    boldCenterContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '5px',
        fontWeight: 'bold',
    },
});

export default useDeleteWidgetConfirmationModalStyles;
