import React, { useEffect, useMemo, useState } from 'react';
import { DataTable } from 'motili-ui';
import { Tooltip } from 'react-tooltip';
import { BaseUrl, TableHeaders } from '../../models/WidgetModels';
import { snakeCaseToTitleCase } from '../../../utils/WidgetUtils';
import { useGetFilteredWorkOrderExceptionsQuery } from 'widgets/store/api/ExceptionFlagWidgetApi';
import {
    EXCEPTION_FLAG,
    ExceptionFlagFilters,
    TableRecords,
    WorkOrderExceptionCountAPIResult,
} from 'widgets/models/ExceptionFlagModel';

const styles = {
    menuItemStyle: {
        display: 'flex',
        alignItems: 'center',
        zIndex: '4',
        width: '240px',
        margin: '1px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#314976',
            borderRadius: '4px',
        },
    },
    menuItemIconStyle: {
        paddingRight: '10px',
        paddingLeft: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    menuItemTextStyle: {
        color: 'white',
    },
    infoTipStyle: {
        margin: '3px',
    },
};

const InfoCardContent = ({ stdAddress }: { stdAddress: string }) => (
    <div id='menu-list-quote' style={{ maxWidth: 'fit-content' }}>
        <div style={styles.menuItemStyle}>
            <span style={styles.menuItemIconStyle}>
                <img
                    alt='home icon'
                    src={`${BaseUrl.widgetImage}/home-Icon.svg`}
                />
            </span>
            <div style={styles.menuItemTextStyle}>
                <span>{stdAddress}</span>
            </div>
        </div>
    </div>
);
interface ExceptionFlagTableProps {
    originalWorkOrderCountData: WorkOrderExceptionCountAPIResult[] | undefined;
    mappedWorkOrderCountData:
        | { label: string; value: string; count: number }[]
        | undefined;
    activeTablePage: number;
    onPageClick: (e: number) => void;
    workOrderFilters: ExceptionFlagFilters;
    chartSelectedValue: EXCEPTION_FLAG;
    recordsPerPage: number;
    userIds: number[];
    displayType: string;
    labelIndex: number;
}

export function ExceptionFlagTable(
    props: Readonly<ExceptionFlagTableProps>
): JSX.Element {
    const {
        originalWorkOrderCountData,
        mappedWorkOrderCountData,
        activeTablePage,
        onPageClick,
        workOrderFilters,
        chartSelectedValue,
        recordsPerPage,
        userIds,
        displayType,
        labelIndex,
    } = props;

    const [mappedWorkOrderData, setMappedWorkOrderData] = useState<
        TableRecords[]
    >([]);

    const tableHeaders: TableHeaders[] = [
        {
            displayName: 'Document #',
            value: 'number',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Document Type',
            value: 'workOrderTypeId',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Client',
            value: 'client',
            sortable: false,
            ascending: false,
        },

        {
            displayName: 'Contractor Company',
            value: 'contractor',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Status',
            value: 'statusId',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Exception Flag',
            value: 'issueId',
            sortable: false,
            ascending: false,
        },
        {
            displayName: 'Days Since Flagged',
            value: 'issueAgeDays',
            sortable: false,
            ascending: false,
        },
    ];

    // Api call to get work order table data
    const response = useGetFilteredWorkOrderExceptionsQuery(
        {
            ...workOrderFilters,
            exceptionFlags: [chartSelectedValue],
            userIds:
                displayType === 'Bar Graph'
                    ? userIds
                    : workOrderFilters.userIds,
        },
        { refetchOnMountOrArgChange: true }
    );
    const GetFilteredWorkOrdersData = response?.data?.result;

    const totalNumberOfRecords = useMemo(() => {
        if (displayType === 'Pie Chart') {
            const _totalNumberOfRecords:
                | {
                      label: string;
                      value: string;
                      count: number;
                  }[]
                | undefined = mappedWorkOrderCountData?.filter(
                obj => obj.value === chartSelectedValue
            );
            return _totalNumberOfRecords
                ? _totalNumberOfRecords[0]?.count || 0
                : 0;
        } else if (displayType === 'Bar Graph') {
            return originalWorkOrderCountData
                ? originalWorkOrderCountData[labelIndex]?.counts?.find(
                      r => r.label === chartSelectedValue
                  )?.count || 0
                : 0;
        }
    }, [
        chartSelectedValue,
        displayType,
        labelIndex,
        mappedWorkOrderCountData,
        originalWorkOrderCountData,
    ]);

    useEffect((): void => {
        if (GetFilteredWorkOrdersData) {
            setMappedWorkOrderData(
                GetFilteredWorkOrdersData.map((record, index) => {
                    const numberLink: string = `/#/ops/work-orders/${record.workOrder.id}/details`;
                    const clientLink: string = `/#/ops/clients/${record.workOrder.client.id}/details`;
                    const contractorLink: string = `/#/ops/contractors/${record.workOrder.contractor?.id}/details`;
                    const workOrderTypeDisplay: string = snakeCaseToTitleCase(
                        record.workOrder.workOrderTypeId
                    );

                    return {
                        number: (
                            <a
                                data-test-id={`EFTableIdLink${index}`}
                                href={numberLink}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                {record.workOrder.number}
                            </a>
                        ),
                        workOrderTypeId: workOrderTypeDisplay,
                        client: (
                            <div>
                                <img
                                    id={`exc-flag-info-${record.workOrder.id}-${index}`}
                                    key={index}
                                    alt={'info icon'}
                                    style={styles.infoTipStyle}
                                    src={`${BaseUrl.widgetImage}/info-icon.svg`}
                                />
                                <Tooltip
                                    id={`exc-flag-info-tooltip-${record.workOrder.id}-${index}`}
                                    anchorSelect={`#$exc-flag-info-${record.workOrder.id}-${index}`}
                                    openOnClick
                                    place={'bottom-start'}
                                    opacity={1}
                                    style={{
                                        ...styles.infoTipStyle,
                                        backgroundColor: '#0e2248',
                                        zIndex: 5,
                                        borderRadius: 6,
                                        maxWidth: 'fit-content',
                                    }}
                                    disableStyleInjection={'core'}
                                >
                                    <InfoCardContent
                                        stdAddress={
                                            record.workOrder.client.address
                                                .stdAddress
                                        }
                                    />
                                </Tooltip>
                                <a
                                    data-test-id={`EFTableClientLink${index}`}
                                    href={clientLink}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    {record.workOrder.client.name}
                                </a>
                            </div>
                        ),
                        issueId: snakeCaseToTitleCase(record.issueId),
                        statusId: snakeCaseToTitleCase(
                            record.workOrder.statusId
                        ),
                        contractor: record.workOrder.contractor?.name ? (
                            <a
                                data-test-id={`EFTableContractorLink${index}`}
                                href={contractorLink}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                {record.workOrder.contractor?.name}
                            </a>
                        ) : (
                            'NCF'
                        ),
                        issueAgeDays: record.issueAgeDays,
                    };
                })
            );
        }
    }, [GetFilteredWorkOrdersData]);

    return (
        <DataTable
            headerBackground={'#0E2248'}
            headerTextColor={'#FFFFFF'}
            data-test-id={'EFWidgetClosedTable'}
            totalRecordCount={totalNumberOfRecords || 0}
            records={mappedWorkOrderData}
            tableHeaders={tableHeaders}
            showPagination
            activePage={activeTablePage}
            recordsPerPage={recordsPerPage}
            onPageClick={pageNumber => {
                onPageClick(pageNumber);
            }}
            onListItemClick={listItem => {}}
            onTableHeaderItemClick={headerItem => {}}
        />
    );
}
