import React, { useEffect, useState } from 'react';
import { Widget, MultiDropdown } from '../../models/WidgetModels';
import { useGetRegionsAndStatesQuery } from '../../store/api/KeyWorkOrderStatusesWidgetApi';
import { DropDownSelect } from 'motili-ui';

interface DrawerStateDropdownProps {
    widgetData: Widget;
    setWidgetData: React.Dispatch<React.SetStateAction<Widget>>;
    id: string;
}

export function DrawerStateDropdown(props: Readonly<DrawerStateDropdownProps>) {
    const { widgetData, setWidgetData, id } = props;

    const [stateOptions, setStateOptions] = useState<MultiDropdown[]>([]);
    const [initialStateOptions, setInitialStateOptions] = useState<
        MultiDropdown[]
    >([]);

    // Api calls to gather all dropdown data
    const { data: getRegionsAndStatesData } = useGetRegionsAndStatesQuery(null);

    useEffect(() => {
        if (getRegionsAndStatesData) {
            const regionsData: { label: string; value: string }[] | undefined =
                getRegionsAndStatesData
                    ?.map(item => {
                        return {
                            label: `${item.name} Region (${item.states.length} States)`,
                            value: item.name,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            const statesData = getRegionsAndStatesData
                .map(item => {
                    return item.states;
                })
                .flatMap(nestedArray => nestedArray)
                .sort(
                    (
                        a: { name: string },
                        b: {
                            name: string;
                        }
                    ) => a.name.localeCompare(b.name)
                );

            const statesFormatted:
                | { label: string; value: string }[]
                | undefined = statesData
                ? statesData.map((item: { name: string }) => {
                      return {
                          label: item.name,
                          value: item.name,
                      };
                  })
                : [];
            const stateDropdownOptions:
                | {
                      label: string;
                      value: string;
                  }[]
                | undefined = [
                ...(regionsData ?? []),
                ...(statesFormatted ?? []),
            ];

            // loop through each region and remove from options if all states in that region are already selected
            let filteredStateDropdownOptions = stateDropdownOptions;
            for (let regionIndex = 0; regionIndex < getRegionsAndStatesData.length; regionIndex++) {
                const selectedStates = widgetData.config.states.map(state => { return state.value});
                const regionValidation = getRegionsAndStatesData[regionIndex].states.every((state: {name: string, abbreviation: string}) => selectedStates.includes(state.name))

                if (regionValidation) {
                    filteredStateDropdownOptions = filteredStateDropdownOptions.filter(stateOption => stateOption.value !== getRegionsAndStatesData[regionIndex].name)
                }
            }

            setStateOptions(filteredStateDropdownOptions);
            setInitialStateOptions(stateDropdownOptions);
        }
    }, [getRegionsAndStatesData, widgetData.config.states]);

    const handleStateDropdown = (
        selectedStateOrRegion: { label: string; value: string }[]
    ) => {
        // reset state and region dropdown options when clear button is clicked
        if (selectedStateOrRegion.length === 0) {
            setStateOptions(initialStateOptions);
        }

        const regions: string[] | undefined = getRegionsAndStatesData?.map(
            item => item['name']
        );
        const selectedRegion = selectedStateOrRegion.find(
            stateOrRegionSelection =>
                regions?.some(region => region === stateOrRegionSelection.value)
        );

        // Selected a region
        if (selectedRegion) {
            const regionData = getRegionsAndStatesData?.find(
                region => region.name === selectedRegion?.value
            );

            if (regionData) {
                const regionStates = regionData.states
                    .filter(
                        (state: { name: string }) =>
                            !widgetData.config.states.some(
                                item => item.value === state.name
                            )
                    )
                    .map((state: { name: string }) => ({
                        label: state.name,
                        value: state.name,
                    }));

                setWidgetData({
                    ...widgetData,
                    config: {
                        ...widgetData?.config,
                        states: [
                            ...selectedStateOrRegion.filter(
                                option => option.value !== selectedRegion?.value
                            ),
                            ...regionStates,
                        ],
                    },
                });

                // Removes selected region from state dropdown options
                setStateOptions(
                    stateOptions.filter(
                        option => option.value !== selectedRegion?.value
                    )
                );
            }
        } else {
            // Selected/removed a state
            setWidgetData({
                ...widgetData,
                config: {
                    ...widgetData?.config,
                    states: selectedStateOrRegion,
                },
            });
        }
    };

    return (
        <div style={{ marginTop: '30px' }}>
            <DropDownSelect
                id={id}
                isMulti={true}
                onChange={selectedStateOrRegion => {
                    handleStateDropdown(selectedStateOrRegion);
                }}
                name={'state-select'}
                label={'States'}
                options={stateOptions}
                value={widgetData.config.states}
            />
        </div>
    );
}
