import React, { useState } from 'react';
import AlertIcon from 'common/icons/AlertIcon';
import DragIcon from 'common/icons/DragIcon';
import { CustomizeWidgetDrawer } from './RecentlyViewedDrawer';
import { MenuButton } from 'widgets/components/menuButton/MenuButton';
import { DocumentDisplayCard } from 'widgets/components/documentDisplayCard/DocumentDisplayCard';
import { ToggleButtonGroup } from 'widgets/components/toggleButtonGroup/ToggleButtonGroup';
import { Divider } from 'widgets/components/divider/Divider';
import { Widget } from 'widgets/models/WidgetModels';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import useLibraryStyles from 'widgets/components/WidgetStyleLibrary';

interface RecentlyViewedProps {
    data: Widget;
    onUpdateWidget: (widget: Widget) => void;
    onDelete: (id: number) => void;
    listeners?: SyntheticListenerMap;
    attributes: DraggableAttributes;
}

export function RecentlyViewedWidget(
    props: Readonly<RecentlyViewedProps>
): JSX.Element {
    const styles = useLibraryStyles();
    const { data, onUpdateWidget, listeners, attributes } = props;

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [tempFilter, setTempFilter] = useState<string>(
        data.config.documentType
    );

    const persistRootValue: string | null =
        localStorage.getItem('persist:root');
    let recentlyViewedDocuments = [];
    if (persistRootValue) {
        const persistRootObject = JSON.parse(persistRootValue);
        const recentlyViewedData = JSON.parse(persistRootObject.recentlyViewed);
        recentlyViewedDocuments = recentlyViewedData.recentlyViewedDocuments;
    }

    const filterAllDocTypes = () => {
        return recentlyViewedDocuments.slice(
            0,
            data.config.noOfDocumentsToShow
        );
    };
    const filterNonAllDocTypes = docType => {
        return recentlyViewedDocuments
            .filter(
                (document: { documentTypeId: string }) =>
                    document.documentTypeId === docType
            )
            .slice(0, data.config.noOfDocumentsToShow);
    };

    let filteredDocuments;
    const filterType =
        tempFilter !== data.config.documentType
            ? tempFilter
            : data.config.documentType;
    filteredDocuments =
        filterType === 'ALL_DOCUMENT_TYPE'
            ? filterAllDocTypes()
            : filterNonAllDocTypes(filterType);

    const updateTempDocumentTypeFilter = (event): void => {
        setTempFilter(event.target.value);
    };

    const handleCustomiseWidgetMenuClick = (): void => {
        setDrawerOpen(true);
    };
    const handleDeleteWidgetMenuClick = (): void => {
        props.onDelete(data.id);
    };

    return (
        <div className={styles.rootFullScreen}>
            {drawerOpen && (
                <CustomizeWidgetDrawer
                    widgetDetails={data}
                    handleClose={() => {
                        setDrawerOpen(false);
                    }}
                    handleSubmit={(widgetDetails: Widget) => {
                        onUpdateWidget(widgetDetails);
                        setTempFilter(widgetDetails.config.documentType);
                        setDrawerOpen(false);
                    }}
                />
            )}
            <div style={{ width: '100%' }}>
                <div className={styles.widgetHeader}>
                    <div
                        className={styles.draggableAreaStyle}
                        {...listeners}
                        {...attributes}
                    >
                        <DragIcon />
                        <div className={styles.widgetTitle}>
                            {data.config.name}
                        </div>
                    </div>

                    <div className={styles.toggleButtonMenuButtonContainer}>
                        {/*/!*document type*!/*/}
                        <ToggleButtonGroup
                            data-test-id={'RVWidgetDocumentTypeToggleButtons'}
                            options={[
                                {
                                    id: 'allDocumentTypeButton',
                                    text: 'All',
                                    value: 'ALL_DOCUMENT_TYPE',
                                    dataTestId: 'RVWidgetAllDocumentTypeButton',
                                },
                                {
                                    id: 'workOrdersButton',
                                    text: 'Work Orders',
                                    value: 'WORK_ORDER',
                                    dataTestId: 'RVWidgetWorkOrdersButton',
                                },
                                {
                                    id: 'ordersButton',
                                    text: 'Orders',
                                    value: 'ORDER',
                                    dataTestId: 'RVWidgetOrdersButton',
                                },
                                {
                                    id: 'draftsButton',
                                    text: 'Drafts',
                                    value: 'DRAFT',
                                    dataTestId: 'RVWidgetDraftsButton',
                                },
                                {
                                    id: 'quotesButton',
                                    text: 'Quotes',
                                    value: 'QUOTE',
                                    dataTestId: 'RVWidgetQuotesButton',
                                },
                            ]}
                            type={'noOfDocumentsToShow'}
                            selectedOption={
                                tempFilter === data.config.documentType
                                    ? data.config.documentType
                                    : tempFilter
                            }
                            handleButtonSelect={updateTempDocumentTypeFilter}
                        />

                        <Divider height={'40px'} />

                        <MenuButton
                            key={data.id}
                            handleCustomiseWidgetMenuClick={
                                handleCustomiseWidgetMenuClick
                            }
                            handleDeleteWidgetMenuClick={
                                handleDeleteWidgetMenuClick
                            }
                            widgetId={data.id.toString()}
                        />
                    </div>
                </div>
                <div>
                    {filteredDocuments.length ? (
                        <div className={styles.documentCardContainer}>
                            {filteredDocuments.map(document => (
                                <DocumentDisplayCard
                                    key={document.id}
                                    id={document.id}
                                    clientName={document.clientName}
                                    number={document.number}
                                    status={document.statusId}
                                    documentType={document.documentTypeId}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.noContentLayoutFullScreen}>
                            <div className={styles.alertIconStyle}>
                                <div className={styles.alertIconWrapper}>
                                    <AlertIcon />
                                </div>
                                <div className={styles.widgetStatusFont}>
                                    No Records to Show!
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
