import { getLoggedInUserDetails, getUserId } from '../../../utils/utils';
import axios from 'axios';

export const accessToken = () => getLoggedInUserDetails()?.id;

export const getHeaders = () => {
    let obj = {
        authorization: accessToken(),
    };
    return obj;
};

const getAxiosObj = () => {
    return {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: getHeaders(),
        timeout: 60000,
    };
};

export const getAsyncAwaitHttpObj = () => {
    const axiosObj = axios.create(getAxiosObj());
    return axiosObj;
};

export const getUnReadCount = () =>
    getAsyncAwaitHttpObj().get(
        `/CommentNotifications/${getUserId()}/unReadCount`
    );

export const getUnReadComment = (documentTypeId, documentNumber) =>
    getAsyncAwaitHttpObj().get(`/CommentNotifications/getComment`, {
        params: { documentTypeId, documentNumber },
    });
