const styles = {
    width: '100%',
    minHeight: '48px',
    marginLeft: '2px',
    control: {
        backgroundColor: '#fff',
        fontSize: 14,
        fontWeight: 'normal',
    },
    highlighter: {
        border: '0px',
        padding: '5px',
        minHeight: '48px',
    },
    suggestions: {
        width: '65%',
        marginTop: '25px',
        padding: '12px 0px',
        backgroundColor: '#0E2248',
        borderRadius: '4px',
        list: {
            backgroundColor: '#0E2248',
            fontSize: '12px',
            maxHeight: '200px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            borderRadius: '4px',
        },
        item: {
            padding: '10px 16px',
            borderRadius: '4px',
            color: '#fff',
            margin: '5px 16px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
            '&focused': {
                backgroundColor: '#314976',
                color: '#fff',
            },
        },
    },
    input: {
        borderRadius: '0px',
        background: 'white !important',
        border: 'none',
        outline: 'none',
        color: '#5B657B',
        /* override inline styles from pkg */
        padding: '5px',
        boxShadow: 'none !important',
        '::WebkitInputPlaceholder': {
            fontSize: '12px',
            color: '#AAAFBA',
        },
    },
    mention: {
        position: 'relative',
        zIndex: 1,
        color: '#58C0E8',
    },
};
export default styles;
