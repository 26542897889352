import React, {useEffect} from 'react';
import { Widget, BaseUrl } from 'widgets/models/WidgetModels';
import DragItem from './DraggableWidgetItem';
import DragIcon from "../../common/icons/DragIcon";
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash';
import useLibraryStyles from "../components/WidgetStyleLibrary";

interface Props {
    widgetsList: Array<Widget>;
    onUpdateWidget: (widget: Widget) => void;
    onUpdateWidgetOrder: (widget: Array<Widget>) => void;
    onDelete: (id: number) => void;
}

/**
 * This component receives the widgets list from backend, filters out "LAYOUT" objects and displays other widgets to user.
 * This component also receives callbacks to update backend:
 * onUpdateWidget : To update configuration of a particular widget(Eg: Updating name, no of docs to be shown for recently viewed)
 * onUpdateWidgetOrder : To update widget order when drag and drop is performed.
 */

/**
 * @param {Props} props - The props for the component. Should not be modified within the component.
 */
export function WidgetsSection(props: Readonly<Props>) {
    const styles = useLibraryStyles();
    const { widgetsList, onDelete, onUpdateWidget, onUpdateWidgetOrder } = props;

    /* Take user preferences for widgets from API, sort and store in state variable. Use the state variable in UI.
     When we drag a widget OVER(onDragOver) another, just update state variable alone. Updating API on dragOver, would result in lot of API calls
     When drag had ENDED(onDragEnd): if there is a change in order,update API */
    const orderedList = widgetsList.sort((widget1, widget2) =>
        widget1.config.order > widget2.config.order ? 1 : -1
    );
    const [orderedWidgets, setOrderedWidgets] = React.useState(orderedList);
    const [dragging, setDragging] = React.useState(false)
    const [activeId, setActiveId] = React.useState(null);

    useEffect(() => {
        setOrderedWidgets(widgetsList)
    }, [widgetsList])

    const handleDragOver = event => {
        const { active, over } = event;
        if (!active || !over) return;
        const clonedList = cloneDeep(orderedWidgets);
        const updatedWidgets = arrayMove(
            clonedList,
            clonedList.findIndex(item => item.id === active.id),
            clonedList.findIndex(item => item.id === over.id)
        );
        updatedWidgets.forEach(
            (widget: Widget, index) => (widget.config.order = index)
        );
        setOrderedWidgets(updatedWidgets);
    };
    const handleDragStart = event => {
        setActiveId(event.active.id);
        setDragging(true)
    };
    const handleDragEnd = () => {
        setActiveId(null)
        setDragging(false)

        if (isWidgetRearranged(orderedWidgets)) {
            onUpdateWidgetOrder(orderedWidgets);
        }
    };
    const isWidgetRearranged = newWidgetsList => {
        let isRearranged = false;
        try {
            newWidgetsList.forEach(widget => {
                const existingApiData = widgetsList!.find(
                    apiData => apiData.id === widget.id
                );
                if (widget.config.order !== existingApiData!.config.order) {
                    isRearranged = true;
                }
            });
        } catch (e) {
            isRearranged = true;
        }
        return isRearranged;
    };

    return (
        <div>
            <DndContext
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
            >
                <div>
                    <SortableContext
                        strategy={rectSortingStrategy}
                        items={orderedWidgets}
                    >
                        {orderedWidgets.map((widget: Widget, index) => (
                            <div key={index} style={{ marginBottom: '25px' }}>
                                <DragItem
                                    id={widget.id}
                                    value={widget}
                                    dragging={dragging}
                                    onDelete={onDelete}
                                    onUpdateWidget={onUpdateWidget}
                                />
                            </div>
                        ))}
                    </SortableContext>
                </div>
                {activeId && (
                    <DragOverlay>
                        <div
                            id={activeId}
                            className={styles.dragOverlayContainer}
                        >
                            <div className={styles.widgetHeader}>
                                <div
                                    className={styles.dragOverlayAreaStyle}
                                >
                                    <DragIcon />
                                    <div className={styles.widgetTitle}>
                                        {orderedWidgets.find(widget => widget.id === activeId)?.config.name || ''}
                                    </div>
                                </div>
                                <button
                                    id={activeId}
                                    className={styles.dragWidgetMenuButton}
                                >
                                    <div>
                                        <img
                                            alt={'menu button'}
                                            src={
                                                `${BaseUrl.widgetImage}/triple-dot-vert-icon.svg`
                                            }
                                        />
                                    </div>
                                </button>
                            </div>
                            <div className={styles.dragWidgetContainer}>
                                <div className={styles.widgetStatusFont}>
                                    Drag and Drop Widget
                                </div>
                            </div>
                        </div>
                    </DragOverlay>
                )}
            </DndContext>
        </div>
    );
}
