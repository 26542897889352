export enum Widgets {
    ExceptionFlag = 'Exception Flag',
    KeyWorkOrderStatuses = 'Key Work Order Statuses',
    ContractorDocumentExpiration = 'Contractor Document Expiration',
    RecentlyViewed = 'Recently Viewed'
}

export enum CommentsFilterOptions {
    Unreadcomments = 'unreadcomments',
    Allcomments = 'allcomments',
}

export enum MicroFrontendConstants {
    OnDashboardComment = 'DASHBOARD_COMMENT',
    AddDotToCommentIcon = 'addDotToCommentIcon',
    OnParentLogout = 'onParentLogout',
    OnCommentsNavBarClick = 'onCommentsNavBarClick',
    commentsNavListenerSet = 'commentsNavListenerSet',

    /**
     * Name for the MicroFrontends for Dashboard. Any changes made to the name, should be changed in
     *    MotiliWeb codebase(In MicroFrontend.js)
     *    Dashboard codebase under index.jsx - in window.render{MicroFrontendName}
     */
    DashboardComments = 'DashboardComments',
    DashboardWidgets = 'DashboardWidgets',
}

export enum ReduxConstants {
    GetSelectiveFeeds = 'GetSelectiveFeeds',
    GetComments = 'GetComments',
}

export const LAYOUT = 'LAYOUT';
