import { createUseStyles } from 'react-jss';

const useWidgetContainerStyles = createUseStyles({
    App: {
        backgroundColor: '#F7F7F7',
        padding: '36px 40px 20px',
        overflowY: 'scroll',
        minWidth: '1300px',
    },
    addWidgetSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    addWidgetLayout: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '12px',
        marginBottom: '15px',
        cursor: 'pointer',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '16px',
    },
    addWidgetWrapper: {
        color: '#5E687E',
        marginLeft: '8px',
        fontSize: '12px',
        fontWeight: 'bold',
    },
});

export default useWidgetContainerStyles;
