import { Box, Drawer, Theme, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { SXCssObject } from './atoms/StyleModel';
import CommentsIcon from '../common/icons/CommentsIcon';
import BackIcon from '../common/icons/BackIcon';
import UnreadCommentsInfo from '../common/icons/UnreadCommentsInfo';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { Comment } from './CommentDetail';
import { CloseIcon } from '../common/icons/Close';
import LoadMore from '../common/icons/LoadMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    getDocumentNumbers,
    getDraftDocNumbers,
    getUserId,
    isLoggedIn,
} from '../utils/utils';
import {
    getCommentsApi,
    useGetCommentsQuery,
    useGetSelectiveFeedsQuery,
} from './store/api/FeedsApi';
import { getAsyncAwaitHttpObj } from '../common/store/api/HttpObjectConfig';
import {
    hideUnreadCountSnackbar,
    updateUnreadDotInCommentsIcon,
} from './store/api/AppConfigSlice';
import { useSelector } from 'react-redux';
import {
    appConfigDataSelector,
    documentsList,
    nonPersistentConfigSelector,
} from './selector/CommentsSelector';
import MarkAllAsRead from '../common/icons/MarkAllAsRead';
import Tooltip from '../common/icons/Tooltip';
import {
    CommentsFilterOptions,
    MicroFrontendConstants,
    ReduxConstants,
} from '../common/Constants';
import AlertHandler from '../common/AlertHandler';
import { ErrorMessages } from './ErrorMessages';
import {
    fetchDraftDocuments,
    fetchOtherDocuments,
} from './Thunk/DocumentsListMiddleware';
import { useAppDispatch } from './store/CommentsStore';
import { clearDocumentComments } from './store/api/ShowAllComments';
import { LoadingOverlayInNavDrawer } from '../common/InternalLoading';
import {
    CommentsResponse,
    CommentsScenario,
    UserOrGroupListResponse,
} from './models/CommentsModel';
import { setCommentNavDrawerOpenStatus } from 'comments/store/api/CommentsNonPersistentConfigSlice';
interface Props {
    setFetchScenario: React.Dispatch<React.SetStateAction<CommentsScenario>>;
    fetchScenario: CommentsScenario;
    commentsResponse?: CommentsResponse;
    resetToAllFeeds: () => void;
    usersAndGroupApiData: UserOrGroupListResponse[];
    setCommentsDrawerInitialState: () => void;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        p: '22px 25px 8px',
        backgroundColor: '#F7F7F7',
        position: 'relative',
        height: '100%',
    },
    headingSection: {
        pt: 2.5,
        px: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    markAllAsRead: {
        color: '#5B657B',
        cursor: 'pointer',
    },
    disabledMarkAllAsReadStyle: {
        opacity: 0.7,
        pointerEvents: 'none',
    },
    formSection: {
        m: 1,
        mt: 3,
    },
    searchSection: {
        width: '100%',
        display: 'flex',
        borderRadius: '8px',
        backgroundColor: theme.palette.common.white,
    },
    searchIcon: {
        ml: '12px',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
    },
    unreadCommentsInfo: {
        backgroundColor: '#0E2248',
        padding: '12px',
        borderRadius: '8px',
        my: '10px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    viewAllCommentsLink: {
        marginLeft: '12px',
        color: '#58C0E8',
        cursor: 'pointer',
    },
    loadMoreLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: '20px',
    },
    filterSection: {
        borderRadius: '8px',
        backgroundColor: theme.palette.common.white,
        px: '12px',
        ml: '16px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    drawerStyle: {
        width: '50%',
    },
    toggleButtonStyle: {
        padding: '16px 24px',
        lineHeight: '16px',
        textTransform: 'capitalize',
        border: '1px solid black',
    },
    selectedFilter: {
        fontWeight: 'bold',
    },
    radioButtonStyle: {
        px: '10px',
        backgroundColor: '#F6F9FC',
        width: '100%',
        my: '4px',
        borderRadius: '4px',
    },
    customizeIconWrapper: {
        border: '1px solid #EDF0F5',
        padding: '2px',
        marginRight: '12px',
        backgroundColor: theme.palette.common.white,
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuOverflowIconStyle: {
        padding: '6px',
        cursor: 'pointer',
        backgroundColor: '#F6F9FC',
    },
});

export function CommentsDrawer(props: Props) {
    const {
        fetchScenario,
        setFetchScenario,
        commentsResponse,
        resetToAllFeeds,
    } = props;
    const commentsDetails = commentsResponse?.Items;
    const cssObject = createCssObject(useTheme());
    const nonPersistentConfig = useSelector(nonPersistentConfigSelector);
    const isCommentsDrawerOpen = nonPersistentConfig.isCommentsDrawerOpened;

    //This variable is used to block lazyloading when end of feeds is reached. when last feed is reached, lastEvaluatedKey will be not be sent from API
    const [allowLazyLoading, setAllowLazyLoading] = useState(
        commentsResponse?.LastEvaluatedKey ? true : false
    );
    const documentsDetail = useSelector(documentsList);
    const [isLoading, setIsLoading] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);
    const [isLazyLoadingComments, setIsLazyLoadingComments] = useState(false);

    const updateHeight = () => {
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    const updateFeedsData = async () => {
        try {
            setIsLazyLoadingComments(true);
            const payload = {
                LastEvaluatedKey: commentsResponse!.LastEvaluatedKey,
            };
            const updatedRes = await getAsyncAwaitHttpObj().get(
                `/CommentNotifications/${getUserId()}/feed`,
                {
                    params: payload,
                }
            );
            const updatedCommentsData = updatedRes.data.Items;
            const dispatchCommentsData = getCommentsApi.util.updateQueryData(
                ReduxConstants.GetComments,
                CommentsFilterOptions.Allcomments,
                commentsList => {
                    commentsList.LastEvaluatedKey =
                        updatedRes.data.LastEvaluatedKey;
                    commentsList.Items.push(...updatedCommentsData);
                    return commentsList;
                }
            );
            dispatch(dispatchCommentsData);
            setIsLazyLoadingComments(false);
        } catch (e) {
            setIsLazyLoadingComments(false);
        }
    };

    const updateSelectiveFeedsData = async () => {
        try {
            setIsLazyLoadingComments(true);
            const payload = {
                filter: { isUnread: 'true' },
                LastEvaluatedKey: commentsResponse!.LastEvaluatedKey,
            };
            const updatedRes = await getAsyncAwaitHttpObj().get(
                `/CommentNotifications/${getUserId()}`,
                {
                    params: payload,
                }
            );
            const updatedCommentsData = updatedRes.data.Items;
            const dispatchCommentsData = getCommentsApi.util.updateQueryData(
                ReduxConstants.GetSelectiveFeeds,
                fetchScenario,
                commentsList => {
                    commentsList.LastEvaluatedKey =
                        updatedRes.data.LastEvaluatedKey;
                    commentsList.Items.push(...updatedCommentsData);
                    return commentsList;
                }
            );
            dispatch(dispatchCommentsData);
            setIsLazyLoadingComments(false);
        } catch (e) {
            setIsLazyLoadingComments(false);
        }
    };

    useEffect(() => {
        setAllowLazyLoading(commentsResponse?.LastEvaluatedKey ? true : false);
    }, [commentsResponse]);

    const {
        refetch: refetchComments,
        isLoading: isCommentsListLoading,
        isFetching: isCommentsFetching,
    } = useGetCommentsQuery(CommentsFilterOptions.Allcomments, {
        skip: !isLoggedIn(),
    });
    const {
        isLoading: isSelectiveCommentsLoading,
        isFetching: isSelectiveCommentsFetching,
    } = useGetSelectiveFeedsQuery(fetchScenario, { skip: !isLoggedIn() });
    const dispatch = useAppDispatch();
    const appConfigData = useSelector(appConfigDataSelector);
    const isUnreadScenario =
        fetchScenario.scenario === CommentsFilterOptions.Unreadcomments;

    const handleLazyLoading = async () => {
        if (fetchScenario.scenario === CommentsFilterOptions.Allcomments) {
            updateFeedsData();
        } else if (isUnreadScenario) {
            updateSelectiveFeedsData();
        }
    };

    /**
     * Whenever the Comments lists change(during lazyloading/filters), check whether every feed has the details of its documents.
     * If not fetch it using relevant api.
     * */

    useEffect(() => {
        if (!commentsDetails || commentsDetails.length <= 0) return;
        let otherDocsInComments = getDocumentNumbers(commentsDetails);
        let draftsInComments = getDraftDocNumbers(commentsDetails);
        let otherDocsToFetch = otherDocsInComments?.filter(docNumber => {
            const isAlreadyExists = documentsDetail?.documents?.find(
                doc => doc.number === docNumber
            );
            return !isAlreadyExists;
        });
        let draftsToFetch = draftsInComments?.filter(docNumber => {
            const isAlreadyExists = documentsDetail?.drafts?.find(
                doc => doc.number === docNumber
            );
            return !isAlreadyExists;
        });

        if (otherDocsToFetch && otherDocsToFetch.length > 0) {
            dispatch(fetchOtherDocuments(otherDocsToFetch));
        }
        if (draftsToFetch && draftsToFetch.length > 0) {
            dispatch(fetchDraftDocuments(draftsToFetch));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentsDetails]);

    const canShowUnreadSnackbar =
        appConfigData.isUnreadCountSnackbarVisible &&
        appConfigData.unreadMessagesCount &&
        !isUnreadScenario;

    //Height of other Elements except the scrollable comment section
    const heightOfOtherElement = canShowUnreadSnackbar ? 190 : 142;
    const findUnreadCount = React.useCallback(() => {
        if (appConfigData.unreadMessagesCount) {
            return appConfigData.unreadMessagesCount > 9
                ? appConfigData.unreadMessagesCount
                : '0' + appConfigData.unreadMessagesCount;
        }
        return null;
    }, [appConfigData.unreadMessagesCount]);
    const unreadCount = findUnreadCount();

    const resetAllowLazyLoadingFlag = () => {
        try {
            /* When filter is changed(between all comments to Unread OR viceversa), then below state variable is set to false.
      Because when new feeds are fetched, based on that, this state variable will be set again.
    */
            setAllowLazyLoading(false);
            let lazyLoadingDiv = document.getElementsByClassName(
                'infinite-lazy-scroll'
            );
            if (lazyLoadingDiv && lazyLoadingDiv.length === 1) {
                lazyLoadingDiv[0].scrollTo(0, 0);
            }
        } catch (e) {}
    };

    const handleUnread = () => {
        resetAllowLazyLoadingFlag();
        dispatch(clearDocumentComments());
        setFetchScenario({
            scenario: CommentsFilterOptions.Unreadcomments,
        });
    };

    const handleMarkAllAsRead = async () => {
        setIsLoading(true);
        await getAsyncAwaitHttpObj()
            .patch(`/CommentNotifications/${getUserId()}/markAllRead`)
            .catch(() => {
                AlertHandler.error(ErrorMessages.UnableToChangeMessageStatus);
            })
            .finally(() => {
                setIsLoading(false);
            });
        refetchComments();
        resetToAllFeeds();
    };

    const setCommentsDrawerOpenStatus = (status: boolean) => {
        dispatch(setCommentNavDrawerOpenStatus(status));
    };

    const openCommentsDrawer = () => {
        // When user logs we check for unread msgs and add dot.
        //when comments drawer is opened, we disable this flag in AppConfigurations reducer, so no checks are made
        dispatch(updateUnreadDotInCommentsIcon(false));
        props.setCommentsDrawerInitialState();
        setCommentsDrawerOpenStatus(true);
    };
    const logoutEventHandler = () => {
        setCommentsDrawerOpenStatus(false);
    };

    useEffect(() => {
        if (!window[MicroFrontendConstants.commentsNavListenerSet]) {
            document.addEventListener(
                MicroFrontendConstants.OnCommentsNavBarClick,
                openCommentsDrawer
            );
            document.addEventListener(
                MicroFrontendConstants.OnParentLogout,
                logoutEventHandler
            );
            window[MicroFrontendConstants.commentsNavListenerSet] = true;
        }
        return () => {
            setCommentsDrawerOpenStatus(false);
            window[MicroFrontendConstants.commentsNavListenerSet] = false;
            document.removeEventListener(
                MicroFrontendConstants.OnParentLogout,
                logoutEventHandler
            );
            document.removeEventListener(
                MicroFrontendConstants.OnCommentsNavBarClick,
                openCommentsDrawer
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box>
            <Drawer
                id={'new-id'}
                anchor={'right'}
                open={isCommentsDrawerOpen}
                onClose={() => {
                    setCommentsDrawerOpenStatus(false);
                }}
                sx={{
                    zIndex: 15,
                }}
                PaperProps={{
                    sx: {
                        width: '50%',
                        backgroundColor: '#F7F7F7',
                        overflow: 'hidden',
                    },
                }}
            >
                <Box sx={cssObject.root}>
                    <LoadingOverlayInNavDrawer
                        open={
                            isCommentsListLoading ||
                            isCommentsFetching ||
                            isSelectiveCommentsLoading ||
                            isSelectiveCommentsFetching ||
                            isLoading
                        }
                    />
                    <Box
                        aria-label='close'
                        sx={{
                            marginLeft: '20px',
                            float: 'right',
                            right: '20px',
                            position: 'absolute',
                            cursor: 'pointer',
                        }}
                        onClick={() => setCommentsDrawerOpenStatus(false)}
                    >
                        <Close />
                    </Box>
                    <Box sx={cssObject.headingSection}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {isUnreadScenario ? (
                                <>
                                    <Box
                                        sx={[
                                            { cursor: 'pointer' },
                                            cssObject.customizeIconWrapper,
                                        ]}
                                        onClick={() => {
                                            resetAllowLazyLoadingFlag();
                                            resetToAllFeeds();
                                        }}
                                    >
                                        <BackIcon />
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                fontSize: '24px',
                                                color: '#0E2248',
                                            }}
                                        >
                                            Unread Comments
                                        </Box>
                                        <Box
                                            sx={{
                                                color: '#5B657B',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {unreadCount &&
                                                (unreadCount === '01'
                                                    ? `You have ${unreadCount} unread comment`
                                                    : `You have ${unreadCount} unread comments`)}
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box sx={cssObject.customizeIconWrapper}>
                                        <CommentsIcon
                                            color={'black'}
                                            width={24}
                                            height={24}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            fontSize: '24px',
                                            color: '#0E2248',
                                        }}
                                    >
                                        All Comments
                                    </Box>
                                </>
                            )}
                        </Box>
                        {isUnreadScenario && (
                            <Box
                                sx={[
                                    cssObject.markAllAsRead,
                                    (!commentsDetails ||
                                        commentsDetails.length === 0) &&
                                        cssObject.disabledMarkAllAsReadStyle,
                                ]}
                                onClick={handleMarkAllAsRead}
                            >
                                <MarkAllAsRead />
                                <Box
                                    component={'span'}
                                    sx={{ marginLeft: '8px' }}
                                >
                                    Mark all as read
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {commentsDetails && commentsDetails.length > 0 ? (
                        <>
                            <Box sx={cssObject.formSection}>
                                {canShowUnreadSnackbar ? (
                                    <Box sx={cssObject.unreadCommentsInfo}>
                                        <UnreadCommentsInfo />
                                        <Box
                                            component={'span'}
                                            sx={{ marginLeft: '12px' }}
                                        >
                                            {unreadCount &&
                                                (unreadCount === '01'
                                                    ? `You have ${unreadCount} unread comment`
                                                    : `You have ${unreadCount} unread comments`)}
                                        </Box>
                                        <Box
                                            component={'span'}
                                            sx={cssObject.viewAllCommentsLink}
                                            onClick={handleUnread}
                                        >
                                            View Unread Comments
                                        </Box>
                                        <Box
                                            style={{
                                                right: '14px',
                                                position: 'absolute',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                dispatch(
                                                    hideUnreadCountSnackbar()
                                                )
                                            }
                                        >
                                            <CloseIcon color={'#17C2ED'} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <div
                                id='scrollableDiv'
                                style={{
                                    // overflowY: "scroll",
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    className='infinite-lazy-scroll'
                                    dataLength={commentsDetails.length} //This is important field to render the next data
                                    next={() => {
                                        //If lazyloading api returns empty comments response, then stop lazyloading
                                        if (allowLazyLoading) {
                                            handleLazyLoading();
                                        }
                                    }}
                                    hasMore={allowLazyLoading}
                                    height={height - heightOfOtherElement}
                                    // height={}
                                    loader={
                                        isLazyLoadingComments ? (
                                            <Box sx={cssObject.loadMoreLabel}>
                                                <LoadMore />
                                                <span
                                                    style={{
                                                        paddingLeft: '4px',
                                                        fontSize: '14px',
                                                        color: '#AAAFBA',
                                                    }}
                                                >
                                                    Loading more comments
                                                </span>
                                            </Box>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    scrollableTarget={'scrollableDiv'}
                                >
                                    {commentsDetails.map((comment, index) => {
                                        return (
                                            <Comment
                                                comment={comment}
                                                key={comment.documentNumber}
                                                index={index}
                                                fetchScenario={fetchScenario}
                                                usersAndGroupApiData={
                                                    props.usersAndGroupApiData
                                                }
                                                handleCommentsDrawerVisibility={
                                                    setCommentsDrawerOpenStatus
                                                }
                                            />
                                        );
                                    })}
                                </InfiniteScroll>
                            </div>
                        </>
                    ) : (
                        !isSelectiveCommentsLoading &&
                        !isSelectiveCommentsFetching && (
                            <Box
                                sx={{
                                    fontWeight: 500,
                                    padding: '20px',
                                    marginTop: '24px',
                                    borderRadius: '8px',
                                    fontSize: '20px',
                                    backgroundColor: '#fff',
                                    color: '#5B657B',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        marginRight: '20px',
                                        display: 'flex',
                                    }}
                                >
                                    <Tooltip />
                                </Box>
                                <Box>
                                    {isUnreadScenario
                                        ? 'No Unread Comments'
                                        : 'No Comments Yet'}
                                </Box>
                            </Box>
                        )
                    )}
                </Box>
            </Drawer>
        </Box>
    );
}
