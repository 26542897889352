import * as React from 'react';
import { SVGProps } from 'react';

const OverflowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 149 \u2013 31'
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        {...props}
    >
        <g
            data-name='Group 5538'
            transform='translate(6.107 .574)'
            fill='#17c2ed'
        >
            <circle
                data-name='Ellipse 261'
                cx={1.583}
                cy={1.583}
                r={1.583}
                transform='translate(.243 .125)'
            />
            <circle
                data-name='Ellipse 263'
                cx={1.583}
                cy={1.583}
                r={1.583}
                transform='translate(.243 5.882)'
            />
            <circle
                data-name='Ellipse 264'
                cx={1.583}
                cy={1.583}
                r={1.583}
                transform='translate(.243 11.639)'
            />
        </g>
    </svg>
);

export default OverflowIcon;
