import React, { useRef } from 'react';
import { InteractionItem } from 'chart.js';
import { Bar, Doughnut, getElementAtEvent } from 'react-chartjs-2';
import useLibraryStyles from '../../components/WidgetStyleLibrary';
import { ClosedContainerProps } from 'widgets/models/WidgetModels';

export function ContractorDocumentExpirationDisplayChartType(
    props: ClosedContainerProps
): JSX.Element {
    const styles = useLibraryStyles();
    const chartRef = useRef(null);
    const {
        displayType,
        chartData,
        setChartSelectedValue,
        setDisplayTable,
        setActiveTablePage,
        barOptions,
        doughnutOptions,
        chartSelectedValue,
    } = props;

    const toggleTable = (event): void => {
        if (chartRef?.current) {
            const clickedElement: InteractionItem[] = getElementAtEvent(
                chartRef.current,
                event
            );

            if (clickedElement.length) {
                applyIndex(clickedElement[0].index);
            } else {
                setChartSelectedValue('');
                setDisplayTable(false);
            }

            setActiveTablePage(1);
        }
    };

    const applyIndex = (index): void => {
        const selectedValue: string = chartData.values[index];
        if (selectedValue === chartSelectedValue) {
            setChartSelectedValue('');
            setDisplayTable(false);
        } else {
            setChartSelectedValue(selectedValue);
            setDisplayTable(true);
        }
    };

    return (
        <div className={styles.chartContainer}>
            {displayType === 'Pie Chart' && (
                <Doughnut
                    data-test-id={'CDEWidgetDoughnutChart'}
                    ref={chartRef}
                    data={chartData}
                    options={doughnutOptions}
                    onClick={toggleTable}
                />
            )}

            {displayType === 'Bar Graph' && (
                <Bar
                    data-test-id={'CDEWidgetBarChart'}
                    ref={chartRef}
                    data={chartData}
                    options={barOptions}
                    onClick={toggleTable}
                />
            )}
        </div>
    );
}
