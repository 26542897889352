import * as React from 'react';
import { SVGProps } from 'react';

const AlertIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 130 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <rect
            data-name='Rectangle 5691'
            width={5}
            height={16}
            rx={2.5}
            transform='translate(10)'
            fill='#fff'
        />
        <rect
            data-name='Rectangle 5692'
            width={5}
            height={5}
            rx={2.5}
            transform='translate(10 19)'
            fill='#fff'
        />
    </svg>
);

export default AlertIcon;
