import * as React from 'react';
import { SVGProps } from 'react';

const AddWidgetIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 145 \u2013 1'
        xmlns='http://www.w3.org/2000/svg'
        width={23}
        height={23}
        {...props}
    >
        <rect
            data-name='Rectangle 5626'
            width={23}
            height={23}
            rx={4}
            fill='#18c2ed'
        />
        <g
            data-name='Group 5280'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeWidth={2}
        >
            <path data-name='Line 274' d='M11.538 7.914v8.102' />
            <path data-name='Line 275' d='M15.721 11.965H7.324' />
        </g>
    </svg>
);

export default AddWidgetIcon;
