import * as React from 'react';
import { SVGProps } from 'react';

const SelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        id='Component_70_1'
        data-name='Component 70 – 1'
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
    >
        <rect
            id='Rectangle_5783'
            data-name='Rectangle 5783'
            width='20'
            height='20'
            rx='2'
            fill='#11254a'
        />
        <g
            id='Rectangle_5759'
            data-name='Rectangle 5759'
            transform='translate(2 2)'
            fill='#fff'
            stroke='#707070'
            strokeWidth='1'
            opacity='0'
        >
            <rect width='16' height='16' stroke='none' />
            <rect x='0.5' y='0.5' width='15' height='15' fill='none' />
        </g>
        <path
            id='Path_2597'
            data-name='Path 2597'
            d='M-1302.755-12442.155l4.2,4.2,7.465-7.465'
            transform='translate(1306.934 12451.598)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
        />
    </svg>
);

export default SelectedIcon;
