import {
    combineReducers,
    configureStore,
    ConfigureStoreOptions,
} from '@reduxjs/toolkit';
import { getWidgetsApi } from './api/DashboardWidgetsApi';
import { getUserPreferenceApi } from './api/UserPreferenceApi';
import { getKeyWorkOrderStatusesWidgetApi } from './api/KeyWorkOrderStatusesWidgetApi';
import { getContractorDocumentsApi } from './api/ContractorExpiredDocumentsApi';
import { getExceptionFlagWidgetApi } from './api/ExceptionFlagWidgetApi';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const persistConfig: PersistConfig<any> = {
    key: 'motili-dashboard-widgets',
    storage,
    blacklist: [
        'widgets',
        'userPreference',
        'userLayout',
        'search',
        'workOrderStatusesReducer',
        'exceptionFlagReducer',
        'contractorDocumentExpirationReducer'
    ],
};

const combinedReducers = combineReducers({
    [getWidgetsApi.reducerPath]: getWidgetsApi.reducer,
    [getUserPreferenceApi.reducerPath]: getUserPreferenceApi.reducer,
    [getKeyWorkOrderStatusesWidgetApi.reducerPath]:
        getKeyWorkOrderStatusesWidgetApi.reducer,
    [getContractorDocumentsApi.reducerPath]: getContractorDocumentsApi.reducer,
    [getExceptionFlagWidgetApi.reducerPath]: getExceptionFlagWidgetApi.reducer
});
const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const createWidgetsMFStore = (
    options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
    configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat([
                getWidgetsApi.middleware,
                getUserPreferenceApi.middleware,
                getKeyWorkOrderStatusesWidgetApi.middleware,
                getContractorDocumentsApi.middleware,
                getExceptionFlagWidgetApi.middleware
            ]),
        ...options,
    });

export const widgetsMFStore = createWidgetsMFStore();
export const widgetsMFPersistor = persistStore(widgetsMFStore);

export type AppDispatch = typeof widgetsMFStore.dispatch;
export type RootState = ReturnType<typeof widgetsMFStore.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
