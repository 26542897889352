import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { userListApi } from 'comments/store/api/UsersAndGroupListApi';
import { getCommentsApi } from 'comments/store/api/FeedsApi';
import {
    resetAppConfiguration,
    setUnreadMessagesCount,
} from 'comments/store/api/AppConfigSlice';
import { CommentsWrapper } from './CommentsWrapper';
import {
    CommentsFilterOptions,
    MicroFrontendConstants,
    ReduxConstants,
} from 'common/Constants';
import {
    getUnReadComment,
    getUnReadCount,
} from 'common/store/api/HttpObjectConfig';
import { cloneDeep, some } from 'lodash';
import { CommentDetails } from 'comments/models/CommentsModel';
import { setDocumentComments } from 'comments/store/api/ShowAllComments';
import { commentsMFStore, useAppDispatch } from 'comments/store/CommentsStore';
import { getLoggedInUserDetails } from '../utils/utils';

export default function CommentsMF() {
    const dispatch = useAppDispatch();
    const eventHandler = () => {
        dispatch(getCommentsApi.util.resetApiState());
        dispatch(userListApi.util.resetApiState());
        dispatch(resetAppConfiguration());
    };
    /**
     * When a Real Time message comes, update Feeds/Selective Feeds reducer. Trigger UnRead Count API
     * Add dot to comment Icon in Global Navigation bar(only if comments drawer is not in open status)
     * If comments list of a particular doc is expanded(when "show all comments" is clicked), update the new comment in reducer
     * @param incomingComment
     */
    const handleRealTimeMessage = async (incomingComment: CommentDetails) => {
        const docCommentsInfo = commentsMFStore.getState().showAllComments;
        const isCommentsDrawerOpen =
            commentsMFStore.getState().nonPersistentConfigurations
                .isCommentsDrawerOpened;
        try {
            const unreadCount = await getUnReadCount();
            dispatch(setUnreadMessagesCount(unreadCount.data));

            const comment: any = await getUnReadComment(
                incomingComment.modelType,
                incomingComment.documentNumber
            );
            if (incomingComment.objectId && comment?.data) {
                if (
                    some(
                        comment?.data?.unReadComments,
                        e => e.unreadObjectIds === incomingComment.objectId
                    )
                ) {
                    incomingComment.unreadObjectIds =
                        comment?.data?.unReadComments;
                } else {
                    incomingComment.unreadObjectIds = [
                        { latestUnreadObjectId: incomingComment.objectId },
                        ...(comment?.data?.unReadComments || []),
                    ];
                }
            }

            if (!isCommentsDrawerOpen) {
                document.dispatchEvent(
                    new CustomEvent(MicroFrontendConstants.AddDotToCommentIcon)
                );
            }
            const updateFeedsStore = getCommentsApi.util.updateQueryData(
                ReduxConstants.GetComments,
                CommentsFilterOptions.Allcomments,
                existingFeeds => {
                    // Remove the comment from existing Feeds list(If present). Add the comment at the top.
                    let feedsList = cloneDeep(existingFeeds);
                    feedsList.Items = feedsList.Items?.filter(
                        comment =>
                            !(
                                comment.documentNumber ===
                                incomingComment.documentNumber
                            )
                    );
                    feedsList.Items = [incomingComment, ...feedsList.Items];
                    return feedsList;
                }
            );
            dispatch(updateFeedsStore);
            const updateSelectiveFeedsStore =
                getCommentsApi.util.updateQueryData(
                    ReduxConstants.GetSelectiveFeeds,
                    { scenario: CommentsFilterOptions.Unreadcomments },
                    selectiveFeeds => {
                        // Remove the comment from existing Feeds list(If present). Add the comment at the top.
                        let selectiveFeedsList = cloneDeep(selectiveFeeds);
                        selectiveFeedsList.Items =
                            selectiveFeedsList.Items?.filter(
                                comment =>
                                    !(
                                        comment.documentNumber ===
                                        incomingComment.documentNumber
                                    )
                            );
                        selectiveFeedsList.Items = [
                            incomingComment,
                            ...selectiveFeedsList.Items,
                        ];
                        return selectiveFeedsList;
                    }
                );
            dispatch(updateSelectiveFeedsStore);
            if (docCommentsInfo) {
                const isDocExpanded =
                    docCommentsInfo.docNumber ===
                    incomingComment.documentNumber;
                if (isDocExpanded) {
                    let allComments = docCommentsInfo.comments.filter(
                        comment =>
                            !(
                                comment.commentId ===
                                incomingComment.commentId + ''
                            )
                    );
                    allComments = [incomingComment, ...allComments];
                    dispatch(
                        setDocumentComments({
                            ...docCommentsInfo,
                            comments: allComments,
                        })
                    );
                }
            }
        } catch (e) {}
    };
    useEffect(() => {
        const listener = (event: Event) => {
            const customEvent = event as CustomEvent;
            if (
                customEvent.type ===
                    MicroFrontendConstants.OnDashboardComment &&
                Number(customEvent.detail?.data?.accountId) ===
                    getLoggedInUserDetails().userId &&
                customEvent.detail?.data?.comment
            ) {
                handleRealTimeMessage(
                    JSON.parse(customEvent.detail.data.comment)
                ).catch(err => console.log(err));
            }
        };
        window.addEventListener(
            MicroFrontendConstants.OnDashboardComment,
            listener
        );
        return () => {
            window.removeEventListener(
                MicroFrontendConstants.OnDashboardComment,
                listener
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener(
            MicroFrontendConstants.OnParentLogout,
            eventHandler,
            false
        );
        WebFont.load({
            google: {
                families: ['Nunito Sans:300,400,500,600,700'],
            },
        });

        return () => {
            document.removeEventListener(
                MicroFrontendConstants.OnParentLogout,
                eventHandler
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <>
            <CommentsWrapper />
        </>
    );
}
