import React, { ReactElement, JSXElementConstructor, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { BaseUrl } from '../../models/WidgetModels';
import ClickAwayListener from 'react-click-away-listener';
import useToolTipQuestionMarkStyles from './ToolTipQuestionMarkStyles';
import useLibraryStyles from '../WidgetStyleLibrary';

interface ToolTipProps {
    children: ReactElement<any, string | JSXElementConstructor<any>>;
    dataTestId: string;
    widgetId: string;
}

export function ToolTipQuestionMarkIcon(
    props: Readonly<ToolTipProps>
): JSX.Element {
    const toolTipStyles = useToolTipQuestionMarkStyles();
    const libraryStyles = useLibraryStyles();
    const styles = {
        ...toolTipStyles,
        ...libraryStyles,
    };

    const { children, dataTestId, widgetId } = props;

    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    const handleIconClick = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    return (
        <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
            <div>
                <button
                    className={styles.button}
                    data-test-id={dataTestId}
                    id={`question-mark-button-${widgetId}`}
                >
                    <img
                        alt={'tool tip icon'}
                        src={`${BaseUrl.widgetImage}/question-mark-icon.svg`}
                        onClick={handleIconClick}
                    />
                </button>
                <Tooltip
                    id={`question-mark-tooltip-${widgetId}`}
                    anchorSelect={`#question-mark-button-${widgetId}`}
                    noArrow
                    isOpen={isTooltipOpen}
                    place={'bottom-start'}
                    opacity={1}
                    style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 0px 1px 1px lightgray',
                    }}
                    disableStyleInjection={'core'}
                >
                    <div className={styles.toolTipOpenContainer}>
                        {children}
                    </div>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
}
