import { getCommentsApi } from './FeedsApi';

const docStatusesApi = getCommentsApi.injectEndpoints({
    endpoints: builder => ({
        getWorkOrderStatuses: builder.query({
            query() {
                return {
                    url: `/WorkOrders/statuses`,
                    method: 'GET',
                };
            },
        }),
        getOrderStatuses: builder.query({
            query() {
                return {
                    url: `/Orders/statuses`,
                    method: 'GET',
                };
            },
        }),
        getQuoteStatuses: builder.query({
            query() {
                return {
                    url: `/Quotes/statuses`,
                    method: 'GET',
                };
            },
        }),
        getDraftStatuses: builder.query({
            query() {
                return {
                    url: `/Drafts/statuses`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useGetDraftStatusesQuery,
    useGetOrderStatusesQuery,
    useGetWorkOrderStatusesQuery,
    useGetQuoteStatusesQuery,
} = docStatusesApi;
