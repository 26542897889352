import { createApi } from '@reduxjs/toolkit/query/react';
import {
    MetadataV2Dropdown,
    State,
} from '../../models/ContractorDocumentExpirationModel';
import { dataDashboardBaseQuery } from '../../../common/store/api/BaseQueryConfig';

// Define a service using a base URL and expected endpoints

export const getContractorDocumentsApi = createApi({
    reducerPath: 'contractorDocumentExpirationReducer',
    baseQuery: dataDashboardBaseQuery,
    tagTypes: [
        'widget_details',
        'regions_and_states',
        'contractor_status',
        'bucket_count',
        'credentials_list',
        'contractor_company_documents_list',
    ],
    endpoints: builder => ({
        GetBucketCount: builder.query({
            query: requestObject => ({
                url: `/v1/${requestObject.urlFragment}/bucket-counts`,
                method: 'POST',
                body: requestObject,
            }),
            providesTags: ['bucket_count'],
        }),
        GetContractorDocumentData: builder.query({
            query: requestObject => ({
                url: `/v1/${requestObject.urlFragment}?page=${requestObject.page}&size=${requestObject.noOfDocumentsToShow}`,
                method: 'POST',
                body: requestObject,
            }),
            providesTags: ['widget_details'],
        }),
        GetContractorStatuses: builder.query<string[], null>({
            query: () => ({
                url: `/metadata/v1/contractor-statuses`,
                method: 'GET',
            }),
            providesTags: ['contractor_status'],
        }),
        GetCredentialsList: builder.query<MetadataV2Dropdown[], null>({
            query: () => ({
                url: `/metadata/v2/contractor-team-member-credential-types`,
                method: 'GET',
            }),
            providesTags: ['credentials_list'],
        }),
        GetContractorCompanyDocumentsList: builder.query<
            MetadataV2Dropdown[],
            null
        >({
            query: () => ({
                url: `/metadata/v2/contractor-documents`,
                method: 'GET',
            }),
            providesTags: ['contractor_company_documents_list'],
        }),
        GetRegionsAndStates: builder.query<State[], null>({
            query: () => ({
                url: `/v1/region`,
                method: 'GET',
            }),
            providesTags: ['regions_and_states'],
        }),
    }),
});
export const {
    useGetBucketCountQuery,
    useGetContractorDocumentDataQuery,
    useGetRegionsAndStatesQuery,
    useGetContractorStatusesQuery,
    useGetContractorCompanyDocumentsListQuery,
    useGetCredentialsListQuery,
} = getContractorDocumentsApi;
