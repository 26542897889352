import React, {useState} from 'react';
import { Widget } from 'widgets/models/WidgetModels';
import {
    useDeleteUserPreferenceMutation,
    useGetUserPreferenceQuery,
    useUpdateUserPreferenceMutation,
    useUpdateWidgetOrderMutation,
} from '../store/api/UserPreferenceApi';
import { WidgetsSection } from './WidgetsSection';
import { InternalLoading } from '../../common/InternalLoading';
import { filterShowableWidgetsBasedOnUserPreference } from '../../utils/WidgetUtils';
import { DeleteWidgetConfirmationModal } from './DeleteWidgetConfirmationModal';

export function WidgetsLayout() {
    const {
        data: userPreferenceData,
        isLoading: isUserPreferenceInitialApiLoading,
    } = useGetUserPreferenceQuery(null);
    const [triggerDeleteUserPreferenceMutation] =
        useDeleteUserPreferenceMutation();
    const [triggerUpdateUserPreference] = useUpdateUserPreferenceMutation();
    const [triggerUpdateWidgetOrder] = useUpdateWidgetOrderMutation();

    const [widgetToBeDeleted, setWidgetToBeDeleted] = useState<number>(0);

    const handleDeleteActionFromUser = (id: number) => {
        setWidgetToBeDeleted(id);
    };
    const deleteWidget = (id: number) => {
        triggerDeleteUserPreferenceMutation(id);
    };

    return (
        <div>
            {widgetToBeDeleted !== 0 && (
                <DeleteWidgetConfirmationModal
                    handleCancel={() => setWidgetToBeDeleted(0)}
                    handleConfirm={(widgetId: number) => {
                        setWidgetToBeDeleted(0);
                        deleteWidget(widgetId);
                    }}
                    widgetId={widgetToBeDeleted}
                />
            )}
            <InternalLoading open={isUserPreferenceInitialApiLoading} />
            {userPreferenceData?.length && (
                <WidgetsSection
                    widgetsList={filterShowableWidgetsBasedOnUserPreference(
                        userPreferenceData!
                    )}
                    onDelete={(id: number) => {
                        handleDeleteActionFromUser(id);
                    }}
                    onUpdateWidget={(widget: Widget) => {
                        triggerUpdateUserPreference([widget]);
                    }}
                    onUpdateWidgetOrder={(widget: Widget[]) => {
                        triggerUpdateWidgetOrder(widget);
                    }}
                />
            )}
        </div>
    );
}
