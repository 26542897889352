import { CommentsFilterOptions } from '../../common/Constants';

export interface CommentDetails {
    userId?: number;
    documentId: string;
    documentNumber: string;
    commentedUserName: string;
    createdTs?: string;
    modelType: string;
    comment: string;
    commentId?: string;
    messageType: string;
    userEmail: string;
    commentedUserEmail: string;
    documentPrimaryKey: number;
    objectId?: string;
    userName: string;
    jobId: number;
    updatedTs: string;
    commentedUserDepartments?: string[];
    unreadObjectIds?: UnreadObjectIds[];
    commentType?: CommentType; //This field is created in client side. It is only added for comments added in Reply field
}

export enum CommentType {
    LOCAL_COMMENT = 'LOCAL_COMMENT', //Comment is stored in Local(posted via Add Reply field). No read/unread option.
    COMMENT_FROM_DOCUMENT = 'COMMENT_FROM_DOCUMENT', //Comment is present in doc only. No read/unread option.
    COMMENT_FROM_NOTIFICATION = 'COMMENT_FROM_NOTIFICATION', //Either the user or his dept is tagged in comment. so the comment is present in /commentNotifications and includes read/unread status
}

export interface UnreadObjectIds {
    latestUnreadObjectId: string;
}

export interface CommentsResponse {
    Items: CommentDetails[];
    LastEvaluatedKey: LastEvaluatedKeyObj;
}

export interface LastEvaluatedKeyObj {
    documentTs: string;
    objectId: string;
    userId: number;
}
export interface UserOrGroupListResponse {
    id: number;
    username: string;
}
//Scenario changes based when user applies filter on feeds(eg: when user chooses to view unread comments alone)
export interface CommentsScenario {
    scenario: CommentsFilterOptions;
}

export interface UpdateReadStatusReq {
    isRead: boolean;
    documentId: string;
    objectId: string;
}
