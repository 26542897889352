import { createSlice } from '@reduxjs/toolkit';
const configurationSlice = createSlice({
    name: 'appConfigurationSlice',
    initialState: {
        isUnreadCountSnackbarVisible: true,
        unreadMessagesCount: 0,
        shouldCheckForUnreadMsgAndAddDot: true,
    },
    reducers: {
        setUnreadMessagesCount: (state, action) => {
            state.unreadMessagesCount = action.payload;
        },
        hideUnreadCountSnackbar: state => {
            state.isUnreadCountSnackbarVisible = false;
        },
        updateUnreadDotInCommentsIcon: (state, action) => {
            state.shouldCheckForUnreadMsgAndAddDot = action.payload;
        },
        resetAppConfiguration: state => {
            state.isUnreadCountSnackbarVisible = true;
            state.shouldCheckForUnreadMsgAndAddDot = true;
        },
    },
    extraReducers: builder => {},
});

export default configurationSlice.reducer;

export const {
    hideUnreadCountSnackbar,
    resetAppConfiguration,
    setUnreadMessagesCount,
    updateUnreadDotInCommentsIcon,
} = configurationSlice.actions;
