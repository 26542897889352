import React from 'react';
import _ from 'lodash';
import { WidgetDefinition } from '../models/WidgetModels';
import DefaultThumbnail from '../images/DefaultThumbnail.png';
import SelectedIcon from '../../common/icons/SelectedIcon';
import UnselectedIcon from '../../common/icons/UnselectedIcon';
import { ButtonPrimary, ModalBody, ModalFooter, ModalHeader } from 'motili-ui';
import { FullscreenModal } from '../components/fullscreenModal/FullscreenModal';
import useAddWidgetModalStyles from './AddWidgetModalStyles';

interface Props {
    widgets: WidgetDefinition[];
    onClose: () => void;
    handleWidgetAddition: (widgets: WidgetDefinition[]) => void;
}

export function AddWidgetModal(props: Props) {
    const styles = useAddWidgetModalStyles();
    const { widgets, handleWidgetAddition, onClose } = props;

    const [selectedWidgets, setSelectedWidgets] = React.useState<
        WidgetDefinition[]
    >([]);

    const WidgetInfoToShowInAddModal = widgets.map(widget => {
        return {
            Heading: widget.name,
            Description: widget.description,
            Image: DefaultThumbnail,
        };
    });

    const handleWidgetClick = (index: number) => {
        let clickedWidget = widgets[index];
        let alreadySelectedWidgets = [...selectedWidgets];
        if (selectedWidgets.find(widget => clickedWidget.id === widget.id)) {
            _.remove(alreadySelectedWidgets, {
                id: clickedWidget.id,
            });
            setSelectedWidgets(alreadySelectedWidgets);
        } else {
            setSelectedWidgets([...selectedWidgets, { ...widgets[index] }]);
        }
    };
    const handleClose = () => {
        setSelectedWidgets([]);
        onClose();
    };
    const handleSubmit = () => {
        handleWidgetAddition(selectedWidgets);
        handleClose();
    };

    return (
        <FullscreenModal isOpen={true} onClose={handleClose}>
            <>
                <ModalHeader>
                    <h2>Add Widgets</h2>
                </ModalHeader>
                <ModalBody>
                    <div className={styles.widgetContainer}>
                        {widgets.map((item, index) => {
                            const isWidgetSelected = selectedWidgets.find(
                                widget => widget?.key === item?.key
                            );
                            const widgetInfo =
                                WidgetInfoToShowInAddModal[index];

                            return (
                                <div
                                    key={index}
                                    className={styles.widgetItemStyle}
                                >
                                    <div>
                                        <div
                                            className={
                                                styles.clickableIconWrapper
                                            }
                                            onClick={() =>
                                                handleWidgetClick(index)
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.clickableIconStyle
                                                }
                                            >
                                                {isWidgetSelected ? (
                                                    <SelectedIcon />
                                                ) : (
                                                    <UnselectedIcon />
                                                )}
                                            </div>
                                            <img
                                                className={
                                                    styles.clickableImageStyle
                                                }
                                                src={widgetInfo.Image}
                                                alt={'Widget display icon'}
                                            />
                                        </div>
                                        <div className={styles.paddingTop25}>
                                            <div>
                                                <h4
                                                    className={
                                                        styles.widgetHeading
                                                    }
                                                >
                                                    {widgetInfo.Heading}
                                                </h4>
                                            </div>
                                            <div
                                                className={
                                                    styles.widgetDescription
                                                }
                                            >
                                                {widgetInfo.Description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonPrimary
                        onClick={handleSubmit}
                        disabled={selectedWidgets.length === 0}
                    >
                        Add to Dashboard
                    </ButtonPrimary>
                </ModalFooter>
            </>
        </FullscreenModal>
    );
}
