import { createSlice } from '@reduxjs/toolkit';
import {
    fetchDraftDocuments,
    fetchOtherDocuments,
} from 'comments/Thunk/DocumentsListMiddleware';

const initialDocArr: any[] = [];
const documentsListSlice = createSlice({
    name: 'documentsListSlice',
    initialState: {
        documents: initialDocArr,
        drafts: initialDocArr,
        loading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchOtherDocuments.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchOtherDocuments.fulfilled, (state, action) => {
            state.loading = false;
            state.documents = action.payload || [];
        });
        builder.addCase(fetchOtherDocuments.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(fetchDraftDocuments.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchDraftDocuments.fulfilled, (state, action) => {
            state.loading = false;
            state.drafts = action.payload || [];
        });
        builder.addCase(fetchDraftDocuments.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export default documentsListSlice.reducer;
