import React from 'react';

interface DividerProps {
    height: string;
}

export function Divider(props: DividerProps): JSX.Element {
    const { height } = props;

    return (
        <div
            style={{
                width: '1px',
                height: height,
                backgroundColor: '#ccc',
                margin: '0px 15px',
            }}
        ></div>
    );
}
