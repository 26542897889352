import * as React from 'react';
import { SVGProps } from 'react';

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
    const color = props.color || '#333';
    return (
        <svg
            width={20}
            height={20}
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <title>{'Group 4'}</title>
            <path
                d='m11.235 10 6.51-6.51a.872.872 0 1 0-1.236-1.234L10 8.765l-6.509-6.51A.873.873 0 0 0 2.256 3.49L8.766 10l-6.51 6.509a.872.872 0 1 0 1.235 1.234L10 11.235l6.509 6.508a.869.869 0 0 0 1.235 0 .872.872 0 0 0 0-1.234L11.235 10Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    );
};
