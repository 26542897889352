import * as React from 'react';
import { SVGProps } from 'react';

const RemoveIcon = (props: SVGProps<SVGSVGElement>) => {
    const { height = 16, width = 16, color = '#fff' } = props;
    return (
        <svg
            id='Component_229_1'
            data-name='Component 229 – 1'
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 16 16'
        >
            <g
                id='Rectangle_5791'
                data-name='Rectangle 5791'
                fill={color}
                stroke='#94a7cb'
                strokeWidth='1'
                opacity='0'
            >
                <rect width='16' height='16' stroke='none' />
                <rect x='0.5' y='0.5' width='15' height='15' fill='none' />
            </g>
            <g
                id='Group_5769'
                data-name='Group 5769'
                transform='translate(-1092.461 -368.729)'
            >
                <g
                    id='Rectangle_5693'
                    data-name='Rectangle 5693'
                    transform='translate(1094.961 372.957)'
                    fill='none'
                    stroke={color}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                >
                    <path
                        d='M0,0H11a0,0,0,0,1,0,0V7a3,3,0,0,1-3,3H3A3,3,0,0,1,0,7V0A0,0,0,0,1,0,0Z'
                        stroke='none'
                    />
                    <path
                        d='M1.5.75h8a.75.75,0,0,1,.75.75V7A2.25,2.25,0,0,1,8,9.25H3A2.25,2.25,0,0,1,.75,7V1.5A.75.75,0,0,1,1.5.75Z'
                        fill='none'
                    />
                </g>
                <g
                    id='Group_5535'
                    data-name='Group 5535'
                    transform='translate(1099.054 373.49)'
                >
                    <line
                        id='Line_306'
                        data-name='Line 306'
                        y2='7.974'
                        fill='none'
                        stroke={color}
                        strokeWidth='1.5'
                    />
                    <line
                        id='Line_307'
                        data-name='Line 307'
                        y2='7.974'
                        transform='translate(2.807)'
                        fill='none'
                        stroke={color}
                        strokeWidth='1.5'
                    />
                </g>
                <line
                    id='Line_308'
                    data-name='Line 308'
                    x2='5.98'
                    transform='translate(1097.492 370.5)'
                    fill='none'
                    stroke={color}
                    strokeLinecap='round'
                    strokeWidth='1.5'
                />
            </g>
        </svg>
    );
};

export default RemoveIcon;
