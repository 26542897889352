import * as React from 'react';
import { SVGProps } from 'react';

const UnreadCommentsInfo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name='Component 119 \u2013 12'
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        {...props}
    >
        <g data-name='Group 4661'>
            <path
                data-name='Path 2435'
                d='M10.942 22H5.793a3.72 3.72 0 0 1-3.722-3.053 3.9 3.9 0 0 1-.064-.737q-.006-5.151 0-10.3A3.724 3.724 0 0 1 5.79 4.116q4.027-.013 8.054 0a.426.426 0 0 0 .476-.3 4.444 4.444 0 0 1 4.733-2.632 4.5 4.5 0 0 1 3.77 3.751 4.453 4.453 0 0 1-2.65 4.764.4.4 0 0 0-.285.445q.015 4.027 0 8.054a3.718 3.718 0 0 1-3.058 3.74 4.139 4.139 0 0 1-.781.063c-1.702.001-3.405-.001-5.107-.001Zm2.967-16.38c-.06-.006-.11-.015-.16-.015H5.737a2.156 2.156 0 0 0-1.342.445 2.233 2.233 0 0 0-.906 1.87v10.216a2.254 2.254 0 0 0 2.366 2.375h10.194a2.242 2.242 0 0 0 2.347-2.327v-7.859c0-.078-.007-.155-.01-.231a4.547 4.547 0 0 1-4.477-4.474Zm7.47 0a2.983 2.983 0 1 0-3 2.974 3 3 0 0 0 3-2.979Z'
                fill='#aaafba'
                stroke='#aaafba'
                strokeWidth={0.4}
            />
        </g>
    </svg>
);

export default UnreadCommentsInfo;
